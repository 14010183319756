import {
  LOAD_ROLES_START,
  LOAD_ROLES_SUCCESS,
  LOAD_ROLES_FAILURE,
} from "../actions/types";

let initialState = {
  roles: [],
  isLoading: false,
  error: null,
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ROLES_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        isLoading: false,
      };
    case LOAD_ROLES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default rolesReducer;
