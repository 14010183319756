import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";

export const CustomPaper = styled(Paper)`
  padding: 22px;
  border-top:3px solid rgba(128, 128, 128, 0.5);
`;

export const CustomGrid = styled(Grid)`
  margin-bottom: 12px;
`;

export const SpacedGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;

export const TimeOffGrid = styled(Grid)`
  margin-bottom: 12px;
`;

export const TimeOffPopUp = styled(Grid)`
  display: block;
  position: fixed;
  bottom: 0px;
  width: 100%;
`;

export const TabStyle = styled(Grid)`
  margin-left: 40px;
  display: grid;
`;

export const DateStyle = styled(Grid)`
  justify-content: space-around;
  display: grid;
`;
