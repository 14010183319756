import React from "react";
import { Redirect, Switch } from "react-router-dom";
import AuthenticationLayout from "layouts/Authentication";
import { PrivateRouteWithLayout, RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
  NotFound as NotFoundView,
  TimeOff as TimeOffView,
  SignIn as SignInView,
} from "./views";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from={ "/" } to={ "time-off" } />
      <PrivateRouteWithLayout
        component={ TimeOffView }
        exact
        layout={ MainLayout }
        path={ "/time-off" }
      />
      <RouteWithLayout
        component={ SignInView }
        exact
        path={ "/sign-in" }
        layout={ AuthenticationLayout }
      />
      <RouteWithLayout
        component={ NotFoundView }
        exact
        layout={ MinimalLayout }
        path={ "/not-found" }
      />
      <Redirect to={ "/not-found" } />
    </Switch>
  );
};

export default Routes;
