import React from "react";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import IconButton from "@material-ui/core/IconButton";
import handleDownload from "views/TimeOff/functions/downloadFiles";
import { STATES } from "common/constants/timeOff";
import StateManagingButtons from "../components/StateManagingButtons";
import CustomAvatar from "../../CustomAvatar";
import * as Styled from "../styles";

const getRows = (data, isAdmin, t, handleModal) => {
  const rows = data?.map((timeOff) => [
    {
      isVisible: true,
      content: <CustomAvatar
        name={ timeOff.employee.full_name }
        avatarImg={ timeOff.employee.profile_img_url }
        isVisibleBadge={ timeOff.state !== STATES.pending }
        position={ timeOff.employee.job_position.deprecated_position_name }
      />,
    },
    {
      isVisible: isAdmin,
      content:
  <Styled.Bold>
    { timeOff.employee.manager_name }
  </Styled.Bold>,
    },
    {
      isVisible: true,
      content:
  <Styled.DatesContainer>
    <Styled.DateItem>
      {" "}
      <CalendarTodayIcon fontSize={ "small" } />
      {" "}
      <p>{timeOff.starting_date}</p>
    </Styled.DateItem>
    <Styled.DateItem>
      {" "}
      <CalendarTodayIcon fontSize={ "small" } />
      {" "}
      <p>{timeOff.ending_date}</p>
    </Styled.DateItem>
  </Styled.DatesContainer>,
    },

    {
      isVisible: true,
      content: <Styled.Bold>{t(`types.${timeOff.time_off_type}`)}</Styled.Bold>,
    },

    {
      isVisible: true,
      content: <div>{timeOff.reason}</div>,
    },

    {
      isVisible: true,
      content: <Styled.Bold>{t(`states.${timeOff.state}`)}</Styled.Bold>,
    },

    {
      isVisible: true,
      content: timeOff.files_url.length > 0 ? <IconButton onClick={ () => { handleDownload(timeOff.files_url); } }><Styled.BlueCloudDownloadIcon /></IconButton > : "",
    },

    {
      isVisible: true,
      content: <StateManagingButtons handleModal={ handleModal } state={ timeOff.state } isAdmin={ isAdmin } id={ timeOff.id } />,
    },
  ]);
  return rows;
};

export default getRows;
