import React, { useState } from "react";
import PropTypes from 'prop-types';
import isEmpty from "lodash/isEmpty";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import SkeletonLoader from "components/SkeletonLoader";
import NoDataMessage from "components/NoDataMessage";
import { ASC, DESC } from "common/constants/index";
import EnhancedTableHead from "./components/TableHead";
import { RoundTableContainer, RoundPaper, CustomTableRow } from "./styles";

const TableComponent = (props) => {
  const {
    data, headers, sortAction, pagination, isLoading, rows,
  } = props;
  const [direction, setDirection] = useState(ASC);
  const [active, setActive] = useState("");

  const handleSort = (title) => {
    setActive(title);
    direction === ASC ? setDirection(DESC) : setDirection(ASC);
    sortAction(title, direction);
  };

  return (
    <RoundPaper>
      <RoundTableContainer>
        <Table>
          <EnhancedTableHead
            headers={ headers }
            direction={ direction }
            active={ active }
            handleSort={ handleSort }
          />
          {!isLoading && (
            <TableBody>
              {rows?.map((item, index) => (
                <CustomTableRow key={ index } even={ (index % 2) ? undefined : "true" }>
                  {item?.map((cell, cellIndex) => <TableCell key={ cellIndex }>{cell.isVisible ? cell.content : ""}</TableCell>)}
                </CustomTableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {isLoading && <SkeletonLoader />}
        {!isLoading && (isEmpty(data) ? <NoDataMessage />
          : (pagination && rows.length > 11 ) && (
            <TablePagination
              component={ "div" }
              count={ pagination.count || rows.length }
              rowsPerPage={ pagination.pageSize }
              page={ pagination.page }
              onChangePage={ pagination.handlePageChange }
              rowsPerPageOptions={ [] }
            />
          ))}
      </RoundTableContainer>
    </RoundPaper>
  );
};

TableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired, 
  rows: PropTypes.array.isRequired, 
  sortAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    handlePageChange: PropTypes.func,
    pageSize: PropTypes.number,
    page: PropTypes.number,
    count: PropTypes.number,
  }),
}

export default TableComponent;
