import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import { COMPONENT } from "../../common/constants/index";
import { IconDiv, CustomDiv } from './styles'

const TabIcons = (props) => {
  const { components } = props;
  const [value, setValue] = useState(1);
  const handleTabs = (value) => setValue(value);

  return (
    <div data-testid="tab-icons-component">
      <IconDiv>
        {components?.map((element, index) => (
            <IconButton
              key={index}
              onClick={() => handleTabs(index)}
              color={value === index ? "primary" : "default"}
              component={COMPONENT.span}
            >
              {element.icon}
            </IconButton>
          ))}
      </IconDiv>
      <CustomDiv>
        {components?.map((element, index) => (
            <div key={index}>{value === index && <>{element.component}</>}</div>
          ))}
      </CustomDiv>
    </div>
  );
};

TabIcons.propTypes = {
  components: PropTypes.array.isRequired,
};
export default TabIcons;
