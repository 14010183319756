const companyId = "company_id";
const collaborators = "collaborators";
const payrollItems = "payroll_items_salaries";

export const BENEFITS = {
  root: "benefits",
  collaborators,
  companyId,
  payrollItems,
};
