import React, { useState } from "react";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "../Button";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

const MenuPopup = (props) => {
  const { menuItems, button, typeStyle } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div data-testid="menu-container">
      <Button
        onClick={handleClick}
        data-testid="menu-button"
        typeStyle={typeStyle}
      >
        {button ? (
          <>
            {button}
            <ArrowDropDownOutlinedIcon />
          </>
        ) : (
          <MoreVertIcon className={classes.defaultIcon} />
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems &&
          menuItems.map((menuItem) => (
            <Grid container key={menuItem.title}>
              {menuItem.divider && <Divider className={classes.divider} />}
              <Grid item xs={menuItem.tooltip ? 10 : 12}>
                <MenuItem
                  onClick={() => {
                    if (menuItem.onClick) {
                      menuItem.onClick();
                    }
                    handleClose();
                  }}
                  disabled={menuItem.isDisabled}
                >
                  {menuItem.icon && (
                    <ListItemIcon className={classes.listIcon}>
                      {menuItem.icon}
                    </ListItemIcon>
                  )}
                  <Typography variant="inherit" noWrap>
                    {menuItem.title}
                  </Typography>
                </MenuItem>
              </Grid>
              <Grid item xs={2} className={classes.menuItemContainer}>
                {menuItem.tooltip}
              </Grid>
            </Grid>
          ))}
      </Menu>
    </div>
  );
};

MenuPopup.propTypes = {
  icon: PropTypes.object,
  menuItems: PropTypes.array.isRequired,
  typeStyle: PropTypes.string,
};

export default MenuPopup;
