import {
  COMPANY_ID_PARAM,
  OBJECT_KEYS,
} from "../../constants";

export const GOAL_RULES = {
  root: "/goal_rules",
};

export const GOALS = {
  root: "/employees",
  goals: "goals",
  archived: "&archieved=true",
};

export const GET_GOAL_RULES_URL = (companyId) => `${GOAL_RULES.root}${COMPANY_ID_PARAM(companyId)}`;

const GOALS_URL = (employeeId) => `${GOALS.root}/${employeeId}/${GOALS.goals}`;

export const GET_GOALS_EMPLOYEE_URL = (companyId, employeeId, isArchived) => `${GOALS_URL(employeeId)}${COMPANY_ID_PARAM(companyId)}${isArchived ? GOALS.archived : ""}`;

export const GET_UPDATE_DELETE_GOAL_URL = (companyId, employeeId, goalId) => `${GOALS_URL(employeeId)}/${goalId}${COMPANY_ID_PARAM(companyId)}`;

const GET_STATUS = (isArchived) => (isArchived ? OBJECT_KEYS.unarchive : OBJECT_KEYS.archive);

export const GET_ARCHIVE_UNARCHIVE_GOAL = (companyId, employeeId, goalId, isArchived) => `${GOALS_URL(employeeId)}/${goalId}/${GET_STATUS(isArchived)}/${COMPANY_ID_PARAM(companyId)}`;
