import {
  GET_MOVEMENTS_HISTORY_START,
  GET_MOVEMENTS_HISTORY_SUCESS,
  GET_MOVEMENT_HISTORY_SUCESS,
  GET_MOVEMENTS_HISTORY_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";
import { EMPLOYEES } from "common/paths/collaborators";

const getMovementsHistory = (employeeId, companyId) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_MOVEMENTS_HISTORY_START });
  const client = getLoginClient();
  const GET_MOVEMENTS_HISTORY = `${EMPLOYEES.root}/${employeeId}/${EMPLOYEES.movementsHistory}${EMPLOYEES.companyId}${companyId}`;
  client
    .get(GET_MOVEMENTS_HISTORY)
    .then((response) => {
      dispatch({
        type: GET_MOVEMENTS_HISTORY_SUCESS,
        payload: response.data.historical_movements,
      });
    })
    .catch(function(error) {
      dispatch({ type: GET_MOVEMENTS_HISTORY_FAILURE, payload: error });
    });
};

const createMovementHistory = (data, employeeId, companyId) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_MOVEMENTS_HISTORY_START });
  const client = getLoginClient();
  const CREATE_COLLABORATOR_URL = `${EMPLOYEES.root}/${employeeId}/${EMPLOYEES.movementsHistory}${EMPLOYEES.companyId}${companyId}`;
  client
    .post(CREATE_COLLABORATOR_URL, data)
    .then((response) => {
      dispatch({
        type: GET_MOVEMENT_HISTORY_SUCESS,
        payload: response.data.collaborator,
      });
    })
    .catch(function(error) {
      dispatch({ type: GET_MOVEMENTS_HISTORY_FAILURE, payload: error });
    });
};

export default {
  getMovementsHistory,
  createMovementHistory,
};
