import axios from "axios";
import {
  GET_SURVEY_PROCESSES_URL,
  GET_SURVEY_PROCESS_URL,
  CREATE_SURVEY_PROCESS_URL,
  GET_SENDING_SURVEY_PROCESS_URL,
} from "common/paths/surveyProcesses";
import { METHODS, INDEX } from "common/constants";
import {
  LOAD_SURVEY_PROCESSES_START,
  LOAD_SURVEY_PROCESSES_SUCCESS,
  LOAD_SURVEY_PROCESSES_FAILURE,
  LOAD_SURVEY_PROCESSES_EVALUATION_SUCCESS,
} from "./types";
import getLoginClient from "./loggedInClient";

const getSurveyProcesses = (companyId) => async (dispatch, getState) => {
  dispatch({ type: LOAD_SURVEY_PROCESSES_START });
  const client = getLoginClient();
  client
    .get(GET_SURVEY_PROCESSES_URL(companyId))
    .then((response) => {
      dispatch({
        type: LOAD_SURVEY_PROCESSES_SUCCESS,
        payload: response.data.survey_processes,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_SURVEY_PROCESSES_FAILURE, payload: error });
    });
};

const getSurveyProcess = (process, processId, companyId) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: LOAD_SURVEY_PROCESSES_START });
  const client = getLoginClient();
  client
    .get(GET_SURVEY_PROCESS_URL(process, companyId, processId))
    .then((response) => {
      dispatch({
        type: LOAD_SURVEY_PROCESSES_EVALUATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_SURVEY_PROCESSES_FAILURE, payload: error });
    });
};

const createOrUpdateSurveyProcess = (
  data,
  process,
  companyId,
  processId,
) => async (dispatch, getState) => {
  dispatch({ type: LOAD_SURVEY_PROCESSES_START });
  const client = getLoginClient();

  const CREATE_OR_UPDATE_SURVEY_PROCESS_URL = processId
    ? GET_SURVEY_PROCESS_URL(process, companyId, processId)
    : CREATE_SURVEY_PROCESS_URL(process, companyId);

  const clientMethod = processId ? client[METHODS.put] : client[METHODS.post];

  clientMethod(CREATE_OR_UPDATE_SURVEY_PROCESS_URL, data)
    .then((response) => {
      dispatch({
        type: LOAD_SURVEY_PROCESSES_EVALUATION_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_SURVEY_PROCESSES_FAILURE, payload: error });
    });
};

const sendSuveyProcess = async (processId, companyId, isReminder = false) => {
  try {
    const response = await axios.post(
      GET_SENDING_SURVEY_PROCESS_URL(processId, companyId, isReminder),
    );
    return response.data;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.errors?.evaluations[INDEX.zero],
    };
  }
};

export default {
  getSurveyProcesses,
  getSurveyProcess,
  createOrUpdateSurveyProcess,
  sendSuveyProcess,
};
