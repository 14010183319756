import qs from "qs";
import isEmpty from "lodash/isEmpty";
import { PARAMS_SERIALIZER_OPTIONS } from "common/constants";
import configAxios from "../configAxios";
import errorHandler from "../errorHandler";
import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
} from "../actionTypes/attrition";

const URL = {
  main: "/attrition_charts",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const getList = (query) => async (dispatch) => {
  const object = query.q;
  const dispatchRequest = Object.values(object).map((item) => !isEmpty(item));
  dispatch({
    type: GET_LIST_LOADING,
  });
  try {
    let responseList = [];
    if (dispatchRequest.find((item) => item === true)) {
      const response = await configAxios.get(URL.main, {
        params: query,
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });
      responseList = response.data.attrition_charts;
    }

    dispatch({
      type: GET_LIST,
      payload: responseList,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};
