import {
  LOAD_AUTH_START,
  LOAD_AUTH_SUCCESS,
  LOAD_AUTH_FAILURE,
  LOAD_AUTH_RECOVER_PASSWORD_SUCCESS,
  LOAD_AUTH_EMAIL_RECOVER_PASSWORD_SUCCESS,
  LOAD_AUTH_VALIDATE_TOKEN_SUCCESS,
  LOAD_COLLABORATOR_SUCCESS,
  GET_COLLABORATOR_SUCCESS,
  LOAD_CANDIDATE_SUCCESS,
  GET_CANDIDATE_SUCCESS,
  LOAD_DOCUMENT_SUCCESS,
  LOAD_INFO_TYPES_SUCCESS,
  LOAD_ORGUNIT_SUCCESS,
  LOAD_ORGUNIT_TYPES_SUCCESS,
  LOAD_PERFORMANCE_PROCESS_SUCCESS,
  GET_PERFORMANCE_SURVEY_RESULTS_SUCCESS,
  GET_PERFORMANCE_SURVEY_RESULT_SUCCESS,
  GET_TOP_RANKING_SUCCESS,
  GET_BOTTOM_RANKING_SUCCESS,
  LOAD_POTENTIAL_PROCESS_SUCCESS,
  LOAD_NINEBOX_SUCCESS,
  GET_NINEBOX_SUCCESS,
  LOAD_TYPE_OF_CONTRACT_SUCCESS,
  LOAD_SURVEY_RESULTS_SUCCESS,
  LOAD_COUNTRY_SUCCESS,
  LOAD_CITY_SUCCESS,
  LOAD_USERNAME_SUCCESS,
  LOAD_ORGANIZATION_CHART_SUCCESS,
} from "./types";
import getLogoutClient from "./loggedOutClient";
import getLoginClient from "./loggedInClient";
import {
  SIGN_IN,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
} from "common/paths/authentication";
import { LOAD_AUTH_CHANGE_PASSWORD_SUCCESS } from "./types";

const signIn = (bodyDocument, logout) => async (dispatch, getState) => {
  if (logout) {
    dispatch({ type: LOAD_AUTH_SUCCESS, payload: {} });
    dispatch({ type: LOAD_COLLABORATOR_SUCCESS, payload: {} });
    dispatch({ type: GET_COLLABORATOR_SUCCESS, payload: {} });
    dispatch({ type: LOAD_CANDIDATE_SUCCESS, payload: [] });
    dispatch({ type: GET_CANDIDATE_SUCCESS, payload: {} });
    dispatch({ type: LOAD_DOCUMENT_SUCCESS, payload: {} });
    dispatch({ type: LOAD_INFO_TYPES_SUCCESS, payload: {} });
    dispatch({ type: LOAD_ORGUNIT_SUCCESS, payload: null });
    dispatch({ type: LOAD_ORGUNIT_TYPES_SUCCESS, payload: null });
    dispatch({ type: LOAD_CITY_SUCCESS, payload: null });
    dispatch({ type: LOAD_USERNAME_SUCCESS, payload: null });
    dispatch({ type: LOAD_COUNTRY_SUCCESS, payload: null });
    dispatch({ type: LOAD_PERFORMANCE_PROCESS_SUCCESS, payload: null });
    dispatch({ type: GET_PERFORMANCE_SURVEY_RESULTS_SUCCESS, payload: null });
    dispatch({ type: GET_PERFORMANCE_SURVEY_RESULT_SUCCESS, payload: null });
    dispatch({ type: GET_TOP_RANKING_SUCCESS, payload: [] });
    dispatch({ type: GET_BOTTOM_RANKING_SUCCESS, payload: [] });
    dispatch({ type: LOAD_POTENTIAL_PROCESS_SUCCESS, payload: [] });
    dispatch({ type: LOAD_NINEBOX_SUCCESS, payload: [] });
    dispatch({ type: GET_NINEBOX_SUCCESS, payload: {} });
    dispatch({ type: LOAD_TYPE_OF_CONTRACT_SUCCESS, payload: null });
    dispatch({ type: LOAD_SURVEY_RESULTS_SUCCESS, payload: [] });
    dispatch({ type: LOAD_AUTH_EMAIL_RECOVER_PASSWORD_SUCCESS, payload: null });
    dispatch({ type: LOAD_AUTH_RECOVER_PASSWORD_SUCCESS, payload: null });
    dispatch({ type: LOAD_AUTH_CHANGE_PASSWORD_SUCCESS, payload: null });
    dispatch({ type: LOAD_AUTH_VALIDATE_TOKEN_SUCCESS, payload: {} });
    dispatch({ type: LOAD_ORGANIZATION_CHART_SUCCESS, payload: [] });
  } else {
    dispatch({ type: LOAD_AUTH_START });
    const clientLogout = getLogoutClient();

    clientLogout
      .post(SIGN_IN.root, bodyDocument)
      .then((response) => {
        dispatch({ type: LOAD_AUTH_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: LOAD_AUTH_FAILURE, payload: error });
      });
  }
};

const validateToken = (email, token) => async (dispatch, getState) => {
  dispatch({ type: LOAD_AUTH_START });
  const VALIDATE_TOKEN_URL = `${RESET_PASSWORD.root}${RESET_PASSWORD.resetToken}${RESET_PASSWORD.email}${email}${RESET_PASSWORD.resetPasswordToken}${token}`;
  const clientLogout = getLogoutClient();
  clientLogout
    .post(VALIDATE_TOKEN_URL)
    .then((response) => {
      dispatch({
        type: LOAD_AUTH_VALIDATE_TOKEN_SUCCESS,
        payload: response.data.user,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_AUTH_FAILURE, payload: error });
    });
};

const emailRecoverPassword = (email) => async (dispatch, getState) => {
  dispatch({ type: LOAD_AUTH_START });
  const EMAIL_RECOVER_PASSWORD_URL = `${RESET_PASSWORD.root}${RESET_PASSWORD.resetPassword}${RESET_PASSWORD.email}${email}`;
  const clientLogout = getLogoutClient();
  clientLogout
    .post(EMAIL_RECOVER_PASSWORD_URL, { email })
    .then((response) => {
      dispatch({
        type: LOAD_AUTH_EMAIL_RECOVER_PASSWORD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_AUTH_FAILURE, payload: error });
    });
};

const recoverPassword = (companyId, bodyDocument) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_AUTH_START });
  const CHANGE_PASSWORD_URL = `${RESET_PASSWORD.auth}${RESET_PASSWORD.users}${RESET_PASSWORD.password}${RESET_PASSWORD.companyId}${companyId}`;
  const clientLogout = getLogoutClient();
  clientLogout
    .put(CHANGE_PASSWORD_URL, { v1_user: bodyDocument })
    .then((response) => {
      dispatch({
        type: LOAD_AUTH_RECOVER_PASSWORD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_AUTH_FAILURE, payload: error });
    });
};

const changePassword = (companyId, userId, bodyDocument) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_AUTH_START });
  const loggedClient = getLoginClient();
  const CHANGE_PASSWORD_URL = `${CHANGE_PASSWORD.root}/${userId}/${CHANGE_PASSWORD.password}${CHANGE_PASSWORD.companyId}${companyId}`;

  loggedClient
    .patch(CHANGE_PASSWORD_URL, { user: bodyDocument })
    .then((response) => {
      dispatch({
        type: LOAD_AUTH_CHANGE_PASSWORD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_AUTH_FAILURE, payload: error });
    });
};

export default {
  signIn,
  validateToken,
  emailRecoverPassword,
  recoverPassword,
  changePassword,
};
