export const LOAD_INFO_TYPES_START = "LOAD_INFO_TYPES_START";
export const LOAD_INFO_TYPES_SUCCESS = "LOAD_INFO_TYPES_SUCCESS";
export const LOAD_INFO_TYPES_FAILURE = "LOAD_INFO_TYPES_FAILURE";
export const LOAD_DOCUMENT_START = "LOAD_DOCUMENT_START";
export const LOAD_DOCUMENT_SUCCESS = "LOAD_DOCUMENT_SUCCESS";
export const LOAD_DOCUMENT_FAILURE = "LOAD_DOCUMENT_FAILURE";
export const LOAD_ORGUNIT_TYPES_START = "LOAD_ORGUNIT_TYPES_START";
export const LOAD_ORGUNIT_TYPES_SUCCESS = "LOAD_ORGUNIT_TYPES_SUCCESS";
export const LOAD_ORGUNIT_TYPES_FAILURE = "LOAD_ORGUNIT_TYPES_FAILURE";
export const LOAD_ORGUNIT_START = "LOAD_ORGUNIT_START";
export const LOAD_ORGUNIT_SUCCESS = "LOAD_ORGUNIT_SUCCESS";
export const LOAD_ORGUNIT_TREE_SUCCESS = "LOAD_ORGUNIT_TREE_SUCCESS";
export const LOAD_ORGUNIT_FAILURE = "LOAD_ORGUNIT_FAILURE";

export const LOAD_COUNTRY_START = "LOAD_COUNTRY_START";
export const LOAD_COUNTRY_SUCCESS = "LOAD_COUNTRY_SUCCESS";
export const LOAD_COUNTRY_FAILURE = "LOAD_COUNTRY_FAILURE";
export const LOAD_CITY_START = "LOAD_CITY_START";
export const LOAD_CITY_SUCCESS = "LOAD_CITY_SUCCESS";
export const LOAD_CITY_FAILURE = "LOAD_CITY_FAILURE";

export const LOAD_USERNAME_START = "LOAD_USERNAME_START";
export const LOAD_USERNAME_SUCCESS = "LOAD_USERNAME_SUCCESS";
export const LOAD_USERNAME_FAILURE = "LOAD_USERNAME_FAILURE";

export const LOAD_CANDIDATE_START = "LOAD_CANDIDATE_START";
export const LOAD_CANDIDATE_SUCCESS = "LOAD_CANDIDATE_SUCCESS";
export const LOAD_CANDIDATE_FAILURE = "LOAD_CANDIDATE_FAILURE";
export const UPDATE_CANDIDATE_SUCCESS = "UPDATE_CANDIDATE_SUCCESS";
export const UPDATE_CANDIDATE_FAILURE = "UPDATE_CANDIDATE_FAILURE";
export const UPDATE_CANDIDATE_START = "UPDATE_CANDIDATE_START";
export const GET_CANDIDATE_SUCCESS = "GET_CANDIDATE_SUCCESS";

export const LOAD_COLLABORATOR_START = "LOAD_COLLABORATOR_START";
export const LOAD_COLLABORATOR_SUCCESS = "LOAD_COLLABORATOR_SUCCESS";
export const LOAD_COLLABORATOR_FAILURE = "LOAD_COLLABORATOR_FAILURE";
export const GET_COLLABORATOR_SUCCESS = "GET_COLLABORATOR_SUCCESS";
export const LOAD_ORGANIZATION_CHART_SUCCESS = "LOAD_ORGANIZATION_CHART_SUCCESS";
export const LOAD_COLLABORATORS_DOWLOAD_SUCCESS = "LOAD_COLLABORATORS_DOWLOAD_SUCCESS";

export const GET_MOVEMENTS_HISTORY_START = "GET_MOVEMENTS_HISTORY_START";
export const GET_MOVEMENTS_HISTORY_SUCESS = "GET_MOVEMENTS_HISTORY_SUCESS";
export const GET_MOVEMENT_HISTORY_SUCESS = "GET_MOVEMENT_HISTORY_SUCESS";
export const GET_MOVEMENTS_HISTORY_FAILURE = "GET_MOVEMENTS_HISTORY_FAILURE";

export const LOAD_AUTH_START = "LOAD_AUTH_START";
export const LOAD_AUTH_SUCCESS = "LOAD_AUTH_SUCCESS";
export const LOAD_AUTH_FAILURE = "LOAD_AUTH_FAILURE";
export const LOAD_AUTH_VALIDATE_TOKEN_SUCCESS = "LOAD_AUTH_VALIDATE_TOKEN_SUCCESS";
export const LOAD_AUTH_RECOVER_PASSWORD_SUCCESS = "LOAD_AUTH_RECOVER_PASSWORD_SUCCESS";
export const LOAD_AUTH_EMAIL_RECOVER_PASSWORD_SUCCESS = "LOAD_AUTH_EMAIL_RECOVER_PASSWORD_SUCCESS";
export const LOAD_AUTH_CHANGE_PASSWORD_SUCCESS = "LOAD_AUTH_CHANGE_PASSWORD_SUCCESS";

export const LOAD_POTENTIAL_PROCESS_START = "LOAD_POTENTIAL_PROCESS_START";
export const LOAD_POTENTIAL_PROCESS_SUCCESS = "LOAD_POTENTIAL_PROCESS_SUCCESS";
export const LOAD_POTENTIAL_PROCESS_FAILURE = "LOAD_POTENTIAL_PROCESS_FAILURE";

export const LOAD_NINEBOX_START = "LOAD_NINEBOX_START";
export const LOAD_NINEBOX_SUCCESS = "LOAD_NINEBOX_SUCCESS";
export const LOAD_NINEBOX_FAILURE = "LOAD_NINEBOX_FAILURE";
export const GET_NINEBOX_SUCCESS = "GET_NINEBOX_SUCCESS";

export const LOAD_PERFORMANCE_PROCESS_START = "LOAD_PERFORMANCE_PROCESS_START";
export const LOAD_PERFORMANCE_PROCESS_SUCCESS = "LOAD_PERFORMANCE_PROCESS_SUCCESS";
export const LOAD_PERFORMANCE_PROCESS_FAILURE = "LOAD_PERFORMANCE_PROCESS_FAILURE";
export const GET_PERFORMANCE_SURVEY_RESULTS_SUCCESS = "GET_PERFORMANCE_SURVEY_RESULTS_SUCCESS";
export const GET_PERFORMANCE_SURVEY_RESULT_SUCCESS = "GET_PERFORMANCE_SURVEY_RESULT_SUCCESS";

export const LOAD_TYPE_OF_CONTRACT_START = "LOAD_TYPE_OF_CONTRACT_START";
export const LOAD_TYPE_OF_CONTRACT_SUCCESS = "LOAD_TYPE_OF_CONTRACT_SUCCESS";
export const LOAD_TYPE_OF_CONTRACT_FAILURE = "LOAD_TYPE_OF_CONTRACT_FAILURE";

export const LOAD_SURVEY_RESULTS_START = "LOAD_SURVEY_RESULTS_START";
export const LOAD_SURVEY_RESULTS_SUCCESS = "LOAD_SURVEY_RESULTS_SUCCESS";
export const LOAD_SURVEY_RESULTS_FAILURE = "LOAD_SURVEY_RESULTS_FAILURE";
export const LOAD_SURVEY_DETAILS_START = "LOAD_SURVEY_DETAILS_START";
export const LOAD_SURVEY_DETAILS_SUCCESS = "LOAD_SURVEY_DETAILS_SUCCESS";
export const LOAD_SURVEY_DETAILS_FAILURE = "LOAD_SURVEY_DETAILS_FAILURE";

export const LOAD_TOP_RANKING_START = "LOAD_TOP_RANKING_START";
export const GET_TOP_RANKING_SUCCESS = "GET_TOP_RANKING_SUCCESS";
export const GET_BOTTOM_RANKING_SUCCESS = "GET_BOTTOM_RANKING_SUCCESS";
export const LOAD_TOP_RANKING_FAILURE = "LOAD_TOP_RANKING_FAILURE";

export const LOAD_PERSONALITY_START = "LOAD_PERSONALITY_START";
export const LOAD_PERSONALITY_SUCCESS = "LOAD_PERSONALITY_SUCCESS";
export const LOAD_PERSONALITY_FAILURE = "LOAD_PERSONALITY_FAILURE";

export const LOAD_HEAT_MAP_START = "LOAD_HEAT_MAP_START";
export const GET_HEAT_MAP_SUCCESS = "GET_HEAT_MAP_SUCCESS";
export const LOAD_HEAT_MAP_FAILURE = "LOAD_HEAT_MAP_FAILURE";

export const LOAD_MASSIVE_UPLOAD_START = "LOAD_MASSIVE_UPLOAD_START";
export const LOAD_MASSIVE_UPLOAD_FAILURE = "LOAD_MASSIVE_UPLOAD_FAILURE";
export const GET_MASSIVE_UPLOAD_SUCCESS = "GET_MASSIVE_UPLOAD_SUCCESS";

export const LOAD_CURRENCY_START = "LOAD_CURRENCY_START";
export const LOAD_CURRENCY_FAILURE = "LOAD_CURRENCY_FAILURE";
export const LOAD_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS";

export const LOAD_ROLES_START = "LOAD_ROLES_START";
export const LOAD_ROLES_FAILURE = "LOAD_ROLES_FAILURE";
export const LOAD_ROLES_SUCCESS = "GET_ROLES_SUCCESS";

export const LOAD_BENEFITS_START = "LOAD_BENEFITS_START";
export const LOAD_BENEFITS_FAILURE = "LOAD_BENEFITS_FAILURE";
export const GET_BENEFITS_SUCCESS = "GET_BENEFITS_SUCCESS";

export const LOAD_GOALS_START = "LOAD_GOALS_START";
export const LOAD_GOALS_FAILURE = "LOAD_GOALS_FAILURE";
export const LOAD_GOALS_SUCCESS = "LOAD_GOALS_SUCCESS";
export const LOAD_GOAL_SUCCESS = "LOAD_GOAL_SUCCESS";
export const GET_GOAL_RULES_SUCCESS = "GET_GOAL_RULES_SUCCESS";
export const LOAD_ARCHIVED_GOALS_SUCCESS = "LOAD_ARCHIVED_GOALS_SUCCESS";
export const LOAD_ARCHIVED_UNARCHIVED_SUCCESS = "LOAD_ARCHIVED_UNARCHIVED_SUCCESS";

export const LOAD_GOOD_LEADER_PROCESS_START = "LOAD_GOOD_LEADER_PROCESS_START";
export const LOAD_GOOD_LEADER_PROCESS_SUCCESS = "LOAD_GOOD_LEADER_PROCESS_SUCCESS";
export const LOAD_GOOD_LEADER_PROCESS_FAILURE = "LOAD_GOOD_LEADER_PROCESS_FAILURE";
export const LOAD_GOOD_LEADER_RANKING_START = "LOAD_GOOD_LEADER_RANKING_START";
export const GET_GOOD_LEADER_TOP_RANKING_SUCCESS = "GET_GOOD_LEADER_TOP_RANKING_SUCCESS";
export const GET_GOOD_LEADER_BOTTOM_RANKING_SUCCESS = "GET_GOOD_LEADER_BOTTOM_RANKING_SUCCESS";
export const LOAD_GOOD_LEADER_TOP_RANKING_FAILURE = "LOAD_GOOD_LEADER_TOP_RANKING_FAILURE";
export const LOAD_GOOD_LEADER_RESULTS_SUCCESS = "LOAD_GOOD_LEADER_RESULTS_SUCCESS";

export const LOAD_ENGAGEMENT_PROCESS_START = "LOAD_ENGAGEMENT_PROCESS_START";
export const LOAD_ENGAGEMENT_PROCESS_SUCCESS = "LOAD_ENGAGEMENT_PROCESS_SUCCESS";
export const LOAD_ENGAGEMENT_PROCESS_FAILURE = "LOAD_ENGAGEMENT_PROCESS_FAILURE";

export const LOAD_SURVEY_PROCESSES_START = "LOAD_SURVEY_PROCESSES_START";
export const LOAD_SURVEY_PROCESSES_SUCCESS = "LOAD_SURVEY_PROCESSES_SUCCESS";
export const LOAD_SURVEY_PROCESSES_FAILURE = "LOAD_SURVEY_PROCESSES_FAILURE";
export const LOAD_SURVEY_PROCESSES_EVALUATION_SUCCESS = "LOAD_SURVEY_PROCESSES_EVALUATION_SUCCESS";

// Feedback
export const LOAD_FEEDBACKS_START = "LOAD_FEEDBACKS_START";
export const LOAD_FEEDBACKS_FAILURE = "LOAD_FEEDBACKS_FAILURE";
export const LOAD_FEEDBACKS_SUCCESS = "LOAD_FEEDBACKS_SUCCESS";

// Feedback sent
export const LOAD_FEEDBACKS_SENT_START = "LOAD_FEEDBACKS_SENT_START";
export const LOAD_FEEDBACKS_SENT_FAILURE = "LOAD_FEEDBACKS_SENT_FAILURE";
export const LOAD_FEEDBACKS_SENT_SUCCESS = "LOAD_FEEDBACKS_SENT_SUCCESS";

// Send feedback
export const SEND_FEEDBACK_START = "SEND_FEEDBACK_START";
export const SEND_FEEDBACK_FAILURE = "SEND_FEEDBACK_FAILURE";
export const SEND_FEEDBACK_SUCCESS = "SEND_FEEDBACK_SUCCESS";

// Reset modals
export const RESET_MODAL = "RESET_MODAL";

// employment relationship
export const LOAD_EMPLOYMENT_RELATIONSHIP_START = "LOAD_EMPLOYMENT_RELATIONSHIP_START";
export const LOAD_EMPLOYMENT_RELATIONSHIP_SUCCESS = "LOAD_EMPLOYMENT_RELATIONSHIP_SUCCESS";
export const LOAD_EMPLOYMENT_RELATIONSHIP_FAILURE = "LOAD_EMPLOYMENT_RELATIONSHIP_FAILURE";

export const LOAD_TRAINING_PROCESS_START = "LOAD_TRAINING_PROCESS_START";
export const LOAD_TRAINING_PROCESS_SUCCESS = "LOAD_TRAINING_PROCESS_SUCCESS";
export const LOAD_TRAINING_PROCESS_FAILURE = "LOAD_TRAINING_PROCESS_FAILURE";

// positions
export const LOAD_POSITIONS_START = "LOAD_POSITIONS_START";
export const LOAD_POSITIONS_SUCCESS = "LOAD_POSITIONS_SUCCESS";
export const LOAD_POSITIONS_FAILURE = "LOAD_POSITIONS_FAILURE";

// hr analytics
export const LOAD_HC_MONTHLY_START = "LOAD_HC_MONTHLY_START";
export const LOAD_HC_MONTHLY_SUCCESS = "LOAD_HC_MONTHLY_SUCCESS";
export const LOAD_HC_MONTHLY_FAILURE = "LOAD_HC_MONTHLY_FAILURE";
export const LOAD_CTC_MONTHLY_START = "LOAD_CTC_MONTHLY_START";
export const LOAD_CTC_MONTHLY_SUCCESS = "LOAD_CTC_MONTHLY_SUCCESS";
export const LOAD_CTC_MONTHLY_FAILURE = "LOAD_CTC_MONTHLY_FAILURE";
