import axios from "axios";
import {
  GET_POSITIONS_URL, CREATE_POSITION_URL, UPDATE_POSITION_URL, DELETE_POSITION_URL,
} from "common/paths/positions";
import { METHODS } from "common/constants";
import {
  LOAD_POSITIONS_START,
  LOAD_POSITIONS_SUCCESS,
  LOAD_POSITIONS_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";

const getPositions = (companyId) => async (dispatch, getState) => {
  dispatch({ type: LOAD_POSITIONS_START });
  const client = getLoginClient();
  client
    .get(GET_POSITIONS_URL(companyId))
    .then((response) => {
      dispatch({
        type: LOAD_POSITIONS_SUCCESS,
        payload: response.data.positions,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_POSITIONS_FAILURE, payload: error });
    });
};

const createPosition = async (data, companyId) => {
  try {
    const response = await axios.post(
      CREATE_POSITION_URL(companyId),
      data,
    );
    return response.data.position;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

const updatePosition = async (id, data, companyId) => {
  try {
    const response = await axios.put(UPDATE_POSITION_URL(id, companyId), data);
    return response.data.position;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

const deletePosition = async (id, companyId, data) => {
  try {
    const dataToMove = {
      position: {
        movements: data,
      },
    };
    const response = await axios({
      method: METHODS.delete,
      url: DELETE_POSITION_URL(id, companyId),
      data: data ? dataToMove : "",
    });
    return response.data.position;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.detail || error?.response?.data?.message,
    };
  }
};

//  async/await
const getAllPositions = async (companyId) => {
  try {
    const resp = await axios.get(GET_POSITIONS_URL(companyId, true));
    return resp.data.positions;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

export default {
  getPositions,
  createPosition,
  updatePosition,
  deletePosition,
  getAllPositions,
};
