import { API_URL_RAILS_V1, COMPANY_ID_PARAM } from "common/constants";
const companyId = "?company_id=";

export const COUNTRIES = {
  root: "/countries",
  companyId,
};

export const CITIES = {
  root: "/cities",
  move: "&move_collaborators_to=",
  companyId,
};

export const CREATE_CITY = (companyId) =>
  `${API_URL_RAILS_V1}${CITIES.root}${COMPANY_ID_PARAM(companyId)}`;

export const UPDATE_CITY_URL = (id, companyId) =>
`${API_URL_RAILS_V1}${CITIES.root}/${id}${COMPANY_ID_PARAM(
  companyId
)}`;

const getDeleteValidation = (moveId) => {
  return moveId ? CITIES.move+moveId : "";
}

export const DELETE_CITY_URL = (id, companyId, moveId) => `${API_URL_RAILS_V1}${CITIES.root}/${id}${COMPANY_ID_PARAM(companyId)}${getDeleteValidation(moveId)}`;

export const GET_ALL_CITIES = (companyId) => `${API_URL_RAILS_V1}${CITIES.root}${COMPANY_ID_PARAM(companyId)}`;
