import { NINEBOX_PROCESSES } from "common/paths/potential";
import {
  LOAD_NINEBOX_START,
  LOAD_NINEBOX_SUCCESS,
  LOAD_NINEBOX_FAILURE,
  GET_NINEBOX_SUCCESS,
} from "./types";
import getLoginClient from "./loggedInClient";

const getNineBoxes = (id) => (dispatch, getState) => {
  dispatch({ type: LOAD_NINEBOX_START });
  const client = getLoginClient();
  const URL_NINEBOX_PROCESSESS = `${NINEBOX_PROCESSES.root}${NINEBOX_PROCESSES.mainSearch}${id}`;
  client
    .get(URL_NINEBOX_PROCESSESS)
    .then((response) => {
      dispatch({
        type: LOAD_NINEBOX_SUCCESS,
        payload: response.data.nine_boxes,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_NINEBOX_FAILURE, payload: error });
    });
};

const getNineBox = (id, companyId, userId) => async (dispatch, getState) => {
  dispatch({ type: LOAD_NINEBOX_START });
  const client = getLoginClient();
  const URL_NINEBOX_RESULTS = `${NINEBOX_PROCESSES.root}/${id}${NINEBOX_PROCESSES.mainSearch}${companyId}${NINEBOX_PROCESSES.userIdQuery}${userId}`;
  client
    .get(URL_NINEBOX_RESULTS)
    .then((response) => {
      dispatch({
        type: GET_NINEBOX_SUCCESS,
        payload: response.data.nine_box,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_NINEBOX_FAILURE, payload: error });
    });
};

const getNineBoxByEmployee = (id, companyId, employeeId) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: LOAD_NINEBOX_START });
  const client = getLoginClient();
  const URL_NINEBOX_BY_EMPLOYEE = `${NINEBOX_PROCESSES.root}/${id}/${NINEBOX_PROCESSES.collaborator}/${employeeId}${NINEBOX_PROCESSES.mainSearch}${companyId}`;
  client
    .get(URL_NINEBOX_BY_EMPLOYEE)
    .then((response) => {
      dispatch({
        type: GET_NINEBOX_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_NINEBOX_FAILURE, payload: error });
    });
};

export default {
  getNineBoxes, getNineBox, getNineBoxByEmployee,
};
