import {
  LOAD_GOALS_START,
  LOAD_GOALS_SUCCESS,
  LOAD_GOALS_FAILURE,
  GET_GOAL_RULES_SUCCESS,
  LOAD_GOAL_SUCCESS,
  LOAD_ARCHIVED_GOALS_SUCCESS,
  LOAD_ARCHIVED_UNARCHIVED_SUCCESS,
} from "../actions/types";

const initialState = {
  goals: [],
  archivedGoals: [],
  archivedOrUnarchive: [],
  goal: null,
  rules: [],
  isLoading: false,
  isLoadingArchived: false,
  error: null,
};

const goalsData = (state = initialState, action) => {
  switch (action.type) {
  case LOAD_GOALS_START:
    return {
      ...state,
      isLoading: true,
    };
  case LOAD_GOALS_SUCCESS:
    return {
      ...state,
      goals: action.payload,
      isLoading: false,
    };
  case LOAD_ARCHIVED_GOALS_SUCCESS:
    return {
      ...state,
      archivedGoals: action.payload,
      isLoading: false,
    };
  case LOAD_ARCHIVED_UNARCHIVED_SUCCESS:
    return {
      ...state,
      archivedOrUnarchive: action.payload,
      isLoading: false,
    };
  case LOAD_GOAL_SUCCESS:
    return {
      ...state,
      goal: action.payload,
      isLoading: false,
    };
  case GET_GOAL_RULES_SUCCESS:
    return {
      ...state,
      rules: action.payload,
      isLoading: false,
    };
  case LOAD_GOALS_FAILURE:
    return {
      ...state,
      error: action.payload,
      isLoading: false,
    };
  default:
    return state;
  }
};

export default goalsData;
