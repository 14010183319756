import React from "react";
import i18next from "i18next";
import FileSaver from "file-saver";
import moment from "moment";
import XLSX from "xlsx";
import { Workbook } from "exceljs";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import lodashRound from "lodash/round";
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import isString from "lodash/isString";
import find from "lodash/find";
import divide from "lodash/divide";
import gte from "lodash/gte";
import some from "lodash/some";
import charts from "theme/charts";
import {
  UNITS, CONTRACT_TYPES, CITIES, POSITIONS,
} from "views/GeneralAdministrator/functions";
import { getCountry } from "views/GeneralAdministrator/functions/cities";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import {
  WEEK_DAYS,
  MONTHS,
  SUCCESS,
  ERROR,
  ERROR_TYPE,
  XLSX_EXTENSION,
  FILE_TYPE_EXCEL,
  PASSWORD_VALIDATION_UTILS,
  ROLES,
  FULLDATE_FORMATS,
  DEFAULT_MIN_DECIMAL,
  ROUND,
  PERCENTAGE,
  KEY_PRESS,
  LOCAL_STORAGE_NAMES,
  ORG_UNITS,
  DOWNLOAD_TYPE,
  EXCEL_PROPS,
  OBJECT_KEYS,
  COUNTRIES,
  INDEX,
  RADIX_PARAMETER,
  MIN_VALUE,
} from "./constants";

export const historyPush = (history, path, search, state) => {
  history.push({
    pathname: path,
    search,
    state,
  });
};

export const formatDate = (date, format = FULLDATE_FORMATS.dash) => moment(date).utc().format(format);

export const formatDateAsUTC = (date) => moment(date)
  .utc()
  .format(FULLDATE_FORMATS.dash);

export const formatDateMonthYear = (date) => moment(date).format("MM/YY");

export const getPercent = (value, hasSymbol = false, round = ROUND.min) => {
  const result = lodashRound(value * PERCENTAGE.max, round);
  return hasSymbol ? `${result}%` : result;
};

export const getCurrentLanguage = () => i18next.language;

export const getMonthName = (date, t) => t(`common:months.${MONTHS[date.getMonth()]}`);

export const getUtilsFromDate = (date, t) => {
  date = new Date(date);
  const dayName = t(`common:week_days.${WEEK_DAYS[date.getDay()]}`);
  const monthName = getMonthName(date, t);
  return date && `${dayName}, ${date.getUTCDate()} ${monthName} ${date.getFullYear()}`;
};

export const getMonthYear = (date, t) => {
  date = new Date(date);
  const monthName = getMonthName(date, t);
  return date && `${monthName} - ${date.getFullYear()}`;
};

export const handleAlertAutohide = (
  errorStatus,
  setAlertAutohide,
  t,
  customSuccessText,
) => {
  const title = errorStatus
    ? "common:common.api_responses.error.title"
    : "common:common.api_responses.success.title";
  const message = errorStatus
    ? `${errorStatus}`
    : customSuccessText || "common:common.api_responses.success.save";

  setAlertAutohide({
    open: true,
    title: t(title),
    message: t(message),
    type: errorStatus ? ERROR : SUCCESS,
  });
};

export const onCloseAlertAutohide = (setAlertAutohide) => () => {
  setAlertAutohide({
    open: false,
    title: "",
    message: "",
    type: ERROR,
  });
};

export const isNotNull = (toValidate) => !isEqual(toValidate, null);

export const isUnauthorized = (errors) => isEqual(errors.toLowerCase(), ERROR_TYPE.unauthorized);

export const setInLocalStorage = (localStorageName, reducer) => {
  const isInLocalStorage = localStorage.getItem(localStorageName);

  if (isNotNull(reducer) && !isInLocalStorage) {
    return localStorage.setItem(localStorageName, JSON.stringify(reducer));
  }
  return null;
};

export const dispatchIfNotLocalStorage = (
  nameLocalStorage,
  action,
  dispatch,
) => {
  const isInLocalStorage = localStorage.getItem(nameLocalStorage);
  if (!isInLocalStorage) {
    dispatch(action);
  }
};

export const getItemFromLocalStorage = (name) => JSON.parse(localStorage.getItem(name)) || [];

export const mainDownloadExcel = (dataToDownload, fileName) => {
  const fileExtension = `.${XLSX_EXTENSION}`;
  const ws = XLSX.utils.json_to_sheet(dataToDownload);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, {
    bookType: XLSX_EXTENSION,
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: FILE_TYPE_EXCEL });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const validatePassword = (password, passwordConfirmation) => {
  let validPassword = {
    isValid: false,
    typeOfError: "",
  };

  if (
    isEqual(password, passwordConfirmation)
    && gte(password.length, PASSWORD_VALIDATION_UTILS.minLength)
  ) validPassword = { isValid: true };
  else {
    validPassword = {
      isValid: false,
      typeOfError: !gte(password.length, PASSWORD_VALIDATION_UTILS.minLength)
        ? PASSWORD_VALIDATION_UTILS.length
        : PASSWORD_VALIDATION_UTILS.confirmation,
    };
  }

  return validPassword;
};

export const isNotCandidate = (from, roles) => !includes(from, ROLES.CANDIDATE) && !includes(roles, ROLES.CANDIDATE);

// FIXME: this function will be modified, please do not care about it!
export const getAmountFormat = (
  amount,
  decimalLength = DEFAULT_MIN_DECIMAL,
  decimal = ".",
  thousands = ",",
) => {
  decimalLength = Math.abs(decimalLength);
  decimalLength = isNaN(decimalLength) ? DEFAULT_MIN_DECIMAL : decimalLength;

  const negativeSign = amount < 0 ? "-" : "";

  const i = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalLength)),
  ).toString();
  const j = i.length > 3 ? i.length % 3 : 0;

  return (
    negativeSign
    + (j ? i.substr(0, j) + thousands : "")
    + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)
    + (decimalLength
      ? decimal
        + Math.abs(amount - i)
          .toFixed(decimalLength)
          .slice(DEFAULT_MIN_DECIMAL)
      : "")
  );
};

export const getCurrencyFormat = (value, currency, currencies) => {
  if (currency && currencies) {
    const exchange = find(currencies, { name_with_code: currency })?.value;
    value = exchange ? divide(value, exchange) : "";
  }
  return Math.trunc(value);
};

export const hasEmptyInput = (control, objKey, validateAllFields) => includes(validateAllFields(control.getValues()), objKey);

export const hasAllValidatedFields = (arrayWithKeys) => isUndefined(
  Object.keys(arrayWithKeys).find((key) => isEqual(arrayWithKeys[key], true)),
);

export const preventEnter = (e) => isEqual(e.key, KEY_PRESS.enter) && e.preventDefault();

export const getEmployeeCompanyId = (user) => user?.company?.id || user?.person?.employee?.company_id;

export const getUserId = (user) => user?.id;

export const getEmployeeId = (user) => user?.employee?.id;

export const getEmployeeCompanySlug = (user) => user?.company?.slug;

export const getAutocompleteAttr = (newValue, nameOfAttr) => (isNull(newValue) || isString(newValue) ? newValue : newValue[nameOfAttr]);

export const getDivisions = (orgUnits) => (!isEmpty(orgUnits)
  ? orgUnits.filter(
    (organization) => isNull(organization.parent_id)
          && isEqual(organization.organization_unit_type_name, ORG_UNITS.division),
  )
  : []);

export const getCities = (countrySelected) => {
  const allCities = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.cities);
  const citiesSelected = allCities.filter((city) => isEqual(city.country_id, countrySelected));
  return citiesSelected;
};

export const getAreas = (organizationUnits, divisionSelected) => {
  const allOrgUnits = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.organizationUnits);
  const areasSelected = allOrgUnits.filter((unit) => unit.parent_id === divisionSelected);
  return areasSelected;
};

export const getElementNewValue = (value, name) => (!isNull(value) ? name : "");

export const roles = localStorage.user
  ? JSON.parse(localStorage.user).roles_name
  : null;

export const isAdminNala = (roles) => !isEmpty(roles?.filter((role) => isEqual(role?.name, ROLES.ADMIN_NALA)));

export const isAdmin = (roles) => some(roles, (rol) => isEqual(rol.name, ROLES.ADMIN) || isEqual(rol.name, ROLES.ADMIN_NALA));

export const isCandidate = (roles) => !isEmpty(roles?.filter((role) => isEqual(role?.name, ROLES.CANDIDATE)));

export const getDownloadTypes = (t) => [
  { value: DOWNLOAD_TYPE.none, label: t("performance:dashboard.download") },
  { value: DOWNLOAD_TYPE.excel, label: t("performance:dashboard.excel") },
  { value: DOWNLOAD_TYPE.pdf, label: t("performance:dashboard.download_pdf") },
];

export const getPropertyByLocation = (location, propName) => location[propName];

// Receives an array and the name of the key to be listed
// Returns an array
export const getStringList = (data, name) => data?.map((item) => item[name]);

const excelStyleByRequiredColumn = (cell, isListType) => {
  const border = EXCEL_PROPS.style.border.thin;
  cell.fill = {
    type: EXCEL_PROPS.type.pattern,
    pattern: EXCEL_PROPS.pattern.solid,
    fgColor: { argb: EXCEL_PROPS.style.colors.fgColorRequired },
    bgColor: { argb: EXCEL_PROPS.style.colors.bgColorRequired },
  };
  cell.border = {
    top: { style: border },
    left: { style: border },
    bottom: { style: border },
    right: { style: border },
  };
  cell.font = {
    bold: isListType,
    name: EXCEL_PROPS.font,
  };
};

export const getLetters = (value) => value.replace(/\d/g, "");

export const getExcelFormula = (columName, dataList) => `=${OBJECT_KEYS.listings}!$${columName}$${EXCEL_PROPS.position.one}:$${columName}${dataList.length}`;

export const mainDownloadExcelCustom = (dataToDownload, fileName, t) => {
  const header = dataToDownload.map((item) => item.name);
  const fileExtension = `.${XLSX_EXTENSION}`;
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(fileName);
  // Add worksheet hidden for lists
  const worksheetList = workbook.addWorksheet(OBJECT_KEYS.listings);
  worksheetList.state = EXCEL_PROPS.state.hidden;
  // Add Header Row
  worksheet.addRow(header);
  // Custom cells by column
  worksheet.columns.forEach((column, index) => {
    const columnData = dataToDownload[index];
    const dataList = getStringList(columnData.list, columnData.keyList);
    let maxLength = EXCEL_PROPS.style.minLength;
    column[EXCEL_PROPS.eachCell]({ includeEmpty: true }, (cell) => {
      const columnLength = cell.value
        ? cell.value.toString().length
        : EXCEL_PROPS.style.minWidth;
      if (columnLength > maxLength) {
        maxLength = columnLength;
      }
      // Styles only if required column
      columnData.isRequired
        && excelStyleByRequiredColumn(cell, !isEmpty(dataList));
      // Only list type
      if (!isEmpty(dataList)) {
        const columName = getLetters(cell._address);
        // Add list to worksheet hidden
        worksheetList.getColumn(index + 1).values = dataList;
        worksheet.dataValidations.add(
          `${cell._address}:${cell._address}${EXCEL_PROPS.plusCell}`,
          {
            type: EXCEL_PROPS.type.list,
            allowBlank: false,
            formulae: [getExcelFormula(columName, dataList)],
            showErrorMessage: true,
            errorStyle: EXCEL_PROPS.errorStyle.error,
            error: t("common:common.excel.selectError"),
          },
        );
      }
    });
    column.width = maxLength < EXCEL_PROPS.style.minWidth
      ? EXCEL_PROPS.style.minWidth
      : maxLength + EXCEL_PROPS.style.maxLength;
  });
  workbook.xlsx.writeBuffer().then((data) => {
    const dataToSave = new Blob([data], {
      type: XLSX_EXTENSION,
    });
    FileSaver.saveAs(dataToSave, `${fileName}${fileExtension}`);
  });
};

export const getAllExceptSelected = (allItems, findByKey) => allItems.filter((item) => item.id !== findByKey);

export const getObjectStructure = (module, data) => {
  let dataStructure;
  switch (module) {
  case UNITS:
    dataStructure = { organization_unit: data };
    break;
  case CONTRACT_TYPES:
    dataStructure = { type_of_contract: data };
    break;
  case CITIES:
    dataStructure = { city: data };
    break;
  case POSITIONS:
    dataStructure = { position: data };
    break;
  default:
    break;
  }
  return dataStructure;
};

export const isColombianAdmin = (roles) => !isEmpty(roles?.filter((role) => isEqual(role?.name, ROLES.ADMIN_COLOMBIA)));

export const isColombianEmployee = (country) => country === COUNTRIES.colombia;

export const handleAlertDetail = (
  reducer,
  setAlert,
  t,
) => {
  if (reducer.error) {
    setAlert({
      open: true,
      title: t("common:common.api_responses.error.title"),
      message:
        reducer.error.data.errors_messages?.join("\n")
        || reducer.error.data.detail,
      type: ERROR,
    });
  } else {
    setAlert({
      open: true,
      title: t("common:common.api_responses.success.title"),
      message: t("common:common.api_responses.success.save"),
      type: SUCCESS,
    });
  }
};

export const getFormattedItems = (localStorageName, list) => {
  const localStorageElements = localStorageName ? getItemFromLocalStorage(localStorageName) : list;
  const formattedElements = localStorageElements?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  return formattedElements;
};

export const isDateGreaterThanToday = (value) => value > moment();

export const capitalizeFirstLetter = (string) => string[INDEX.zero].toUpperCase() + string.slice(INDEX.one);

export const getPositionId = (positionName) => {
  const allPositions = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.positions);
  return allPositions.find((item) => item.name === positionName);
};

export const getFirstThreeLetter = (word) => word.slice(0, 3).toUpperCase();

export const getListMonthName = ((monthNum, t) => {
  const monthIndex = monthNum - 1;
  const monthToReturn = MONTHS[monthIndex];
  return getFirstThreeLetter(t(`common:months.${monthToReturn}`));
});

export const getLabelCharts = (reducer, t) => {
  const label = reducer.map((item) => getListMonthName(item.month, t));
  return label;
};

export const getDataCharts = (reducer, dataToExtract) => reducer.map((item) => item[dataToExtract]);

// Get acknowledgement Icon given an ID
export const getAcknowledgementIcon = (id, list) => list.find((item) => item.id === id);

export const getNormalizedString = (string) => string?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const getFormattedTags = (options, nameAttr) => {
  const formattedElements = options?.map((item) => ({
    id: item.id,
    value: item[nameAttr],
    label: item[nameAttr],
    country: getCountry(item.country_id)?.name,
  }));
  return formattedElements;
};

export const getOptionListId = (tags) => {
  let elements = [];
  if (tags) {
    elements = tags?.map((tag) => tag.id);
  }

  return elements;
};

export const getOptionsFormat = (options) => options?.map((item) => {
  const newItem = {
    value: item.id,
    label: item.name || item.description || item.full_name, // note: add here all posible ways the read the label for an autocomplete
  };
  return newItem;
});

export const isManager = (roles) => some(roles, (rol) => rol.name === ROLES.MANAGER);

export const isAdminOrManager = (roles) => isAdmin(roles) || isManager(roles);

export const getCompanyCountriesList = (countriesList, collaborators) => {
  const companyCountriesList = countriesList.filter(
    (country) => collaborators.find((collaborator) => collaborator.country_name === country.name),
  );
  return companyCountriesList;
};

export const getMaxYLabel = (dataList) => {
  const maxData = Math.floor(Math.max(...dataList) * 1.25);
  return maxData;
};

export const renderCustomLabelTicks = (props) => {
  const {
    payload, x, y, textAnchor, stroke, radius,
  } = props;
  return (
    <g>
      <text
        radius={ radius }
        stroke={ stroke }
        x={ x }
        y={ y }
        textAnchor={ textAnchor }
        fill={ charts.colors.lightText }
        fontSize={ charts.labels.standar }
      >
        {`${payload.value}%`}

      </text>
    </g>
  );
};

export const isCurrentCompanySlug = (user, companySlug) => user?.company?.slug === companySlug;
export const isCurrentRole = (user, role) => user?.roles?.some((roleItem) => roleItem?.name === role);

// note:
// The radix parameter is used to specify which numeral system to be used, for example, a radix of 16 (hexadecimal) indicates that the number in the string should be parsed from a hexadecimal number to a decimal number.
// If the radix parameter is omitted, JavaScript assumes the following:
// If the string begins with any other value, the radix is 10 (decimal)
export const getParamEmployeeId = (user, params) => parseInt(params?.collaborator, RADIX_PARAMETER) || parseInt(params?.candidate, RADIX_PARAMETER) || user?.employee?.id;

export const getMainRoute = () => (isCurrentRole(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user), ROLES.CANDIDATE)
  ? "profile"
  : "planning");

export const getError = (error, reset, t, dispatch) => {
  const toastMessage = {
    title: t("common:common.api_responses.error.title"),
  };
  if (error) {
    toastMessage.message = error;
    toast(MESSAGE_TYPES.error, toastMessage);
    dispatch(reset);
  }
};

export const getAverageResult = (results) => results.map((result) => result?.average_result?.toFixed(DEFAULT_MIN_DECIMAL));

export const getLastProcessId = (processes) => processes.find((process) => process.average_result !== null).id;

export const calculateLossRisk = (list, collaboratorId) => {
  const attritionLossRisk = list?.map((item) => {
    let verticeValues = MIN_VALUE;
    let calculatedAttrition = MIN_VALUE;
    if (item.employee_id === collaboratorId) {
      verticeValues = item.attrition_axis_values
        .filter((vertice) => vertice.value !== null)
        .map((vertice) => {
          const sumatory = vertice.value * vertice.weighing;
          return { sumatory, weight: vertice.weighing };
        });

      const sumatoryOfVertices = verticeValues.reduce((currentValue, vertice) => currentValue + vertice.sumatory, MIN_VALUE);
      const weightOfVertices = verticeValues.reduce((currentValue, vertice) => currentValue + vertice.weight, MIN_VALUE);
      calculatedAttrition = 1 - sumatoryOfVertices / weightOfVertices;
    }
    return calculatedAttrition;
  });
  return attritionLossRisk;
};
