import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: "#3F51B5",
    height: "5px",
    top: "45px",
  },
  tabs: {
    marginLeft: "-40px",
    marginBottom: "-3px",
  },
}));

export default useStyles;
