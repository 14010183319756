import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import SelectController from "components/SelectController";
import DateInputController from "components/DateInputController";
import Button from "components/Button";
import InputForm from "components/InputForm";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import { getEmployeeId } from "common/utils";
import { SessionContext } from "modules/session/context";
import { create } from "redux/actions/timeOffActions";
import {
  INPUT_TYPE,
  BUTTON_TYPE,
  SIZE,
  ALIGN_ITEMS,
  VARIANT,
} from "common/constants";
import FileInputTimeOff from "./components/FileInputTimeOff";
import useStyles, { SubmitGrid, FileGrid } from "./styles";

const TimeOffForm = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common"]);
  const {
    isMobile,
    selectOptions,
    onChangeFile = () => { },
    onClose = () => { },
  } = props;
  const classes = useStyles();

  const { handleSubmit, control, watch } = useForm();
  const watchSelect = watch("time_off_type", "default");
  const watchFromDate = watch("starting_date");
  const today = new Date();
  const [files, setFiles] = useState([]);
  const removeFile = (selectedFile) => {
    const filtered = files.filter((file) => file.name !== selectedFile);
    return setFiles(filtered);
  };
  const updateFiles = (target) => {
    const file = target.files[0];
    target.value = "";
    if (file) {
      if (file.size > 5000000) {
        return toast(MESSAGE_TYPES.error, {
          title: t("common.files.exceedSizeLimit"),
        });
      }
      return setFiles((prevValue) => [...prevValue, file]);
    }
  };

  // const theDate = new Date(watchFromDate);
  // theDate.setDate(theDate.getDate() + 30);
  // const maxToDate = `${theDate.getMonth()
  //   + 1}/${theDate.getDate()}/${theDate.getUTCFullYear()}`;

  const { loadingProcess } = useSelector(
    ({ timeOffReducer }) => timeOffReducer,
  );

  const {
    state: { user },
  } = useContext(SessionContext);
  const userId = getEmployeeId(user);


  const onSubmit = async () => {
    const formData = new FormData();
    const form = control.getValues();
    files.forEach((element) => formData.append("time_off[files][]", element));
    formData.append("time_off[employee_id]", userId);
    formData.append("time_off[starting_date]", form.starting_date);
    formData.append("time_off[ending_date]", form.ending_date);
    formData.append("time_off[reason]", form.reason);
    formData.append("time_off[time_off_type]", form.time_off_type);
    await dispatch(create(formData));
    return onClose();
  };

  return (
    <form onSubmit={ handleSubmit(onSubmit) }>
      <Grid container direction={ "column" } spacing={ 5 }>
        <Grid item container direction={ "row" } spacing={ 4 }>
          <Grid item xs={ 12 } lg={ 6 }>
            <DateInputController
              control={ control }
              label={ t("common.fromDate") }
              name={ "starting_date" }
              value={ today }
              customStyles={ classes.dateInputs }
            />
            <DateInputController
              control={ control }
              label={ t("common.toDate") }
              name={ "ending_date" }
              minDate={ watchFromDate }
              // maxDate={ maxToDate }
              value={ today }
              customStyles={ classes.dateInputs }
            />
          </Grid>
          <Grid item xs={ 12 } lg={ 6 }>
            <SelectController
              control={ control }
              menuItems={ [
                { value: "default", label: t("common.type") },
                ...selectOptions,
              ] }
              defaultValue={ "default" }
              name={ "time_off_type" }
              id={ "time_off_type" }
            />
          </Grid>
        </Grid>
        <Grid item container spacing={ 3 }>
          <Grid item xs={ 12 } lg={ 12 }>
            <InputForm
              type={ INPUT_TYPE.text }
              control={ control }
              placeholder={ t("common.describe_description") }
              name={ "reason" }
              label={ t("common.reason(optional)") }
              multiline={ 4 }
            />
          </Grid>
          <FileGrid isMobile={ isMobile } item xs={ 12 } lg={ 12 }>
            <FileInputTimeOff
              control={ control }
              onChangeFile={ onChangeFile }
              files={ files }
              updateFiles={ updateFiles }
              removeFile={ removeFile }
            />
          </FileGrid>
          <SubmitGrid
            item
            lg={ 12 }
            sm={ 12 }
            container
            direction={ "row" }
            alignItems={ ALIGN_ITEMS.center }
          >
            <Button
              variant={ VARIANT.contained }
              type={ BUTTON_TYPE.submit }
              typeStyle={ "submit" }
              size={ SIZE.medium }
              isLoading={ loadingProcess }
              isDisabled={ watchSelect === "default" }
            >
              {t("common.send_request")}
            </Button>
          </SubmitGrid>
        </Grid>
      </Grid>
    </form>
  );
};

TimeOffForm.propTypes = {
  selectOptions: PropTypes.any.isRequired,
  onChangeFile: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

export default TimeOffForm;
