import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: "0 15px 0 -10px",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  profileName: {
    color: theme.palette.text.link,
    fontWeight: 500,
    fontSize: 14,
  },
  profilePosition: {
    fontSize: 14,
    color: theme.palette.text.darkBlue,
  },
  profileCPF: {
    fontSize: 13,
  },
  close: {
    position: "absolute",
    right: 10,
    top: 10,
  },
}));

export default useStyles;
