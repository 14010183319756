import axios from "axios";
import {
  GET_ORGANIZATION_UNITS_URL,
  GET_ORGANIZATION_UNITS_TYPES_URL,
  CREATE_ORGANIZATION_UNIT_URL,
  UPDATE_ORGANIZATION_UNIT_URL,
  DELETE_ORGANIZATION_UNIT_URL,
} from "common/paths/organizationUnits";
import { METHODS } from "common/constants";
import {
  LOAD_ORGUNIT_START,
  LOAD_ORGUNIT_SUCCESS,
  LOAD_ORGUNIT_FAILURE,
  LOAD_ORGUNIT_TYPES_START,
  LOAD_ORGUNIT_TYPES_SUCCESS,
  LOAD_ORGUNIT_TREE_SUCCESS,
  LOAD_ORGUNIT_TYPES_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";

const getOrganizationUnits = (companyId, tree) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_ORGUNIT_START });
  const client = getLoginClient();

  client
    .get(GET_ORGANIZATION_UNITS_URL(companyId, tree))
    .then((response) => {
      dispatch({
        type: tree ? LOAD_ORGUNIT_TREE_SUCCESS : LOAD_ORGUNIT_SUCCESS,
        payload: tree ? response.data : response.data.organization_units,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_ORGUNIT_FAILURE, payload: error });
    });
};

const getOrganizationUnitsTypes = (companyId) => async (dispatch, getState) => {
  dispatch({ type: LOAD_ORGUNIT_TYPES_START });
  const client = getLoginClient();

  client
    .get(GET_ORGANIZATION_UNITS_TYPES_URL(companyId))
    .then((response) => {
      dispatch({
        type: LOAD_ORGUNIT_TYPES_SUCCESS,
        payload: response.data.organization_unit_types,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_ORGUNIT_TYPES_FAILURE, payload: error });
    });
};

//  async/await
const getAllOrganizationUnits = async (companyId) => {
  try {
    const resp = await axios.get(
      GET_ORGANIZATION_UNITS_URL(companyId, false, true)
    );
    return resp.data.organization_units;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

const createOrganizationUnits = async (data, companyId) => {
  try {
    const response = await axios.post(
      CREATE_ORGANIZATION_UNIT_URL(companyId),
      data
    );
    return response.data.organization_unit;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

const updateOrganizationUnits = async (id, data, companyId) => {
  try {
    const response = await axios.put(
      UPDATE_ORGANIZATION_UNIT_URL(id, companyId),
      data
    );
    return response.data.organization_unit;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

const deleteOrganizationUnits = async (id, companyId, data) => {
  try {
    const dataToMove = {
      organization_unit: {
        movements: data,
      },
    };
    const response = await axios({
      method: METHODS.delete,
      url: DELETE_ORGANIZATION_UNIT_URL(id, companyId),
      data: data ? dataToMove : "",
    });
    return response.data.organization_unit;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.detail || error?.response?.data?.message,
    };
  }
};

export default {
  getOrganizationUnits,
  getOrganizationUnitsTypes,
  createOrganizationUnits,
  updateOrganizationUnits,
  deleteOrganizationUnits,
  getAllOrganizationUnits
};
