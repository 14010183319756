import {
  LOAD_HC_MONTHLY_START,
  LOAD_HC_MONTHLY_SUCCESS,
  LOAD_HC_MONTHLY_FAILURE,
  LOAD_CTC_MONTHLY_START,
  LOAD_CTC_MONTHLY_SUCCESS,
  LOAD_CTC_MONTHLY_FAILURE,
} from "../actions/types";

const initialState = {
  hcMonthly: [],
  isLoadingHc: false,
  errorHc: null,
  ctcMonthly: [],
  isLoadingCtc: false,
  errorCtc: null,
};

const hrAnalyticsData = (state = initialState, action) => {
  switch (action.type) {
  case LOAD_HC_MONTHLY_START:
    return {
      ...state,
      isLoadingHc: true,
    };
  case LOAD_HC_MONTHLY_SUCCESS:
    return {
      ...state,
      hcMonthly: action.payload,
      isLoadingHc: false,
    };
  case LOAD_CTC_MONTHLY_START:
    return {
      ...state,
      isLoadingCtc: true,
    };
  case LOAD_CTC_MONTHLY_SUCCESS:
    return {
      ...state,
      ctcMonthly: action.payload,
      isLoadingCtc: false,
    };
  case LOAD_HC_MONTHLY_FAILURE:
    return {
      ...state,
      errorHc: action.payload,
      isLoadingHc: false,
    };
  case LOAD_CTC_MONTHLY_FAILURE:
    return {
      ...state,
      errorCtc: action.payload,
      isLoadingCtc: false,
    };
  default:
    return state;
  }
};

export default hrAnalyticsData;
