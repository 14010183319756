import React from "react";
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { INPUT_TYPE } from "common/constants/index"
import { useEffect } from "react";
import { StyledInputForm } from "./styles"


const InputTextSearch = (props) => {
  const { inputHandler } = props;
  const { t } = useTranslation(["timeOff"]);
  const { control, watch } = useForm();
  const hasSearchIcon = true;
  const searchWatch = watch("search")

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      inputHandler(searchWatch)
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchWatch, inputHandler])

  return (
    <>
      <StyledInputForm
        type={INPUT_TYPE.text}
        control={control}
        name={"search"}
        placeholder={t(`search collaborators`)}
        hasSearchIcon={hasSearchIcon}
      />
    </>
  )

}

export default InputTextSearch;
