import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import GTranslateIcon from "@material-ui/icons/GTranslate";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/Button";
import { LANGUAGES, LANGUAGES_NAMES } from "common/constants";
import useStyles from "./styles";

const ChangeLanguageSelect = (props) => {
  const { isCustomIcon } = props;
  const classes = useStyles();
  const [openSelect, setOpenSelect] = useState(false);
  const [language, setLanguage] = useState(LANGUAGES.en);
  const { i18n } = useTranslation();

  const handleChange = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const handleOpenClose = () => {
    setOpenSelect(!openSelect);
  };

  const isBlueTheme = isCustomIcon && classes.blueTheme;

  return (
    <div data-testid="change-language-select">
      <Button onClick={handleOpenClose}>
        <GTranslateIcon className={clsx(classes.icon, isBlueTheme)} />
      </Button>
      <FormControl className={classes.formControl}>
        <Select
          className={clsx(classes.select, isBlueTheme)}
          open={openSelect}
          onClose={handleOpenClose}
          onOpen={handleOpenClose}
          value={language}
          onChange={handleChange}
          inputProps={{
            classes: {
              icon: clsx(classes.icon, isBlueTheme),
            },
          }}
        >
          <MenuItem value="en">{LANGUAGES_NAMES.english}</MenuItem>
          <MenuItem value="es">{LANGUAGES_NAMES.spanish}</MenuItem>
          <MenuItem value="pr">{LANGUAGES_NAMES.portuguese}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

ChangeLanguageSelect.propTypes = {
  isCustomIcon: PropTypes.bool,
};

export default ChangeLanguageSelect;
