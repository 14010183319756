import React, {
  useState, useEffect, useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { usePrevious } from "react-tidy";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import Grid from "@material-ui/core/Grid";
import InputTag from "components/InputTag";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import SkeletonLoader from "components/SkeletonLoader";
import {
  SIZE, SKELETONS_NUMBER, FILTERS_KEYS, OBJECT_KEYS, VARIANT, BUTTON_STYLE_TYPES, INDEX, ALIGN_ITEMS,
} from "common/constants";
import { getOptionListId, getFormattedTags, getCompanyCountriesList } from "common/utils";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import { ReactComponent as FilterIcon } from "assets/images/general/filter.svg";
import { buildNewOptionArray } from "views/GeneralAdministrator/functions/units";
import { getList as getCountriesList, resetState as resetStateCountry } from "redux/actions/common/countryActions";
import { getList as getCitiesList, resetState as resetStateCities } from "redux/actions/common/cityActions";
import { getList as getOrgUnitsList, resetState as resetStateOrgUnit } from "redux/actions/common/orgUnitActions";
import { getMainList as getCollaboratorsMainList } from "redux/actions/collaboratorActions";
import { getList as getStatesList, resetState as resetStateStates } from "redux/actions/common/stateActions";
import { getFilterAction, handleUpdatedValues } from "./functions";
import {
  useStyles, FilterIconContainer, StyledFilterButton, StyledGrid,
} from "./styles";

const Filter = (props) => {
  const {
    type, externalParams, isButton, isMultiple, isLabel, externalHandler, defaultValues, dispatched: externalDispatched, limitTag,
  } = props;
  const [showFilters, setShowFilters] = useState(!isButton);
  const classes = useStyles();
  const { t } = useTranslation(["common", "timeOff"]);
  const { register } = useForm();
  const [dispatched, setDispatched] = useState(false);
  const [filterToUse, setFilterToUse] = useState([]);
  const [values, setValues] = useState({
    // Add here all filters
    managers: null,
    countries: null,
    cities: null,
    unities: null,
    periods: null,
    states: null,
  });
  const [tags, setTags] = useState({
    // Add here all filters
    managers: [],
    countries: [],
    cities: [],
    unities: [],
    periods: [],
    states: [],
  });
  const lastExternalParamsValue = usePrevious(externalParams, 1);

  const {
    list: countriesList,
    loadingList: isCountryLoadingList,
    errorList: countryErrorList,
  } = useSelector(({ countryReducer }) => countryReducer);

  const {
    list: citiesList,
    loadingList: isCityLoadingList,
    errorList: cityErrorList,
  } = useSelector(({ cityReducer }) => cityReducer);

  const {
    list: orgUnitList,
    loadingList: isOrgUnitLoadingList,
    errorList: orgUnitErrorList,
  } = useSelector(({ orgUnitReducer }) => orgUnitReducer);

  const {
    mainList: collaboratorsMainList,
    isLoadingList: isCollaboratorsLoadingList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    list: statesList,
    loadingList: isStateLoadingList,
    errorList: stateErrorList,
  } = useSelector(({ stateReducer }) => stateReducer);

  const isLoadingFilters = isCountryLoadingList || isCityLoadingList || isOrgUnitLoadingList || (isCollaboratorsLoadingList && !collaboratorsMainList) || isStateLoadingList;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountriesList());
    dispatch(getCitiesList());
    dispatch(getOrgUnitsList());
    dispatch(getCollaboratorsMainList());
    dispatch(getStatesList());
  }, [dispatch]);

  useEffect(() => {
    if (countryErrorList || cityErrorList || orgUnitErrorList || stateErrorList) {
      const toastMessage = {
        title: t("common:common.api_responses.error.title"),
        message: countryErrorList || cityErrorList || orgUnitErrorList || stateErrorList,
      };
      toast(MESSAGE_TYPES.error, toastMessage);
      dispatch(resetStateCountry());
      dispatch(resetStateOrgUnit());
      dispatch(resetStateCities());
      dispatch(resetStateStates());
    }
  }, [countryErrorList, t, dispatch, orgUnitErrorList, cityErrorList, stateErrorList]);

  const getListToUse = useCallback(
    (filterType, collaboratorsMainList) => {
      const FILTER_LIST = {
        // show countries with collaborators
        countries: getCompanyCountriesList(countriesList, collaboratorsMainList),
        cities: citiesList,
        states: statesList,
      };
      return FILTER_LIST[filterType];
    }, [countriesList, citiesList, statesList],
  );

  const getFilterOptions = useCallback(
    (filterType, key, isOrgUnit, collaboratorsMainList) => {
      if (isOrgUnit) {
        return getFormattedTags(buildNewOptionArray(orgUnitList), key);
      }
      return getFormattedTags(getListToUse(filterType, collaboratorsMainList), key);
    }, [getListToUse, orgUnitList],
  );

  const getAllFilters = useCallback(
    (collaboratorsMainList) => [
      {
        id: FILTERS_KEYS.collaborators,
        label: FILTERS_KEYS.collaborators,
        options: [],
        type: [],
      },
      {
        id: FILTERS_KEYS.managers,
        label: FILTERS_KEYS.managers,
        options: [], // getFilterOptions(FILTERS_KEYS.managers, OBJECT_KEYS.full_name),
        type: [],
      },
      {
        id: FILTERS_KEYS.countries,
        label: FILTERS_KEYS.country,
        options: getFilterOptions(FILTERS_KEYS.countries, OBJECT_KEYS.name, false, collaboratorsMainList),
        type: [
          FILTER_ACTIONS_TYPES.collaborators,
          FILTER_ACTIONS_TYPES.potentialCollaborators,
          FILTER_ACTIONS_TYPES.talentDrain,
          FILTER_ACTIONS_TYPES.performance,
          FILTER_ACTIONS_TYPES.orgChart,
          FILTER_ACTIONS_TYPES.timeOff,
        ],
      },
      {
        id: FILTERS_KEYS.cities,
        label: FILTERS_KEYS.city,
        options: getFilterOptions(FILTERS_KEYS.cities, OBJECT_KEYS.name, false, collaboratorsMainList),
        type: [
          FILTER_ACTIONS_TYPES.collaborators,
          FILTER_ACTIONS_TYPES.potentialCollaborators,
          FILTER_ACTIONS_TYPES.talentDrain,
          FILTER_ACTIONS_TYPES.performance,
          FILTER_ACTIONS_TYPES.orgChart,
          FILTER_ACTIONS_TYPES.timeOff,
        ],
      },
      {
        id: FILTERS_KEYS.unities,
        label: FILTERS_KEYS.unit,
        options: getFilterOptions(FILTERS_KEYS.unities, OBJECT_KEYS.name, true, collaboratorsMainList),
        type: [
          FILTER_ACTIONS_TYPES.collaborators,
          FILTER_ACTIONS_TYPES.potentialCollaborators,
          FILTER_ACTIONS_TYPES.talentDrain,
          FILTER_ACTIONS_TYPES.performance,
          FILTER_ACTIONS_TYPES.orgChart,
          FILTER_ACTIONS_TYPES.timeOff,
        ],
      },
      {
        id: FILTERS_KEYS.periods,
        label: FILTERS_KEYS.periods,
        options: [],
        type: [FILTER_ACTIONS_TYPES.attritionCollaborator],
      },
      {
        id: FILTERS_KEYS.states,
        label: FILTERS_KEYS.states,
        options: statesList.map((element) => ({ ...element, label: t(`timeOff:states.${element.id}`) })),
        type: [FILTER_ACTIONS_TYPES.timeOff],
      },
    ],
    [getFilterOptions, statesList, t],
  );

  const action = getFilterAction(type);

  const handleTags = (selectedTags, prop) => {
    setTags({
      ...tags,
      [prop]: selectedTags,
    });
    setValues({
      ...values,
      [prop]: getOptionListId(selectedTags),
    });
    if (externalHandler) {
      externalHandler(prop, { [prop]: selectedTags });
    }
    setDispatched(false);
    externalDispatched && externalDispatched.reset(false);
  };

  // this CALLBACK build the structure for all posible filters
  // handle two kind of filters: single one API call and multiple API calls
  // for multiple API calls, action constant return a object with multiple functions(API calls) and an array of indexs
  // each index represents a specific param for each API call.
  const handleAction = useCallback(
    () => {
      const queryValues = {
        countries: values.countries,
        cities: values.cities,
        unities: values.unities,
        periods: values.periods,
        states: values.states,
      };

      if (defaultValues) {
        queryValues.countries = handleUpdatedValues(defaultValues, FILTERS_KEYS.countries, values);
        queryValues.cities = handleUpdatedValues(defaultValues, FILTERS_KEYS.cities, values);
        queryValues.unities = handleUpdatedValues(defaultValues, FILTERS_KEYS.unities, values);
        queryValues.states = handleUpdatedValues(defaultValues, FILTERS_KEYS.states, values);
      }
      const query = {
        q: {
          // add here all queries
          country_id_in: queryValues.countries,
          city_id_in: queryValues.cities,
          // manager_id_in: values.managers,
          organization_unit_id_in: queryValues.unities,
          // period_id_in: queryValues.periods,
          time_offs_state_in: queryValues.states,
          state_in: queryValues.states,
        },
      };

      if (!isFunction(action)) {
        action.forEach((item) => {
          const paramsToUse = item.indexParams.map((index) => externalParams[index]);
          dispatch(item.func(...paramsToUse, query));
        });
      } else {
        return action(...externalParams, query);
      }
    },
    [action, values, externalParams, dispatch, defaultValues],
  );

  useEffect(() => {
    if (type === FILTER_ACTIONS_TYPES.potentialCollaborators) {
      const spreadLastExternalParam = [...lastExternalParamsValue];
      spreadLastExternalParam.forEach((item) => {
        if (externalParams[INDEX.zero] !== item[INDEX.zero]) {
          setDispatched(false);
        }
      });
    }

    if (!dispatched || (externalDispatched && !externalDispatched.value)) {
      if (isMultiple) {
        handleAction();
      } else {
        dispatch(handleAction());
      }
      setDispatched(true);
      externalDispatched && externalDispatched.reset(true);
    }
  }, [dispatch, dispatched, handleAction, values, externalParams, lastExternalParamsValue, type, isMultiple, externalDispatched]);

  useEffect(() => {
    if (!isEmpty(collaboratorsMainList) && !isEmpty(countriesList) && !isEmpty(orgUnitList) && isEmpty(filterToUse) && !isEmpty(statesList)) {
      const getFilterGivenType = (filterType) => getAllFilters(collaboratorsMainList).filter((filterOption) => includes(filterOption.type, filterType) && filterOption);
      setFilterToUse(getFilterGivenType(type));
    }
  }, [type, countriesList, orgUnitList, filterToUse, getAllFilters, collaboratorsMainList, statesList]);

  return (
    <Grid container data-testid={ "filter-component" }>
      <Grid item xs={ 12 } sm={ 6 } md={ 1 }>
        {isButton
          ? (
            <StyledFilterButton
              onClick={ () => setShowFilters(!showFilters) }
              variant={ VARIANT.outlined }
              typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
              showFilters={ showFilters }
            >
              <FilterIconContainer>
                <FilterIcon />
                <span>{ `${ t("common.filters") }` }</span>
              </FilterIconContainer>
            </StyledFilterButton>
          )
          : (
            isLabel && (
              <FilterIconContainer display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
                <FilterIcon />
                <span>{ `${ t("common.filters") }:` }</span>
              </FilterIconContainer>
            )

          )}

      </Grid>
      {/* NOTE: mostly inline conditions are temporal until all services for this are ready */}
      { isLoadingFilters && !isButton && [...Array(type === FILTER_ACTIONS_TYPES.attritionCollaborator ? 1 : 4).keys()].map((item) => (
        <Grid key={ `skeleton-${item}` } item xs={ 12 } sm={ 6 } md={ type === FILTER_ACTIONS_TYPES.talentDrain ? 2 : type === FILTER_ACTIONS_TYPES.attritionCollaborator ? 12 : 2 }>
          <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.ONE } />
        </Grid>
      ))}
      {showFilters && filterToUse.map((filter) => (
        <StyledGrid item xs={ 12 } sm={ 6 } md={ type === FILTER_ACTIONS_TYPES.attritionCollaborator ? 12 : 2 } key={ filter.id + filter.label }>
          <InputTag
            id={ filter.id }
            limitTags={ limitTag }
            label={ t(`common.${filter.label}`) }
            size={ SIZE.small }
            customStyle={ classes.filter }
            name={ filter.id }
            register={ register }
            onChange={ (selectedTags) => handleTags(selectedTags, filter.id) }
            data={ filter.options }
            hasCheckbox
            isDisabled={ isLoadingFilters || type === FILTER_ACTIONS_TYPES.attritionCollaborator } // note: type condition is temporal until service implementation will ready
            groupBy={ {
              filterBy: filter.id,
              compareWith: FILTERS_KEYS.cities,
            } }
            defaultValues={ defaultValues ? defaultValues[filter.id] : tags[filter.id] }
          />
        </StyledGrid>
      ))}
    </Grid>
  );
};

Filter.propTypes = {
  type: PropTypes.string.isRequired,
  externalParams: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  isButton: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isLabel: PropTypes.bool,
  externalHandler: PropTypes.func,
  defaultValues: PropTypes.object,
  dispatched: PropTypes.bool,
  limitTag: PropTypes.number,
};

Filter.defaultProps = {
  externalParams: "",
  isButton: false,
  isMultiple: false,
  isLabel: false,
  externalHandler: null,
  defaultValues: null,
  dispatched: false,
  limitTag: 10,
};

export default Filter;
