import React from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { STATES, STATE_CHANGE } from "common/constants/timeOff";
import * as S from "../../styles";

const StateManagingButtons = (props) => {
  const {
    state, isAdmin, id, handleModal, isMobile, flexEnd,
  } = props;
  switch (state) {
  case STATES.pending:
    return (
      <S.IconDiv isMobile={ isMobile } flexEnd={ flexEnd }>
        <IconButton onClick={ () => handleModal(STATE_CHANGE.reject, id) }><S.RedCancelIcon isMobile={ isMobile } /></IconButton>
        <IconButton onClick={ () => handleModal(STATE_CHANGE.approve, id) }><S.GreenCheckCircleIcon isMobile={ isMobile } /></IconButton>
      </S.IconDiv>
    );
  case STATES.approved_by_leader:
    return isAdmin ? (
      <S.IconDiv isMobile={ isMobile } flexEnd={ flexEnd }>
        <IconButton onClick={ () => handleModal(STATE_CHANGE.reject, id) }><S.RedCancelIcon isMobile={ isMobile } /></IconButton>
        <IconButton onClick={ () => handleModal(STATE_CHANGE.approve, id) }><S.GreenCheckCircleIcon isMobile={ isMobile } /></IconButton>
      </S.IconDiv>
    ) : "";
  case STATES.approved:
    return isMobile ? "" : <IconButton onClick={ () => handleModal(STATE_CHANGE.delete, id) } ><S.RedTrashAvatar><DeleteIcon /></S.RedTrashAvatar></IconButton>;

  case STATES.rejected:
  default:
    return "";
  }
};

export default StateManagingButtons;
