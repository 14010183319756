import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { VARIANT, BUTTON_STYLE_TYPES, SIZE } from "common/constants";
import TimeOffForm from "./components/TimeOffForm";
import { CustomModal, CustomButton } from "./styles";

const PopUpForm = (props) => {
  const [isActive, setIsActive] = useState(false);
  const close = () => setIsActive(false);
  const { t } = useTranslation(["common"]);
  const { isMobile } = props;

  return (
    <div>
      <CustomButton
        variant={ VARIANT.contained }
        typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
        size={ SIZE.medium }
        onClick={ () => setIsActive(true) }
        isFullWidth
        isMobile={ isMobile }
      >
        {t("common.request_time_off")}
      </CustomButton>

      <CustomModal
        isOpen={ isActive }
        onClose={ () => close() }
        title={ t("common.request_time_off") }
      >
        <TimeOffForm
          isMobile={ isMobile }
          onClose={ () => close() }
          onChangeFile={ () => { } }
          selectOptions={ [
            {
              value: "permission",
              label: t("common.type_of_permit.permit"),
            },
            {
              value: "vacations",
              label: t("common.type_of_permit.vacations"),
            },
            {
              value: "medical_license",
              label: t("common.type_of_permit.medical"),
            },
          ] }
        />
      </CustomModal>
    </div>
  );
};

PopUpForm.propTypes = {
  isMobile: PropTypes.bool,
};

export default PopUpForm;
