import {
  GET_MOVEMENTS_HISTORY_START,
  GET_MOVEMENTS_HISTORY_SUCESS,
  GET_MOVEMENT_HISTORY_SUCESS,
  GET_MOVEMENTS_HISTORY_FAILURE,
} from "../actions/types";

let initialState = {
  movementsHistory: [],
  movementHistory: [],
  isLoading: false,
  error: null,
};

const movementsData = (state = initialState, action) => {
  switch (action.type) {
    case GET_MOVEMENTS_HISTORY_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MOVEMENTS_HISTORY_SUCESS:
      return {
        ...state,
        movementsHistory: action.payload,
        isLoading: false,
      };
    case GET_MOVEMENT_HISTORY_SUCESS:
      return {
        ...state,
        movementHistory: action.payload,
        isLoading: false,
      };
    case GET_MOVEMENTS_HISTORY_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default movementsData;
