import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { LOCAL_STORAGE_NAMES } from "common/constants";

import allActions from "actions";
import { dispatchIfNotLocalStorage, setInLocalStorage } from "common/utils";
import useSignInService from "hooks/auth/useSignInService";
import auth from "./api";

const SessionContext = createContext(null);
// FIXME: this document has the OLD architecture
const SessionProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [user, setUser] = useState(
    localStorage.user ? JSON.parse(localStorage.user) : null,
  );
  const { userResult, logout } = useSignInService();

  const typeOfContractReducer = useSelector(
    (state) => state.typeOfContractReducer,
  );
  const organizationUnitsReducer = useSelector(
    (state) => state.organizationUnitsReducer,
  );
  const organizationUnitsTypesReducer = useSelector(
    (state) => state.organizationUnitsTypesReducer,
  );
  const userNamesReducer = useSelector((state) => state.userNamesReducer);
  const citiesV1Reducer = useSelector((state) => state.citiesV1Reducer);
  const countriesV1Reducer = useSelector((state) => state.countriesV1Reducer);
  const currencyReducer = useSelector((state) => state.currencyReducer);
  const employmentRelationshipReducer = useSelector((state) => state.employmentRelationshipReducer);
  const positionsReducer = useSelector((state) => state.positionsReducer);

  const [cookies] = useCookies(["lenguage"]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user && user.company) {
      const COMPANY_ID = user.company.id;
      dispatch(allActions.roleActions.getRoles(COMPANY_ID));
      dispatchIfNotLocalStorage(
        LOCAL_STORAGE_NAMES.organizationUnits,
        allActions.organizationUnitsActions.getOrganizationUnits(COMPANY_ID),
        dispatch,
      );

      dispatchIfNotLocalStorage(
        LOCAL_STORAGE_NAMES.cities,
        allActions.countryActions.getCities(COMPANY_ID),
        dispatch,
      );
      dispatchIfNotLocalStorage(
        LOCAL_STORAGE_NAMES.countries,
        allActions.countryActions.getCountries(COMPANY_ID),
        dispatch,
      );

      dispatchIfNotLocalStorage(
        LOCAL_STORAGE_NAMES.organizationUnitsTypes,
        allActions.organizationUnitsActions.getOrganizationUnitsTypes(
          COMPANY_ID,
        ),
        dispatch,
      );
    }
  }, [dispatch, user]);

  useEffect(() => {
    setInLocalStorage(LOCAL_STORAGE_NAMES.cities, citiesV1Reducer.cities);
    setInLocalStorage(
      LOCAL_STORAGE_NAMES.countries,
      countriesV1Reducer.countries,
    );
    setInLocalStorage(
      LOCAL_STORAGE_NAMES.organizationUnitsTypes,
      organizationUnitsTypesReducer.organizationUnitsTypes,
    );
    setInLocalStorage(
      LOCAL_STORAGE_NAMES.organizationUnits,
      organizationUnitsReducer.organizationUnits,
    );
  }, [
    typeOfContractReducer.typeOfContract,
    userNamesReducer.userNames,
    citiesV1Reducer.cities,
    countriesV1Reducer.countries,
    organizationUnitsTypesReducer.organizationUnitsTypes,
    organizationUnitsReducer.organizationUnits,
    currencyReducer.currencies,
    employmentRelationshipReducer.employmentRelationship,
    positionsReducer.positions,
  ]);

  useEffect(() => {
    if (cookies.lenguage) {
      i18n.changeLanguage(cookies.lenguage);
    }
    if(!userResult && !localStorage.user){
      return;
    }

    let data = null;
    if (localStorage.user) {
      data = JSON.parse(localStorage.user);
      if (data?.user) {
        data = null;
        localStorage.clear();
      }
      setUser(data ? { ...data } : null);
    }

    if (userResult?.user?.roles.length > 0) {
      localStorage.setItem(LOCAL_STORAGE_NAMES.user, JSON.stringify(userResult.user));
      setUser({ ...userResult.user });
    }
  }, [i18n, cookies, userResult]);

  const state = { user };
  const actions = {
    signOut: () => {
      auth.signOut();
      localStorage.clear();
      setUser(null);
      logout();
    },
  };

  return (
    <SessionContext.Provider value={ { state, actions } }>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionProvider, SessionContext };
