import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_STATE_ONE,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
} from "../../actionTypes/performance/surveyResults";

const INITIAL_STATE = {
  list: null,
  loadinglist: false,
  errorlist: "",
  oneResult: null,
  loadingOneResult: null,
  errorOneResult: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      loadingList: false,
      errorList: null,
      list: null,
    };
  case GET_LIST:
    return {
      ...state,
      list: action.payload,
      loadingList: false,
      errorList: "",
    };
  case GET_LIST_LOADING:
    return {
      ...state,
      list: null,
      loadingList: true,
      errorList: "",
    };
  case GET_LIST_ERROR:
    return {
      ...state,
      loadingList: false,
      errorList: action.payload,
    };
  case RESET_STATE_ONE:
    return {
      ...state,
      loadingOneResult: false,
      errorOneResult: null,
      oneResult: null,
    };
  case GET_ONE:
    return {
      ...state,
      oneResult: action.payload,
      loadingOneResult: false,
      errorOneResult: "",
    };
  case GET_ONE_LOADING:
    return {
      ...state,
      oneResult: null,
      loadingOneResult: true,
      errorOneResult: "",
    };
  case GET_ONE_ERROR:
    return {
      ...state,
      loadingOneResult: false,
      errorOneResult: action.payload,
    };
  default:
    return state;
  }
};
