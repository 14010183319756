const companyId = "?company_id=";

export const DOCUMENT_TYPES = {
  root: "/document_types",
  companyId,
};

export const INFO_TYPES = {
  root: "/info_types",
  companyId,
};
