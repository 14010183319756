import {
  LOAD_GOOD_LEADER_PROCESS_START,
  LOAD_GOOD_LEADER_PROCESS_SUCCESS,
  LOAD_GOOD_LEADER_PROCESS_FAILURE,
  GET_GOOD_LEADER_TOP_RANKING_SUCCESS,
  GET_GOOD_LEADER_BOTTOM_RANKING_SUCCESS,
  LOAD_GOOD_LEADER_RESULTS_SUCCESS,
} from "actions/types";

let initialState = {
  goodLeaderProcess: null,
  top_ranking: [],
  bottom_ranking: [],
  surveyResults: [],
  isLoading: false,
  error: null,
};

const goodLeaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GOOD_LEADER_PROCESS_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_GOOD_LEADER_PROCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        goodLeaderProcess: action.payload,
      };
    case GET_GOOD_LEADER_TOP_RANKING_SUCCESS:
      return {
        ...state,
        top_ranking: action.payload,
        isLoading: false,
      };
    case GET_GOOD_LEADER_BOTTOM_RANKING_SUCCESS:
      return {
        ...state,
        bottom_ranking: action.payload,
        isLoading: false,
      };
    case LOAD_GOOD_LEADER_RESULTS_SUCCESS:
      return {
        ...state,
        surveyResults: action.payload,
        isLoading: false,
      };
    case LOAD_GOOD_LEADER_PROCESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default goodLeaderReducer;
