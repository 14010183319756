import {
  LOAD_POTENTIAL_PROCESS_START,
  LOAD_POTENTIAL_PROCESS_SUCCESS,
  LOAD_POTENTIAL_PROCESS_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";
import { POTENTIAL_PROCESSES } from "common/paths/potential";

const getPotentialProcess = (id) => (dispatch, _) => {
  dispatch({ type: LOAD_POTENTIAL_PROCESS_START });
  const client = getLoginClient();
  const POTENTIAL_PROCESSES_URL =
    POTENTIAL_PROCESSES.root + POTENTIAL_PROCESSES.companyId + id;
  client
    .get(POTENTIAL_PROCESSES_URL)
    .then((response) => {
      dispatch({
        type: LOAD_POTENTIAL_PROCESS_SUCCESS,
        payload: response.data.potential_processes,
      });
    })
    .catch(function(error) {
      dispatch({ type: LOAD_POTENTIAL_PROCESS_FAILURE, payload: error });
    });
};

export default { getPotentialProcess };
