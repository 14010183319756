import {
  LOAD_INFO_TYPES_START,
  LOAD_INFO_TYPES_SUCCESS,
  LOAD_INFO_TYPES_FAILURE,
} from "../actions/types";

let initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const infoTypesData = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INFO_TYPES_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_INFO_TYPES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case LOAD_INFO_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default infoTypesData;
