import axios from "axios";
import { API_URL_RAILS_V1 } from "common/constants";
import { BENEFITS } from "common/paths/benefits";
import {
  LOAD_BENEFITS_START,
  GET_BENEFITS_SUCCESS,
  LOAD_BENEFITS_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";

const getCompanyBenefits = (companyId) => async (dispatch, getState) => {
  dispatch({ type: LOAD_BENEFITS_START });
  const client = getLoginClient();
  const BENEFITS_URL = `/${BENEFITS.root}?${BENEFITS.companyId}=${companyId}`;
  client
    // TODO: URL is not the same that BASE_URL
    .get(BENEFITS_URL)
    .then((response) => {
      dispatch({
        type: GET_BENEFITS_SUCCESS,
        payload: response.data.benefits,
      });
    })
    .catch(function(error) {
      dispatch({ type: LOAD_BENEFITS_FAILURE, payload: error });
    });
};

// //no need a reducer on this case
const getAsyncCollaboratorBenefits = async (collaboratorId, companyId) => {
  const COLLABORATOR_BENEFITS_URL = `${API_URL_RAILS_V1}/${BENEFITS.collaborators}/${collaboratorId}/${BENEFITS.payrollItems}?${BENEFITS.companyId}=${companyId}`;
  const response = axios.get(COLLABORATOR_BENEFITS_URL).catch(function(error) {
    if (error.response) {
      return {
        error: error.response.status,
        message: error.response.data.detail,
      };
    }
  });
  return response;
};

//no need a reducer on this case
const createBenefit = async (data, companyId) => {
  try {
    const BENEFITS_URL = `${API_URL_RAILS_V1}/${BENEFITS.root}?${BENEFITS.companyId}=${companyId}`;
    const resp = await axios.post(BENEFITS_URL, data);
    return await resp.data.benefit;
  } catch (err) {
    return err;
  }
};

export default {
  getCompanyBenefits,
  getAsyncCollaboratorBenefits,
  createBenefit,
};
