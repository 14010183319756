import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  submitStyle: {
    background: theme.palette.text.link,
    color: theme.palette.white,
    "&:hover": {
      background: theme.palette.text.linkHover,
    },
    "&:disabled": {
      background: theme.palette.background.lightGrey,
    },
  },
  cancelStyle: {
    background: theme.palette.background.lightGrey,
  },
  backStyle: {
    backgroundColor: theme.palette.background.lightGrey,
    color: theme.palette.background.darkGrey,
  },
  rejectStyle: {
    backgroundColor: theme.palette.background.darkGrey,
    color: theme.palette.background.darkGrey,
  },
  outlinedStyle: {
    borderColor: theme.palette.text.link,
    color: theme.palette.text.link,
    textTransform: "none",
    "& input": {
      display: "none",
    },
  },
  disabledStyle: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
  generalStyles: {
    textTransform: "none",
  },
  butonIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  iconLeft: {
    marginRight: "10px",
    marginTop: "5px",
  },
  iconRight: {
    marginLeft: "10px",
    marginTop: "5px",
  },
}));

export default useStyles;
