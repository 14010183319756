import makeStyles from "@material-ui/core/styles/makeStyles";
import { TABLE } from "theme/palette";

export const useStyles = makeStyles((theme) => ({
  defaultIcon: {
    color: "#75768F",
  },
  listIcon: {
    minWidth: "30px",
  },
  menuItemContainer: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    flex: 1,
    border: `1px solid${ TABLE.DIVIDER}`,
  },
}));
