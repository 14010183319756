import {
  GET_GOAL_RULES_URL, GET_GOALS_EMPLOYEE_URL, GET_UPDATE_DELETE_GOAL_URL, GET_ARCHIVE_UNARCHIVE_GOAL,
} from "common/paths/goals";
import {
  LOAD_GOALS_START,
  LOAD_GOALS_SUCCESS,
  LOAD_GOALS_FAILURE,
  GET_GOAL_RULES_SUCCESS,
  LOAD_GOAL_SUCCESS,
  LOAD_ARCHIVED_GOALS_SUCCESS,
  LOAD_ARCHIVED_UNARCHIVED_SUCCESS,
} from "./types";
import getLoginClient from "./loggedInClient";

const getGoalRules = (companyId) => async (dispatch, getState) => {
  dispatch({ type: LOAD_GOALS_START });
  const client = getLoginClient();
  client
    .get(GET_GOAL_RULES_URL(companyId))
    .then((response) => {
      dispatch({
        type: GET_GOAL_RULES_SUCCESS,
        payload: response.data.goal_rules,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_GOALS_FAILURE, payload: error });
    });
};

const getGoals = (companyId, employeeId, isArchived = false) => async (dispatch, getState) => {
  dispatch({ type: LOAD_GOALS_START });
  const client = getLoginClient();
  const GOAL_TYPE = isArchived ? LOAD_ARCHIVED_GOALS_SUCCESS : LOAD_GOALS_SUCCESS;
  client
    .get(GET_GOALS_EMPLOYEE_URL(companyId, employeeId, isArchived))
    .then((response) => {
      dispatch({
        type: GOAL_TYPE,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_GOALS_FAILURE, payload: error });
    });
};

const createGoal = (companyId, employeeId, data) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: LOAD_GOALS_START });
  const client = getLoginClient();
  client
    .post(GET_GOALS_EMPLOYEE_URL(companyId, employeeId), data)
    .then((response) => {
      dispatch({
        type: LOAD_GOAL_SUCCESS,
        payload: response.data.goal,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_GOALS_FAILURE, payload: error });
    });
};

const updateGoal = (companyId, employeeId, data, goalId) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: LOAD_GOALS_START });
  const client = getLoginClient();
  client
    .put(GET_UPDATE_DELETE_GOAL_URL(companyId, employeeId, goalId), data)
    .then((response) => {
      dispatch({
        type: LOAD_GOAL_SUCCESS,
        payload: response.data.goal,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_GOALS_FAILURE, payload: error });
    });
};

const deleteGoal = (companyId, employeeId, goalId) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: LOAD_GOALS_START });
  const client = getLoginClient();
  client
    .delete(GET_UPDATE_DELETE_GOAL_URL(companyId, employeeId, goalId))
    .then((response) => {
      dispatch({
        type: LOAD_GOAL_SUCCESS,
        payload: response.data.goal,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_GOALS_FAILURE, payload: error });
    });
};

const archiveOrUnarchiveGoal = (companyId, employeeId, data, goalId, isArchived = false) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: LOAD_GOALS_START });
  const client = getLoginClient();
  client
    .post(GET_ARCHIVE_UNARCHIVE_GOAL(companyId, employeeId, goalId, isArchived), data)
    .then((response) => {
      dispatch({
        type: LOAD_ARCHIVED_UNARCHIVED_SUCCESS,
        payload: response.data.goal,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_GOALS_FAILURE, payload: error });
    });
};

export default {
  getGoalRules,
  getGoals,
  createGoal,
  updateGoal,
  deleteGoal,
  archiveOrUnarchiveGoal,
};
