import { getListSearch as getCollaboratorList, getOrchartList as getOrgchartCollaboratorList } from "redux/actions/collaboratorActions";
import { getSurveyResults } from "redux/actions/surveyProcessesActions";
import { getTopList, getBottomList } from "redux/actions/performance/rankingActions";
import { getGoodLeaderTopRanking, getGoodLeaderResults } from "redux/actions/performance/goodLeaderActions";
import { getList as getHeatMapList } from "redux/actions/performance/heatMapActions";
import { getList as getAttritionCollaboratorChart } from "redux/actions/collaborators/attritionActions";
import { getList as getAttritionChart } from "redux/actions/attritionActions";
// import { getList as getTimeOffListCollaborators, getTimeOffList, getExcelList } from "redux/actions/timeOffActions";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import { INDEX } from "common/constants";

export const getFilterAction = (type) => {
  let action;
  switch (type) {
  case FILTER_ACTIONS_TYPES.collaborators:
    action = getCollaboratorList;
    break;
  case FILTER_ACTIONS_TYPES.potentialCollaborators:
    action = getSurveyResults;
    break;
  case FILTER_ACTIONS_TYPES.performance:
    action = [
      { func: getTopList, indexParams: [INDEX.zero] },
      { func: getBottomList, indexParams: [INDEX.zero] },
      { func: getHeatMapList, indexParams: [INDEX.zero, INDEX.one] },
      { func: getSurveyResults, indexParams: [INDEX.zero, INDEX.three] },
      { func: getGoodLeaderTopRanking, indexParams: [INDEX.two] },
      { func: getGoodLeaderResults, indexParams: [INDEX.two] },
    ];
    break;
  case FILTER_ACTIONS_TYPES.attritionCollaborator:
    action = getAttritionCollaboratorChart;
    break;
  case FILTER_ACTIONS_TYPES.talentDrain:
    action = [
      { func: getSurveyResults, indexParams: [INDEX.zero, INDEX.one] },
      { func: getAttritionChart, indexParams: [] },
      { func: getCollaboratorList, indexParams: [INDEX.one] },
    ];
    break;
  case FILTER_ACTIONS_TYPES.orgChart:
    action = getOrgchartCollaboratorList;
    break;
  case FILTER_ACTIONS_TYPES.timeOff:
    action = [
      /*
      filter actions whouls go here but in order for
      the TimeOff View to work correctly it was managed in the view itself  */
    ];
    break;
  default:
    break;
  }
  return action;
};

export const handleUpdatedValues = (defaultValues, filterType, values) => {
  return defaultValues[filterType].map((item) =>
    values[filterType].find((element) => element === item.id));
};
