import {
  LOAD_USERNAME_START,
  LOAD_USERNAME_SUCCESS,
  LOAD_USERNAME_FAILURE,
} from "../actions/types";

let initialState = {
  userNames: null,
  isLoading: false,
  error: null,
};

const userNamesData = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USERNAME_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_USERNAME_SUCCESS:
      return {
        ...state,
        userNames: action.payload,
        isLoading: false,
      };
    case LOAD_USERNAME_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default userNamesData;
