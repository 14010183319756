export const RESET_STATE = "RESET_STATE";
export const GET_LIST = "GET_LIST";
export const GET_LIST_LOADING = "GET_LIST_LOADING";
export const GET_LIST_ERROR = "GET_LIST_ERROR";

export const RESET_STATE_EXCEL = "RESET_STATE_TIME_OFF_EXCEL";
export const GET_LIST_EXCEL = "GET_LIST_TIME_OFF_EXCEL";
export const GET_LIST_EXCEL_LOADING = "GET_LIST_EXCEL_TIME_OFF_LOADING";
export const GET_LIST_EXCEL_ERROR = "GET_LIST_EXCEL_TIME_OFF_ERROR";

export const PROCESS_TIME_OFF = "PROCESS_TIME_OFF";
export const PROCESS_TIME_OFF_LOADING = "PROCESS_TIME_OFF_LOADING";
export const PROCESS_TIME_OFF_ERROR = "PROCESS_TIME_OFF_ERROR";

export const RESET_TIME_OFF = "RESET_TIME_OFF";
export const GET_LIST_TIME_OFF = "GET_LIST_TIME_OFF";
export const GET_LIST_TIME_OFF_LOADING = "GET_LIST_TIME_OFF_LOADING";
export const GET_LIST_TIME_OFF_ERROR = "GET_LIST_TIME_OFF_ERROR";
