import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { default as FormHelper } from "@material-ui/core/FormHelperText";
import { useStyles } from "./styles";

const FormHelperText = (props) => {
  const { isError, children } = props;
  const { t } = useTranslation("common");
  const classes = useStyles();
  return (
    <FormHelper data-testid={ "form-helper-text-component" } className={ classes.requiredText }>
      {isError ? <span className={ isError ? classes.errorText : "" } >{children}</span> : children || t("common.fieldRequired")}
    </FormHelper>
  );
};

FormHelperText.propTypes = {
  children: PropTypes.string.isRequired,
  isError: PropTypes.bool,
};

FormHelperText.defaultProps = {
  isError: false,
};

export default FormHelperText;
