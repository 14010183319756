import {
  LOAD_FEEDBACKS_FAILURE,
  LOAD_FEEDBACKS_SENT_FAILURE,
  LOAD_FEEDBACKS_SENT_START,
  LOAD_FEEDBACKS_SENT_SUCCESS,
  LOAD_FEEDBACKS_START,
  LOAD_FEEDBACKS_SUCCESS,
  SEND_FEEDBACK_FAILURE,
  SEND_FEEDBACK_START,
  SEND_FEEDBACK_SUCCESS,
  RESET_MODAL,
} from "../actions/types";

const initialState = {
  feedbacksReceived: [],
  feedbacksSent: [],
  isLoadingSent: false,
  isLoadingReceived: false,
  isLoadingSendFeedback: false,
  sendFeedbackData: null,
  isSendFeedbackWithErrors: false,
  error: null,
};

const feedbacksData = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FEEDBACKS_START:
      return {
        ...state,
        isLoadingReceived: true,
      };
    case LOAD_FEEDBACKS_SUCCESS:
      return {
        ...state,
        feedbacksReceived: action.payload,
        isLoadingReceived: false,
      };
    case LOAD_FEEDBACKS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoadingReceived: false,
      };
    case LOAD_FEEDBACKS_SENT_START:
      return {
        ...state,
        isLoadingSent: true,
      };
    case LOAD_FEEDBACKS_SENT_SUCCESS:
      return {
        ...state,
        feedbacksSent: action.payload,
        isLoadingSent: false,
      };
    case LOAD_FEEDBACKS_SENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoadingSent: false,
      };
    case SEND_FEEDBACK_START:
      return {
        ...state,
        isLoadingSendFeedback: true,
      };
    case SEND_FEEDBACK_SUCCESS:
      return {
        ...state,
        sendFeedbackData: action.payload,
        isLoadingSendFeedback: false,
      };
    case SEND_FEEDBACK_FAILURE:
      return {
        ...state,
        isSendFeedbackWithErrors: true,
        isLoadingSendFeedback: false,
      };
    case RESET_MODAL:
      return {
        ...state,
        isLoadingSendFeedback: false,
        sendFeedbackData: null,
        isSendFeedbackWithErrors: false,
      };
    default:
      return state;
  }
};

export default feedbacksData;
