import {
  LOAD_PERSONALITY_START,
  LOAD_PERSONALITY_SUCCESS,
  LOAD_PERSONALITY_FAILURE,
} from "actions/types";

let initialState = {
  personality: [],
  isLoading: false,
  error: null,
};

const personalitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PERSONALITY_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_PERSONALITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        personality: action.payload,
      };
    case LOAD_PERSONALITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default personalitiesReducer;
