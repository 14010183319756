import makeStyles from "@material-ui/core/styles/makeStyles";
import { TOP_BAR } from "theme/palette";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 2px 6px #0000000A",
    backgroundColor: TOP_BAR.background,
    height: 60,
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  button: {
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 10,
  },
  icon: {
    color: TOP_BAR.lightBlue,
    "&:hover": {
      color: theme.palette.white,
    },
    "&:active": {
      color: theme.palette.white,
    },
  },
  select: {
    minWidth: 80,
    lineHeight: "inherit !important",
    color: TOP_BAR.lightBlue,
    "&:before": {
      borderColor: "inherit!important",
      borderBottom: "inherit!important",
    },
    "&:after": {
      borderColor: "inherit!important",
      borderBottom: "inherit!important",
    },
    "&:hover": {
      color: theme.palette.white,
    },
  },
  badgeNotification: {
    backgroundColor: "#19E2D6",
    color: "#19E2D6",
    top: 5,
    right: 7,
  },
  btnProfile: {
    textTransform: "inherit",
    padding: 5,
    fontSize: 17,
    color: theme.palette.white,
  },
  containerMenuItem: {
    padding: "10px 20px",
  },
  spanMenuItemProfile: {
    fontWeight: "bold",
    paddingRight: 5,
  },
  MenuItemProfile: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  dividerCustomcss: {
    marginTop: 10,
    marginBottom: 10,
    color: TOP_BAR.lightBlue,
  },
  MenuItemName: {
    color: TOP_BAR.background,
    fontWeight: 500,
    fontSize: 14,
  },
  MenuItemPosition: {
    fontSize: 14,
    color: "#021947",
  },
  MenuItemCPF: {
    fontSize: 13,
  },
  menuItem: {
    padding: 0,
    paddingBottom: "3px",
  },
  menuIcon: {
    paddingRight: "5px",
  },
  menu: {
    top: 40,
  },
  avatar: {
    marginLeft: 15,
  },
  whiteMenuIcon: {
    color: theme.palette.white,
  },
}));

export default useStyles;
