import qs from "qs";
import isEmpty from "lodash/isEmpty";
import {
  LOCAL_STORAGE_NAMES,
  OBJECT_KEYS,
  SURVEY_PROCESS_TYPE,
  PARAMS_SERIALIZER_OPTIONS,
  METHODS,
} from "common/constants";
import { getItemFromLocalStorage, getUserId } from "common/utils";
import configAxios from "../configAxios";
import errorHandler from "../errorHandler";
import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_STATE_ONE,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
  RESET_STATE_PROCESS_SURVEY_PROCESSES,
  PROCESS_SURVEY_PROCESSES,
  PROCESS_SURVEY_PROCESSES_LOADING,
  PROCESS_SURVEY_PROCESSES_ERROR,
  RESET_STATE_PERFORMANCE_EVOLUTION,
  GET_LIST_PERFORMANCE_EVOLUTION_LOADING,
  GET_LIST_PERFORMANCE_EVOLUTION,
  GET_LIST_PERFORMANCE_EVOLUTION_ERROR,
  RESET_STATE_SURVEY_RESULTS,
  GET_LIST_SURVEY_RESULTS,
  GET_LIST_LOADING_SURVEY_RESULTS,
  GET_LIST_ERROR_SURVEY_RESULTS,
} from "../actionTypes/surveyProcesses";

const URL = {
  main: "/survey_processes",
  evolution: "evolution",
  surveyResults: "survey_results",
  userIdParam: "user_id=",
  sendProcess: "send_process",
  sendProcessReminder: "send_process_reminder",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const resetStateProcess = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_PROCESS_SURVEY_PROCESSES,
  });
};

export const resetStateOne = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ONE,
  });
};

export const getList = () => async (dispatch, getState) => {
  const { list } = getState().surveyProcessesReducer;

  if (!list) {
    dispatch({
      type: GET_LIST_LOADING,
    });
    try {
      const response = await configAxios.get(URL.main);
      dispatch({
        type: GET_LIST,
        payload: response.data.survey_processes,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_ERROR);
    }
  }
};

export const getOne = (processType, processId) => async (dispatch, getState) => {
  const { one } = getState().surveyProcessesReducer;
  const SURVEY_PROCESS_URL = `${processType}/${processId}`;
  if (!one) {
    dispatch({
      type: GET_ONE_LOADING,
    });
    try {
      const response = await configAxios.get(SURVEY_PROCESS_URL);
      dispatch({
        type: GET_ONE,
        payload: response.data,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_ONE_ERROR);
    }
  }
};

export const createOrUpdate = (
  data,
  processType,
  processId,
) => async (dispatch, getState) => {
  const { successProcess } = getState().surveyProcessesReducer;
  const SURVEY_PROCESS_URL = `${processType}/${processId || ""}`;
  const configAxiosMethod = configAxios[processId ? METHODS.put : METHODS.post];

  if (!successProcess) {
    dispatch({
      type: PROCESS_SURVEY_PROCESSES_LOADING,
    });

    try {
      const response = await configAxiosMethod(SURVEY_PROCESS_URL, data);
      dispatch({
        type: PROCESS_SURVEY_PROCESSES,
        payload: response.data,
      });
    } catch (error) {
      errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
    }
  }
};

export const deleteItem = (
  processType,
  processId,
) => async (dispatch, getState) => {
  const { successProcess } = getState().surveyProcessesReducer;
  const SURVEY_PROCESS_URL = `${processType}/${processId}`;

  if (!successProcess) {
    dispatch({
      type: PROCESS_SURVEY_PROCESSES_LOADING,
    });

    try {
      const response = await configAxios.delete(SURVEY_PROCESS_URL);
      dispatch({
        type: PROCESS_SURVEY_PROCESSES,
        payload: isEmpty(response.data),
      });
    } catch (error) {
      errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
    }
  }
};

export const sendSurveyProcess = (processId, isReminder = false) => async (dispatch, getState) => {
  const { successProcess } = getState().surveyProcessesReducer;
  const SURVEY_PROCESS_URL = `${URL.main}/${processId}/${isReminder ? URL.sendProcessReminder : URL.sendProcess}`;

  if (!successProcess) {
    dispatch({
      type: PROCESS_SURVEY_PROCESSES_LOADING,
    });

    try {
      const response = await configAxios.post(SURVEY_PROCESS_URL);
      dispatch({
        type: PROCESS_SURVEY_PROCESSES,
        payload: response.data,
      });
    } catch (error) {
      errorHandler(error, dispatch, PROCESS_SURVEY_PROCESSES_ERROR);
    }
  }
};

// performance evolution

export const resetStatePerformanceEvolution = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_PERFORMANCE_EVOLUTION,
  });
};

export const getPerformanceEvolution = () => async (dispatch, getState) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const { performanceEvolution } = getState().surveyProcessesReducer;

  if (!performanceEvolution) {
    dispatch({
      type: GET_LIST_PERFORMANCE_EVOLUTION_LOADING,
    });
    try {
      const response = await configAxios.get(`${URL.main}/${URL.evolution}`, {
        params: {
          [OBJECT_KEYS.userId]: userId,
          [OBJECT_KEYS.type]: SURVEY_PROCESS_TYPE.performance.key,
        },
      });

      dispatch({
        type: GET_LIST_PERFORMANCE_EVOLUTION,
        payload: response.data.performance_processes,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_PERFORMANCE_EVOLUTION_ERROR);
    }
  }
};

export const getCollaboratorPerformanceEvolution = (collaboratorId) => async (dispatch, getState) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const { performanceEvolution } = getState().surveyProcessesReducer;

  const query = {
    q: {
      employee_id_eq: collaboratorId,
    },
  };

  if (!performanceEvolution) {
    dispatch({
      type: GET_LIST_PERFORMANCE_EVOLUTION_LOADING,
    });
    try {
      const response = await configAxios.get(`${URL.main}/${URL.evolution}`, {
        params: {
          ...query,
          [OBJECT_KEYS.userId]: userId,
          [OBJECT_KEYS.type]: SURVEY_PROCESS_TYPE.performance.key,
        },
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });
      dispatch({
        type: GET_LIST_PERFORMANCE_EVOLUTION,
        payload: response.data.performance_processes,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_PERFORMANCE_EVOLUTION_ERROR);
    }
  }
};

// survey results

export const resetStateSurveyResults = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_SURVEY_RESULTS,
  });
};

export const getSurveyResults = (processId, isEmptyFilter, query) => async (dispatch, getState) => {
  let object = null;
  let dispatchRequest = true;
  if (isEmptyFilter) {
    object = query.q;
    dispatchRequest = Object.values(object).map((item) => !isEmpty(item));
  }

  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const SURVEY_RESULTS_URL = `${URL.main}/${processId}/${URL.surveyResults}`;
  dispatch({
    type: GET_LIST_LOADING_SURVEY_RESULTS,
  });
  try {
    let responseList = [];
    if (!isEmptyFilter || (isEmptyFilter && dispatchRequest.find((item) => item === true))) {
      const response = await configAxios.get(SURVEY_RESULTS_URL, {
        params: { ...query, [OBJECT_KEYS.userId]: userId },
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });

      responseList = response.data.survey_results;
    }

    dispatch({
      type: GET_LIST_SURVEY_RESULTS,
      payload: responseList,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR_SURVEY_RESULTS);
  }
};
