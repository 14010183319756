import { LANGUAGES } from "common/constants";
import { GET_PERSONALITY_TEST_URL } from "common/paths/personality";
import {
  LOAD_PERSONALITY_START,
  LOAD_PERSONALITY_SUCCESS,
  LOAD_PERSONALITY_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";

const getPersonalityTest = (companyId, id, locale = LANGUAGES.en) => async (
  dispatch,
) => {
  dispatch({ type: LOAD_PERSONALITY_START });
  const client = getLoginClient();
  client
    .get(GET_PERSONALITY_TEST_URL(companyId, id, locale))
    .then((response) => {
      dispatch({
        type: LOAD_PERSONALITY_SUCCESS,
        payload: response.data.personalities,
      });
    })
    .catch((error) => {
      dispatch({
        type: LOAD_PERSONALITY_FAILURE,
        payload: error.status,
      });
    });
};

export default {
  getPersonalityTest,
};
