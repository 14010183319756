import {
  LOAD_BENEFITS_START,
  GET_BENEFITS_SUCCESS,
  LOAD_BENEFITS_FAILURE,
} from "../actions/types";

let initialState = {
  benefits: [],
  isLoading: false,
  error: null,
};

const benefitsData = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BENEFITS_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_BENEFITS_SUCCESS:
      return {
        ...state,
        benefits: action.payload,
        isLoading: false,
      };
    case LOAD_BENEFITS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default benefitsData;
