import {
  LOAD_DOCUMENT_START,
  LOAD_DOCUMENT_SUCCESS,
  LOAD_DOCUMENT_FAILURE,
  LOAD_INFO_TYPES_START,
  LOAD_INFO_TYPES_SUCCESS,
  LOAD_INFO_TYPES_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";
import { DOCUMENT_TYPES, INFO_TYPES } from "common/paths/additionalData";

const getDocuments = (id) => async (dispatch, getState) => {
  dispatch({ type: LOAD_DOCUMENT_START });
  const client = getLoginClient();
  const GET_DOCUMENTS_URL = DOCUMENT_TYPES.root + DOCUMENT_TYPES.companyId + id;
  client
    .get(GET_DOCUMENTS_URL)
    .then((response) => {
      dispatch({
        type: LOAD_DOCUMENT_SUCCESS,
        payload: response.data.document_types,
      });
    })
    .catch(function(error) {
      dispatch({ type: LOAD_DOCUMENT_FAILURE, payload: error });
    });
};

const getInfoTypes = (id) => async (dispatch, getState) => {
  dispatch({ type: LOAD_INFO_TYPES_START });
  const client = getLoginClient();
  const GET_INFO_TYPES_URL = INFO_TYPES.root + INFO_TYPES.companyId + id;
  client
    .get(GET_INFO_TYPES_URL)
    .then((response) => {
      dispatch({
        type: LOAD_INFO_TYPES_SUCCESS,
        payload: response.data.info_types,
      });
    })
    .catch(function(error) {
      dispatch({ type: LOAD_INFO_TYPES_FAILURE, payload: error });
    });
};

export default {
  getDocuments,
  getInfoTypes,
};
