import {
  LOAD_MASSIVE_UPLOAD_START,
  LOAD_MASSIVE_UPLOAD_FAILURE,
  GET_MASSIVE_UPLOAD_SUCCESS,
} from "../actions/types";

let initialState = {
  uploadStatus: [],
  isLoading: false,
  error: null,
};

const massiveUploadData = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MASSIVE_UPLOAD_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MASSIVE_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadStatus: action.payload,
        isLoading: false,
      };
    case LOAD_MASSIVE_UPLOAD_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default massiveUploadData;
