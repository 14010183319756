import {
  COLLABORATORS_PATH,
  COMPANY_ID_PARAM,
  FEEDBACK_PATH,
  FEEDBACK_SIMPLES_PATH,
  FEEDBACK_SSC_PATH,
} from "../../constants";

const FEEDBACK_SENT_QUERY_PARAM = "&sent=true";

// URL example: /api/v1/collaborators/id/feedbacks/?company_id=&sent=true (sent param is optional)
export const GET_FEEDBACK_BY_COLLABORATOR_AND_TYPE_PATH = (companyId, collaboratorId, isSent) => {
  let url = `/${COLLABORATORS_PATH}/${collaboratorId}/${FEEDBACK_PATH}/${COMPANY_ID_PARAM(companyId)}`;
  url = isSent ? url.concat(FEEDBACK_SENT_QUERY_PARAM) : url;
  return url;
};

// URL example: /api/v1/feedback_simples?company_id=
export const SEND_FEEDBACK_SIMPLE_PATH = (companyId) => `/${FEEDBACK_SIMPLES_PATH}/${COMPANY_ID_PARAM(companyId)}`;
export const SEND_FEEDBACK_SSC_PATH = (companyId) => `/${FEEDBACK_SSC_PATH}/${COMPANY_ID_PARAM(companyId)}`;
