import {
  LOAD_TYPE_OF_CONTRACT_START,
  LOAD_TYPE_OF_CONTRACT_SUCCESS,
  LOAD_TYPE_OF_CONTRACT_FAILURE,
} from "actions/types";

let initialState = {
  typeOfContract: null,
  isLoading: false,
  error: null,
};

const typeOfContractReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TYPE_OF_CONTRACT_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_TYPE_OF_CONTRACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        typeOfContract: action.payload,
      };
    case LOAD_TYPE_OF_CONTRACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default typeOfContractReducer;
