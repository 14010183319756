import isEqual from "lodash/isEqual";
import { TOP_RESULTS_PATH } from "common/constants";
import {
  GET_GOOD_LEADER_PROCESSES_URL,
  GET_GOOD_LEADER_RANKING_URL,
  GET_GOOD_LEADER_SURVEY_RESULTS_URL,
} from "common/paths/goodLeader";
import {
  LOAD_GOOD_LEADER_PROCESS_START,
  LOAD_GOOD_LEADER_PROCESS_SUCCESS,
  LOAD_GOOD_LEADER_PROCESS_FAILURE,
  LOAD_GOOD_LEADER_RANKING_START,
  GET_GOOD_LEADER_TOP_RANKING_SUCCESS,
  GET_GOOD_LEADER_BOTTOM_RANKING_SUCCESS,
  LOAD_GOOD_LEADER_TOP_RANKING_FAILURE,
  LOAD_GOOD_LEADER_RESULTS_SUCCESS,
} from "./types";
import getLoginClient from "./loggedInClient";

const getGoodLeaderProcess = (companyId) => (dispatch, _) => {
  dispatch({ type: LOAD_GOOD_LEADER_PROCESS_START });
  const client = getLoginClient();
  client
    .get(GET_GOOD_LEADER_PROCESSES_URL(companyId))
    .then((response) => {
      dispatch({
        type: LOAD_GOOD_LEADER_PROCESS_SUCCESS,
        payload: response.data.good_leader_processes,
      });
    })
    .catch(function(error) {
      dispatch({ type: LOAD_GOOD_LEADER_PROCESS_FAILURE, payload: error });
    });
};

const getRanking = (id, companyId, type, userId) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_GOOD_LEADER_RANKING_START });
  const client = getLoginClient();
  client
    .get(GET_GOOD_LEADER_RANKING_URL(id, companyId, type, userId))
    .then((response) => {
      dispatch({
        type: isEqual(type, TOP_RESULTS_PATH)
          ? GET_GOOD_LEADER_TOP_RANKING_SUCCESS
          : GET_GOOD_LEADER_BOTTOM_RANKING_SUCCESS,
        payload: response.data.survey_results,
      });
    })
    .catch(function(error) {
      dispatch({
        type: LOAD_GOOD_LEADER_TOP_RANKING_FAILURE,
        payload: error.status,
      });
    });
};

const getGoodLeaderResults = (id, companyId, userId) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_GOOD_LEADER_PROCESS_START });
  const client = getLoginClient();
  client
    .get(GET_GOOD_LEADER_SURVEY_RESULTS_URL(id, companyId, userId))
    .then((response) => {
      dispatch({
        type: LOAD_GOOD_LEADER_RESULTS_SUCCESS,
        payload: response.data.survey_results,
      });
    })
    .catch(function(error) {
      dispatch({
        type: LOAD_GOOD_LEADER_PROCESS_FAILURE,
        payload: error.status,
      });
    });
};

export default { getGoodLeaderProcess, getRanking, getGoodLeaderResults };
