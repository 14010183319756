import {
  LOAD_ORGUNIT_START,
  LOAD_ORGUNIT_SUCCESS,
  LOAD_ORGUNIT_FAILURE,
  LOAD_ORGUNIT_TREE_SUCCESS,
} from "../actions/types";

let initialState = {
  organizationUnits: null,
  organizationUnitsTree: null,
  isLoading: false,
  isLoadingTree: false,
  error: null,
};

const organizationUnitsData = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ORGUNIT_START:
      return {
        ...state,
        isLoading: true,
        isLoadingTree: true,
      };
    case LOAD_ORGUNIT_SUCCESS:
      return {
        ...state,
        organizationUnits: action.payload,
        isLoading: false,
      };

    case LOAD_ORGUNIT_TREE_SUCCESS:
      return {
        ...state,
        organizationUnitsTree: action.payload,
        isLoadingTree: false,
      };
    case LOAD_ORGUNIT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoadingTree: false,
      };
    default:
      return state;
  }
};

export default organizationUnitsData;
