import { GET_EMPLOYMENT_RELATIONSHIP_URL } from "common/paths/employmentsRelationship";
import {
  LOAD_EMPLOYMENT_RELATIONSHIP_START,
  LOAD_EMPLOYMENT_RELATIONSHIP_SUCCESS,
  LOAD_EMPLOYMENT_RELATIONSHIP_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";

const getEmploymentRelationship = (companyId) => (dispatch) => {
  dispatch({ type: LOAD_EMPLOYMENT_RELATIONSHIP_START });
  const client = getLoginClient();
  client
    .get(GET_EMPLOYMENT_RELATIONSHIP_URL(companyId))
    .then((response) => {
      dispatch({
        type: LOAD_EMPLOYMENT_RELATIONSHIP_SUCCESS,
        payload: response.data.employment_relationships,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_EMPLOYMENT_RELATIONSHIP_FAILURE, payload: error });
    });
};

export default { getEmploymentRelationship };
