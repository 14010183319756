import {
  LOAD_POSITIONS_START,
  LOAD_POSITIONS_SUCCESS,
  LOAD_POSITIONS_FAILURE,
} from "actions/types";

let initialState = {
  positions: null,
  isLoading: false,
  error: null,
};

const positionsReducer = (state = initialState, action) => {
  switch (action.type) {
  case LOAD_POSITIONS_START:
    return {
      ...state,
      isLoading: true,
    };
  case LOAD_POSITIONS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      positions: action.payload,
    };
  case LOAD_POSITIONS_FAILURE:
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  default:
    return state;
  }
};

export default positionsReducer;
