import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

import store from "./store";

// Lang
import commonEn from "./translations/en/common.json";
import commonEs from "./translations/es/common.json";
import commonPr from "./translations/pr/common.json";
import authenticationEn from "./translations/en/authentication/authentication.json";
import authenticationEs from "./translations/es/authentication/authentication.json";
import authenticationPr from "./translations/pr/authentication/authentication.json";
import formValidationEn from "./translations/en/formValidations/formValidations.json";
import formValidationEs from "./translations/es/formValidations/formValidations.json";
import formValidationPr from "./translations/pr/formValidations/formValidations.json";
import timeOffEn from "./translations/en/timeOff/timeOff.json";
import timeOffEs from "./translations/es/timeOff/timeOff.json";
import timeOffPr from "./translations/pr/timeOff/timeOff.json";

i18next
  .init({
    interpolation: { escapeValue: false },
    lng: "en",
    fallbackLng: "en",
    resources: {
      en: {
        common: commonEn,
        authentication: authenticationEn,
        formValidations: formValidationEn,
        timeOff: timeOffEn,
      },
      es: {
        common: commonEs,
        authentication: authenticationEs,
        formValidations: formValidationEs,
        timeOff: timeOffEs,
      },
      pr: {
        common: commonPr,
        authentication: authenticationPr,
        formValidations: formValidationPr,
        timeOff: timeOffPr,
      },
    },
  })
  .then(() => {});

if (!serviceWorker.isLocalhost) {
  Sentry.init({
    dsn:
      "https://8b97c24a91d2423b8ee0b5270b471b31@o526974.ingest.sentry.io/5642685",
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <I18nextProvider i18n={ i18next }>
    <CookiesProvider>
      <Provider store={ store }>
        <App />
      </Provider>
    </CookiesProvider>
  </I18nextProvider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
