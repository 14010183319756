import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "components/Button";
import {
  AUTH_METHODS,
  VARIANT,
} from 'common/constants';
import googleLogoImage from "assets/images/sign-in/google-logo.svg";
import useStyles from "./styles";

const GoogleButton = (props) => {
  const { setAuthMethod, onClick, isLoading } = props;
  const { t } = useTranslation("common");
  const classes = useStyles();

  const onClickHandler = () => {
    setAuthMethod(AUTH_METHODS.google);
    onClick();
  };

  return (
    <div data-testid={ "googleButton" }>
      <Button
        onClick={ onClickHandler }
        isDisabled={ isLoading }
        size={ "large" }
        variant={ VARIANT.outlined }
        customStyle={ classes.buttonContainer }
      >
        <Grid container direction={ "row" } justify={ "center" } alignItems={ "center" }>
          <Grid item sm={ 1 } className={ classes.gridIcon }>
            <img src={ googleLogoImage } alt={ "Google Logo" } />
          </Grid>
          <Grid item sm={ 11 } className={ classes.gridText }>
            <Typography variant={ "h6" }>{t("sign-in.sig-in")}</Typography>
          </Grid>
        </Grid>
      </Button>
    </div>
  );
};

GoogleButton.propTypes = {
  setAuthMethod: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default GoogleButton;
