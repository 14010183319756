import axios from "axios";
import { GET_ENGAGEMENT_PROCESSES_URL, GET_RESULTS_BY_DEPARTMENT_URL } from "common/paths/engagement";
import {
  LOAD_ENGAGEMENT_PROCESS_START,
  LOAD_ENGAGEMENT_PROCESS_SUCCESS,
  LOAD_ENGAGEMENT_PROCESS_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";

const getEngagementProcess = (companyId) => (dispatch) => {
  dispatch({ type: LOAD_ENGAGEMENT_PROCESS_START });
  const client = getLoginClient();
  client
    .get(GET_ENGAGEMENT_PROCESSES_URL(companyId))
    .then((response) => {
      dispatch({
        type: LOAD_ENGAGEMENT_PROCESS_SUCCESS,
        payload: response.data.engagement_processes,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_ENGAGEMENT_PROCESS_FAILURE, payload: error });
    });
};

const getResultsByDepartment = async (id, resultType, companyId) => {
  try {
    const resp = await axios.get(
      GET_RESULTS_BY_DEPARTMENT_URL(id, resultType, companyId),
    );
    return resp.data.departments;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

export default { getEngagementProcess, getResultsByDepartment };
