import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getDownloadButton } from "./functions";
import { mainDownloadExcel, mainDownloadExcelCustom } from "common/utils";
import useStyles from "./styles";

const DownloadExcelButton = (props) => {
  const { isDisabled, onClick, customStyle, downloadApart } = props;
  const classes = useStyles();
  const { t } = useTranslation("timeOff");

  const handleClick = (_) => {
    if (downloadApart) {
      onClick();
    } else {
      const [data, filename, isCustom] = onClick();
      isCustom
        ? mainDownloadExcelCustom(data, filename, t)
        : mainDownloadExcel(data, filename);
    }
  };

  return (
    <div data-testid="downloadExcelButton">
      {getDownloadButton(
        customStyle,
        classes,
        t,
        isDisabled,
        handleClick,
        props.children
      )}
    </div>
  );
};

DownloadExcelButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  customStyle: PropTypes.string,
};

export default DownloadExcelButton;
