import {
  LOAD_CANDIDATE_START,
  LOAD_CANDIDATE_SUCCESS,
  LOAD_CANDIDATE_FAILURE,
  UPDATE_CANDIDATE_SUCCESS,
  GET_CANDIDATE_SUCCESS,
  UPDATE_CANDIDATE_FAILURE,
  UPDATE_CANDIDATE_START,
  RESET_MODAL,
} from "../actions/types";

const initialState = {
  candidate: {},
  candidates: [],
  isLoading: false,
  isLoadingUpdate: false,
  hasErrorsUpdating: false,
  error: null,
  errorsUpdating: null,
};

const candidatesData = (state = initialState, action) => {
  switch (action.type) {
  case LOAD_CANDIDATE_START:
    return {
      ...state,
      isLoading: true,
    };
  case LOAD_CANDIDATE_SUCCESS:
    return {
      ...state,
      candidates: action.payload,
      isLoading: false,
    };
  case UPDATE_CANDIDATE_START:
    return {
      ...state,
      isLoadingUpdate: true,
    };
  case UPDATE_CANDIDATE_SUCCESS:
    return {
      ...state,
      candidate: action.payload,
      isLoadingUpdate: false,
    };
  case UPDATE_CANDIDATE_FAILURE:
    return {
      ...state,
      errorsUpdating: action.payload,
      isLoadingUpdate: false,
    };
  case GET_CANDIDATE_SUCCESS:
    return {
      ...state,
      candidate: action.payload,
      isLoading: false,
    };

  case LOAD_CANDIDATE_FAILURE:
    return {
      ...state,
      error: action.payload,
      isLoading: false,
    };
  case RESET_MODAL:
    return {
      ...state,
      errorsUpdating: null,
      isLoadingUpdate: false,
      hasErrorsUpdating: false,
    };
  default:
    return state;
  }
};

export default candidatesData;
