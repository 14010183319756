import React from "react";
import { useTranslation } from "react-i18next";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { ASC } from "common/constants/index";
import { CustomTableHead } from "./styles";


const EnhancedTableHead = (props) => {
  
  const { headers, direction, active, handleSort, } = props;
  const { t } = useTranslation(["common"]);

  return(
    <CustomTableHead>
      <TableRow>
        {
          headers?.map((element, _index) => {           
           return  <TableCell key={element.title}>
              <TableSortLabel
              active={active === element.title}
              direction={direction}
              onClick={ element.sortable ? () => handleSort(element.title, direction === ASC ? element.sortAsc : element.sortDesc) : undefined} 
              hideSortIcon={ !element.sortable }
              >
                { element.isVisible ? t(`common.${element.title}`) : "" }
               </TableSortLabel> 
            </TableCell>
          })
        }
      </TableRow>
    </CustomTableHead>
  )
}



export default EnhancedTableHead;
