import { CANDIDATES } from "common/paths/candidates";
import {
  LOAD_CANDIDATE_START,
  LOAD_CANDIDATE_SUCCESS,
  LOAD_CANDIDATE_FAILURE,
  UPDATE_CANDIDATE_SUCCESS,
  UPDATE_CANDIDATE_FAILURE,
  GET_CANDIDATE_SUCCESS,
  UPDATE_CANDIDATE_START,
} from "./types";
import getLoginClient from "./loggedInClient";

const getCandidates = (id) => async (dispatch, getState) => {
  dispatch({ type: LOAD_CANDIDATE_START });
  const client = getLoginClient();
  const GET_CANDIDATES_URL = CANDIDATES.root + CANDIDATES.companyId + id;

  client
    .get(GET_CANDIDATES_URL)
    .then((response) => {
      dispatch({
        type: LOAD_CANDIDATE_SUCCESS,
        payload: response.data.candidates,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_CANDIDATE_FAILURE, payload: error.status });
    });
};

const updateCandidate = (data, candidateId, companyId) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: UPDATE_CANDIDATE_START });
  const client = getLoginClient();
  const UPDATE_CANDIDATE_URL = `${CANDIDATES.root}/${candidateId}${CANDIDATES.companyId}${companyId}`;
  client
    .put(UPDATE_CANDIDATE_URL, data)
    .then((response) => {
      dispatch({
        type: UPDATE_CANDIDATE_SUCCESS,
        payload: response.data.candidate,
      });
    })
    .catch((error) => {
      dispatch({ type: UPDATE_CANDIDATE_FAILURE, payload: error?.data });
    });
};

const getCandidate = (id, companyId) => async (dispatch, getState) => {
  dispatch({ type: LOAD_CANDIDATE_START });
  const client = getLoginClient();
  const GET_CANDIDATE_URL = `${CANDIDATES.root}/${id}${CANDIDATES.companyId}${companyId}`;
  client
    .get(GET_CANDIDATE_URL)
    .then((response) => {
      dispatch({
        type: GET_CANDIDATE_SUCCESS,
        payload: response.data.candidate,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_CANDIDATE_FAILURE, payload: error.status });
    });
};

export default {
  getCandidates,
  updateCandidate,
  getCandidate,
};
