import {
  LOAD_COLLABORATOR_START,
  LOAD_COLLABORATOR_SUCCESS,
  LOAD_COLLABORATOR_FAILURE,
  GET_COLLABORATOR_SUCCESS,
  LOAD_ORGANIZATION_CHART_SUCCESS,
  LOAD_COLLABORATORS_DOWLOAD_SUCCESS,
} from "../actions/types";

let initialState = {
  collaborator: [],
  collaborators: [],
  organizationChart: [],
  collaboratorsToDownload: [],
  termination: null,
  isLoading: false,
  error: null,
};

const collaboratorsData = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COLLABORATOR_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_COLLABORATOR_SUCCESS:
      return {
        ...state,
        collaborators: action.payload,
        isLoading: false,
      };
    case GET_COLLABORATOR_SUCCESS:
      return {
        ...state,
        collaborator: action.payload,
        isLoading: false,
      };
    case LOAD_COLLABORATOR_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case LOAD_ORGANIZATION_CHART_SUCCESS:
      return {
        ...state,
        organizationChart: action.payload,
        isLoading: false,
      };
    case LOAD_COLLABORATORS_DOWLOAD_SUCCESS:
      return {
        ...state,
        collaboratorsToDownload: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default collaboratorsData;
