import {
  LOAD_NINEBOX_START,
  LOAD_NINEBOX_SUCCESS,
  LOAD_NINEBOX_FAILURE,
  GET_NINEBOX_SUCCESS,
} from "actions/types";

let initialState = {
  nineBoxes: [],
  nineBox: {},
  isLoading: false,
  error: null,
};

const nineBoxReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_NINEBOX_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_NINEBOX_SUCCESS:
      return {
        ...state,
        nineBoxes: action.payload,
        isLoading: false,
      };
    case GET_NINEBOX_SUCCESS:
      return {
        ...state,
        nineBox: action.payload,
        isLoading: false,
      };
    case LOAD_NINEBOX_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default nineBoxReducer;
