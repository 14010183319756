import {
  GET_FEEDBACK_BY_COLLABORATOR_AND_TYPE_PATH,
  SEND_FEEDBACK_SIMPLE_PATH,
  SEND_FEEDBACK_SSC_PATH,
} from "common/paths/feedback";
import {
  LOAD_FEEDBACKS_FAILURE,
  LOAD_FEEDBACKS_SENT_FAILURE,
  LOAD_FEEDBACKS_SENT_START,
  LOAD_FEEDBACKS_SENT_SUCCESS,
  LOAD_FEEDBACKS_START,
  LOAD_FEEDBACKS_SUCCESS,
  SEND_FEEDBACK_FAILURE,
  SEND_FEEDBACK_START,
  SEND_FEEDBACK_SUCCESS,
} from "./types";
import getLoginClient from "./loggedInClient";
import { FEEDBACK_TYPE } from "../common/constants";

const getFeedbacksByEmployeeIdAndType = (companyId, collaboratorId, isSent) => (dispatch) => {
  dispatch({ type: isSent ? LOAD_FEEDBACKS_SENT_START : LOAD_FEEDBACKS_START });
  const client = getLoginClient();
  client
    .get(GET_FEEDBACK_BY_COLLABORATOR_AND_TYPE_PATH(companyId, collaboratorId, isSent))
    .then((response) => {
      dispatch({
        type: isSent ? LOAD_FEEDBACKS_SENT_SUCCESS : LOAD_FEEDBACKS_SUCCESS,
        payload: response.data?.feedbacks,
      });
    })
    .catch(function(error) {
      dispatch({
        type: isSent ? LOAD_FEEDBACKS_SENT_FAILURE : LOAD_FEEDBACKS_FAILURE,
        payload: error
      });
    });
};

const sendFeedback = (companyId, body, type) => (dispatch) => {
  dispatch({ type: SEND_FEEDBACK_START });
  const client = getLoginClient();
  const URL = type === FEEDBACK_TYPE.feedbackSimpleSN ? SEND_FEEDBACK_SIMPLE_PATH(companyId) : SEND_FEEDBACK_SSC_PATH(companyId);
  client
    .post(URL, body)
    .then((response) => {
      dispatch({
        type: SEND_FEEDBACK_SUCCESS,
        payload: response.data[type],
      });
    })
    .catch(function(error) {
      dispatch({
        type: SEND_FEEDBACK_FAILURE,
        payload: error
      });
    });
};

export default {
  getFeedbacksByEmployeeIdAndType,
  sendFeedback,
};
