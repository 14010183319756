import { combineReducers } from "redux";
import userNamesReducer from "./userNamesReducer";
import candidatesReducer from "./candidatesReducer";
import organizationUnitsReducer from "./organizationUnitsReducer";
import citiesV1Reducer from "./citiesV1Reducer";
import documentsReducer from "./documentsReducer";
import infoTypesReducer from "./infoTypesReducer";
import organizationUnitsTypesReducer from "./organizationUnitsTypesReducer";
import collaboratorsReducer from "./collaboratorsReducer";
import oAuthReducer from "./oAuthReducer";
import countriesV1Reducer from "./countriesV1Reducer";
import potentialReducer from "./potentialReducer";
import nineBoxReducer from "./nineBoxReducer";
import performancesReducer from "./performancesReducer";
import typeOfContractReducer from "./typeOfContractReducer";
import surveyResultsReducer from "./surveyResultsReducer";
import personalitiesReducer from "./personalitiesReducer";
import massiveUploadReducer from "./massiveUploadReducer";
import currencyReducer from "./currencyReducer";
import rolesReducer from "./rolesReducer";
import movementsHistoryReducer from "./movementsHistoryReducer";
import benefitsReducer from "./benefitsReducer";
import goalsReducer from "./goalsReducer";
import goodLeaderReducer from "./goodLeaderReducer";
import engagementReducer from "./engagementReducer";
import surveyProcessesReducer from "./surveyProcessesReducer";
import feedbacksReducer from "./feedbacksReducer";
import employmentRelationshipReducer from "./employmentRelationshipReducer";
import positionsReducer from "./positionsReducer";
import hrAnalyticsReducer from "./hrAnalyticsReducer";
import { RESET_MODAL } from "../actions/types";

//  new implementation start here
import acknowledgementTypesReducer from "../redux/reducers/acknowledgementTypesReducer";
import nineboxProcessReducer from "../redux/reducers/nineboxReducer";
import managerReducer from "../redux/reducers/common/managerReducer";
import signInReducer from "../redux/reducers/userAuthReducer";
import createPasswordReducer from "../redux/reducers/userRecoveryReducer";
import countryReducer from "../redux/reducers/common/countryReducer";
import orgUnitReducer from "../redux/reducers/common/orgUnitReducer";
import collaboratorReducer from "../redux/reducers/collaboratorReducer";
import surveysReducer from "../redux/reducers/surveyProcessesReducer";
import cityReducer from "../redux/reducers/common/cityReducer";
import surveyResultReducer from "../redux/reducers/surveyResultReducer";
import documentTypesReducer from "../redux/reducers/documentTypesReducer";
import collaboratorDocumentReducer from "../redux/reducers/collaborator/documentReducer";
import performanceReducer from "../redux/reducers/performance/performanceReducer";
import performanceSurveyResultsReducer from "../redux/reducers/performance/surveyResultsReducer";
import performanceRankingReducer from "../redux/reducers/performance/rankingReducer";
import performanceHeatMapReducer from "../redux/reducers/performance/heatMapReducer";
import performanceGoodLeaderReducer from "../redux/reducers/performance/goodLeaderReducer";
import collaboratorAttritionReducer from "../redux/reducers/collaborator/attritionReducer";
import attritionReducer from "../redux/reducers/attritionReducer";
import personalityReducer from "../redux/reducers/personalityReducer";

import stateReducer from "../redux/reducers/common/stateReducer";
import timeOffReducer from "../redux/reducers/timeOffReducer"

export const resetModalReducer = (dispatch) => {
  if (dispatch) {
    dispatch({
      type: RESET_MODAL,
    });
  }
  return null;
};

const rootReducer = combineReducers({
  userNamesReducer,
  organizationUnitsReducer,
  candidatesReducer,
  citiesV1Reducer,
  documentsReducer,
  infoTypesReducer,
  organizationUnitsTypesReducer,
  collaboratorsReducer,
  oAuthReducer,
  countriesV1Reducer,
  potentialReducer,
  nineBoxReducer,
  performancesReducer,
  typeOfContractReducer,
  surveyResultsReducer,
  personalitiesReducer,
  massiveUploadReducer,
  currencyReducer,
  rolesReducer,
  movementsHistoryReducer,
  benefitsReducer,
  goalsReducer,
  feedbacksReducer,
  goodLeaderReducer,
  engagementReducer,
  surveyProcessesReducer,
  employmentRelationshipReducer,
  positionsReducer,
  hrAnalyticsReducer,
  resetModalReducer,

  //  new implementation start here
  acknowledgementTypesReducer,
  nineboxProcessReducer,
  managerReducer,
  signInReducer,
  createPasswordReducer,
  countryReducer,
  orgUnitReducer,
  collaboratorReducer,
  surveysReducer,
  cityReducer,
  surveyResultReducer,
  documentTypesReducer,
  collaboratorDocumentReducer,
  performanceReducer,
  performanceSurveyResultsReducer,
  performanceRankingReducer,
  performanceHeatMapReducer,
  performanceGoodLeaderReducer,
  collaboratorAttritionReducer,
  attritionReducer,
  personalityReducer,

  stateReducer,
  timeOffReducer,
});

export default rootReducer;
