import countryActions from "./countryActions";
import userNameActions from "./userNameActions";
import candidateActions from "./candidateActions";
import organizationUnitsActions from "./organizationUnitsActions";
import additionalDataActions from "./additionalDataActions";
import collaboratorActions from "./collaboratorActions";
import oAuthActions from "./oAuthActions";
import potentialActions from "./potentialActions";
import nineBoxActions from "./nineBoxActions";
import performanceActions from "./performanceActions";
import typeOfContractActions from "./typeOfContractActions";
import surveyResultsActions from "./surveyResultsActions";
import personalityActions from "./personalityActions";
import massiveUploadActions from "./massiveUploadActions";
import currencyActions from "./currencyActions";
import roleActions from "./roleActions";
import movementsHistoryActions from "./movementsHistoryActions";
import benefitActions from "./benefitActions";
import goalsActions from "./goalsActions";
import goodLeaderActions from "./goodLeaderActions";
import engagementActions from "./engagementActions";
import surveyProcessesActions from "./surveyProcessesActions";
import feedbackActions from "./feedbackActions";
import employmentRelationshipActions from "./employmentRelationshipActions";
import trainingActions from "./trainingActions";
import positionActions from "./positionActions";
import hrAnalyticActions from "./hrAnalyticActions";

const allActions = {
  countryActions,
  userNameActions,
  candidateActions,
  organizationUnitsActions,
  additionalDataActions,
  collaboratorActions,
  oAuthActions,
  potentialActions,
  nineBoxActions,
  performanceActions,
  typeOfContractActions,
  surveyResultsActions,
  personalityActions,
  massiveUploadActions,
  currencyActions,
  roleActions,
  movementsHistoryActions,
  benefitActions,
  goalsActions,
  surveyProcessesActions,
  goodLeaderActions,
  engagementActions,
  feedbackActions,
  employmentRelationshipActions,
  trainingActions,
  positionActions,
  hrAnalyticActions,
};

export default allActions;
