import configAxios from "../configAxios";
import errorHandler from "../errorHandler";
import {
  RESET_STATE,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
} from "../actionTypes/signIn";

const URL = {
  main: "/sessions",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const getOne = (formData) => async (dispatch, getState) => {
  const { one } = getState().signInReducer;

  if (!one) {
    dispatch({
      type: GET_ONE_LOADING,
    });
    try {
      const response = await configAxios.post(URL.main, formData);
      dispatch({
        type: GET_ONE,
        payload: response.data,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_ONE_ERROR);
    }
  }
};
