import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  inputDate: {
    "& input": {
      padding: "10.5px !important",
    },
    "& .MuiIconButton-root": {
      padding: "0px",
    }
  },
});
