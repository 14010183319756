import React from "react";
import PropTypes from "prop-types";
import ReorderIcon from "@material-ui/icons/Reorder";
import EventIcon from "@material-ui/icons/Event";
import Box from "@material-ui/core/Box";
import TabIcons from "components/TabIcons";
import GantTimeOff from "./components/GantTimeOff";
import TableTimeOff from "./components/TableTimeOff";

const TabGantTable = (props) => {
  const {
    gantData, gantIsLoading, tableData, tableIsLoading, pageFilter, pageHandler, statesFilter,
  } = props;

  const components = [
    { component: <TableTimeOff data={ tableData } pageHandler={ pageHandler } isLoading={ tableIsLoading } pageFilter={ pageFilter } />, icon: <ReorderIcon /> },
    { component: <GantTimeOff data={ gantData } statesFilter={ statesFilter } isLoading={ gantIsLoading } />, icon: <EventIcon /> },
  ];

  return (
    <Box>
      <TabIcons components={ components } />
    </Box>
  );
};

TabGantTable.propTypes = {
  gantData: PropTypes.array,
  tableData: PropTypes.array,
  tableIsLoading: PropTypes.bool.isRequired,
  gantIsLoading: PropTypes.bool.isRequired,
};
export default TabGantTable;
