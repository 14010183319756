import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useStyles from "./styles";

const TabComponent = (props) => {
  const { tabValue, tabs, onChange, customStyle, variant="scrollable" } = props;
  const [value, setValue] = useState(tabValue);
  const clasess = useStyles();

  const handleChange = (event, value) => {
    setValue(value);
    onChange(value);
  };

  return (
    <Tabs
      data-testid="tabsContainer"
      value={value}
      onChange={handleChange}
      variant={variant}
      scrollButtons="on"
      aria-label="Tabs"
      TabIndicatorProps={{ className: clasess.indicator }}
      className={clasess.tabs}
    >
      {tabs &&
        tabs.map((item, index) => {
          return (
            <Tab
              key={index}
              label={item.label}
              id={`simple-tab-${index}`}
              aria-controls={`simple-tabpanel-${index}`}
              disabled={item.isDisabled}
              className={customStyle}
            />
          );
        })}
    </Tabs>
  );
};

TabComponent.propTypes = {
  tabs: PropTypes.array,
  tabValue: PropTypes.number,
  onChange: PropTypes.func,
  customStyle: PropTypes.string
};

export default TabComponent;
