import React from "react";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import includes from "lodash/includes";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { ReactComponent as Units } from "assets/images/administrator/units.svg";
import { ReactComponent as Surveys } from "assets/images/administrator/surveys.svg";
import { ReactComponent as Cities } from "assets/images/administrator/city.svg";
import { ReactComponent as Positions } from "assets/images/administrator/positions.svg";
import { ReactComponent as ContractTypes } from "assets/images/administrator/contract_types.svg";
import { sendSurveyProcess } from "redux/actions/surveyProcessesActions";
import {
  OBJECT_KEYS, SIZE, MIN_VALUE, LOCAL_STORAGE_NAMES,
} from "common/constants";

export const MODULES_INFO = [
  {
    icon: <Units />,
    text: "units",
    path: "/administrator/units",
    isDisabled: false,
  },
  // FIXME: These options will be enabled when the services are ready
  {
    icon: <Positions />,
    text: "positions",
    path: "/administrator/positions",
    isDisabled: false,
  },
  {
    icon: <Surveys />,
    text: "surveys",
    path: "/administrator/surveys",
    new: true,
    simple: true,
    isDisabled: true,
  },
  {
    icon: <ContractTypes />,
    text: "contract-types",
    path: "/administrator/contract-types",
    isDisabled: false,
  },
  {
    icon: <Cities />,
    text: "cities",
    path: "/administrator/cities",
    isDisabled: false,
  },
];

export const MODULES_INDEX = {
  units: 0,
  positions: 1,
  surveys: 2,
  contractTypes: 3,
  cities: 4,
};

export const UNITS = MODULES_INFO[MODULES_INDEX.units].text;
export const CONTRACT_TYPES = MODULES_INFO[MODULES_INDEX.contractTypes].text;
export const CITIES = MODULES_INFO[MODULES_INDEX.cities].text;
export const POSITIONS = MODULES_INFO[MODULES_INDEX.positions].text;
const SURVEYS = MODULES_INFO[MODULES_INDEX.surveys]?.text;

export const getDataBreadcrumbs = (t, thisModule, last) => [
  {
    label: t("title"),
    path: "/administrator",
  },
  {
    label: t(`modules.${thisModule}.title`),
    path: last ? "./" : "",
  },
];

const getDeleteMessage = (t, thisModule, rowData) => {
  const mainMessage = `${t("common:common.modal_messages.delete_text")} ${t(`modules.${thisModule}.singular_title`)} ${
    rowData.name
  }?`;
  const collaboratorsMessage = `${rowData.collaborators_count} ${t(`modules.${thisModule}.collaborators`)} `;

  const deleteMessage = rowData.collaborators_count === MIN_VALUE
    ? mainMessage
    : `${collaboratorsMessage}${mainMessage}`;
  return deleteMessage;
};

export const handleRowDelete = (functions, t, thisModule, rowData) => functions.viewModal(
  t("common:common.modal_messages.sure_question"),
  "",
  getDeleteMessage(t, thisModule, rowData),
  t("common:common.modal_messages.yes_confirm"),
  t("common:common.modal_messages.no_cancel"),
  () => {
    functions.handleDelete(thisModule, rowData.id);
  },
);

export const handleRowEdit = (functions, thisModule, rowData) => {
  functions.handleEdit(thisModule, rowData);
};

const handleMove = (functions, thisModule, rowData, t) => {
  functions.handleMoveModal(thisModule, rowData, t);
};

export const pushValidOptions = (rowData, ACTIONS_MENU, DELETE_ACTION_MENU, thisModule, t, functions) => {
  const isAvailableToDelete = rowData.collaborators_count === MIN_VALUE;
  switch (thisModule) {
  case UNITS:
    const moveAction = {
      title: t("table.actions.move"),
      icon: <SyncAltIcon fontSize={ SIZE.small } />,
      onClick: () => {
        handleMove(functions, thisModule, rowData, t);
      },
    };
    ACTIONS_MENU.push(moveAction);
    ACTIONS_MENU.push(DELETE_ACTION_MENU);
    break;
  case CONTRACT_TYPES:
    if (isAvailableToDelete) {
      ACTIONS_MENU.push(DELETE_ACTION_MENU);
    }
    break;
  default:
      ACTIONS_MENU.push(DELETE_ACTION_MENU);
    break;
  }
};

export const postDispatch = async (thisModule, allActions, data, companyId) => {
  let createElement;
  switch (thisModule) {
  case UNITS:
    createElement = await allActions.organizationUnitsActions.createOrganizationUnits(data, companyId);
    break;
  case CONTRACT_TYPES:
    createElement = await allActions.typeOfContractActions.createTypeOfContract(data, companyId);
    break;
  case CITIES:
    createElement = await allActions.countryActions.createCity(data, companyId);
    break;
  case POSITIONS:
    createElement = await allActions.positionActions.createPosition(data, companyId);
    break;

  default:
    break;
  }
  return createElement;
};

export const putDispatch = async (thisModule, allActions, id, data, companyId) => {
  let updateElement;
  switch (thisModule) {
  case UNITS:
    updateElement = await allActions.organizationUnitsActions.updateOrganizationUnits(id, data, companyId);
    break;
  case CONTRACT_TYPES:
    updateElement = await allActions.typeOfContractActions.updateTypeOfContract(id, data, companyId);
    break;
  case CITIES:
    updateElement = await allActions.countryActions.updateCity(id, data, companyId);
    break;
  case POSITIONS:
    updateElement = await allActions.positionActions.updatePosition(id, data, companyId);
    break;

  default:
    break;
  }
  return updateElement;
};

export const deleteDispatch = async (thisModule, allActions, id, companyId, alternativeId, isMassive) => {
  let deleteElement;
  switch (thisModule) {
  case UNITS:
    deleteElement = await allActions.organizationUnitsActions.deleteOrganizationUnits(id, companyId, alternativeId, isMassive);
    break;
  case CONTRACT_TYPES:
    deleteElement = await allActions.typeOfContractActions.deleteTypeOfContract(id, companyId);
    break;
  case CITIES:
    deleteElement = await allActions.countryActions.deleteCity(id, companyId, alternativeId);
    break;
  case POSITIONS:
    deleteElement = await allActions.positionActions.deletePosition(id, companyId, alternativeId, isMassive);
    break;
  default:
    break;
  }
  return deleteElement;
};

export const getReducerToUse = (thisModule, selector) => {
  let data = [];
  let isLoading = false;
  const organizationUnitsReducer = selector((state) => state.organizationUnitsReducer);
  const surveyProcessesReducer = selector((state) => state.surveyProcessesReducer);
  const typeOfContractReducer = selector((state) => state.typeOfContractReducer);
  const citiesV1Reducer = selector((state) => state.citiesV1Reducer);
  const positionsReducer = selector((state) => state.positionsReducer);
  switch (thisModule) {
  case UNITS:
    data = organizationUnitsReducer.organizationUnitsTree;
    isLoading = organizationUnitsReducer.isLoadingTree;
    break;
  case SURVEYS:
    data = surveyProcessesReducer.surveyProcesses;
    isLoading = surveyProcessesReducer.isLoading;
    break;
  case CONTRACT_TYPES:
    data = typeOfContractReducer.typeOfContract;
    isLoading = typeOfContractReducer.isLoading;
    break;
  case CITIES:
    data = citiesV1Reducer.cities;
    isLoading = citiesV1Reducer.isLoading;
    break;
  case POSITIONS:
    data = positionsReducer?.positions;
    isLoading = positionsReducer?.isLoading;
    break;

  default:
    break;
  }
  return {
    data,
    isLoading,
  };
};

export const pushChildren = (list, data, parentId) => {
  isNull(parentId) ? list.push(data) : list.children.push(data);
  return list;
};

const findNestedChildrens = (object, targetId) => {
  if (object.id === targetId) {
    return object;
  }
  if (object.children) {
    for (const item of object.children) {
      const check = findNestedChildrens(item, targetId);
      if (check) {
        return check;
      }
    }
  }
  return null;
};

const findChildrens = (list, parentId) => {
  let result = null;
  for (const object of list) {
    result = findNestedChildrens(object, parentId);
    if (result) {
      break;
    }
  }

  return result;
};

export const getFinalObject = (list, parentId) => {
  const moduleList = list;
  if (isNull(parentId)) {
    return moduleList;
  }
  return findChildrens(moduleList, parentId);
};

export const getParentId = (actions, item) => (!isNull(actions.newIdElement)
  ? actions.newIdElement
  : isEmpty(item?.children)
    ? item?.parent_id
    : item?.children[0]?.parent_id);

export const getModuleData = (thisModule) => MODULES_INFO.find((moduleInfo) => moduleInfo.text === thisModule);

export const getHeaderModule = (t, thisModule) => ({
  title: t(`modules.${thisModule}.title`),
  subtitle: t(`modules.${thisModule}.subtitle`),
  breadcrumb: getDataBreadcrumbs(t, thisModule),
});

export const getAllExceptDeleted = (list, id) => {
  const listAux = list;
  return listAux
    .map((item) => ({ ...item }))
    .filter((item) => {
      if (OBJECT_KEYS.children in item) {
        item.children = getAllExceptDeleted(item.children, id);
      }
      return item.id !== id;
    });
};

export const getListUpdated = (list, element) => {
  const listAux = list;
  return listAux
    .map((item) => ({ ...item }))
    .filter((item) => {
      if (OBJECT_KEYS.children in item) {
        item.children = getListUpdated(item.children, element);
      }

      if (item.id === element.id) {
        // all keys need it from all modules - here -
        item.name = element.name;
        item.employment_relationship = element.employment_relationship;
        item.country_id = element.country_id;
      }

      return item;
    });
};

export const sendEmailDispatch = async (
  thisModule,
  dispatch,
  id,
  companyId,
  isReminder = false,
) => {
  let sendElement;
  switch (thisModule) {
  case SURVEYS:
    sendElement = await dispatch(sendSurveyProcess(id, isReminder));
    break;

  default:
    break;
  }
  return sendElement;
};

export const getSubmitDataObject = (module, objData, editableRow, selectedParentId) => {
  let newData;
  switch (module) {
  case UNITS:
    newData = {
      name: objData[module].name,
      parent_id: editableRow ? editableRow.parent_id : selectedParentId,
    };
    break;
  case CONTRACT_TYPES:
    newData = {
      name: objData[module].name,
      employment_relationship_id: objData[module].employment_relationship,
    };
    break;
  case CITIES:
    newData = {
      name: objData[module].name,
      country_id: objData[module].country_id,
    };
    break;
  case POSITIONS:
    newData = {
      name: objData[module].name,
    };
    break;
  default:
    break;
  }
  return newData;
};

export const getDefaultValues = (module, editableRow) => {
  let defaultValues;
  switch (module) {
  case UNITS:
    defaultValues = {
      name: editableRow.name,
    };
    break;
  case CONTRACT_TYPES:
    defaultValues = {
      name: editableRow.name,
      employment_relationship: editableRow.employment_relationship?.id,
    };
    break;
  case CITIES:
    defaultValues = {
      name: editableRow.name,
      country_id: editableRow.country_id,
    };
  break;
  case POSITIONS:
    defaultValues = {
      name: editableRow.name,
    };
    break;
  default:
    break;
  }
  return defaultValues;
};

export const validateOnAdd = (module) => {
  const validModules = [CONTRACT_TYPES, CITIES];
  const isOnAddValid = includes(validModules, module);
  if (isOnAddValid) {
    return true;
  }
  return false;
};

export const getPulseValidation = (module, control) => {
  let isValid;
  const controlToUse = control[module];
  switch (module) {
  case CONTRACT_TYPES:
    isValid = {
      name: isEmpty(controlToUse.name),
      employment_relationship: !isNumber(controlToUse.employment_relationship),
    };
    break;

  default:
    isValid = {
      name: isEmpty(controlToUse.name),
    };
    break;
  }
  return isValid;
};

export const removeOrGetFromLocalStorage = (module, isRemove) => {
  let itemName;
  switch (module) {
  case UNITS:
    itemName = "organizationUnits";
    break;
  case CONTRACT_TYPES:
    itemName = "typeOfContract";
    break;
  case CITIES:
    itemName = "cities";
    break;
  case POSITIONS:
    itemName = "positions";
    break;
  default:
    break;
  }
  if (isRemove) {
    localStorage.removeItem(LOCAL_STORAGE_NAMES[itemName]);
  } else {
    return itemName;
  }
};

export const getAsyncDispatch = (thisModule, allActions, companyId) => {
  switch (thisModule) {
  case UNITS:
    return allActions.organizationUnitsActions.getAllOrganizationUnits(companyId);
  case CITIES:
    return allActions.countryActions.getAllCities(companyId);
  default:
    break;
  }
};

export const getModalMoveTitle = (thisModule, t) => {
  switch (thisModule) {
  case UNITS:
    return t("modules.units.move");
  case CITIES:
    return t("modules.cities.move");
  default:
    break;
  }
};
