import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import InputForm from "components/InputForm";
import { INPUT_TYPE, VARIANT } from "common/constants";
import theme from "theme/palette";
import uploadImg from "assets/images/forms/subir-archivo.svg";
import { StyledIcon } from "styledComponents/StyledIcon";
import {
  BoxLabel, SpacedP, BoldLabel, FileLabel,
} from "./styles";

const FileInputTimeOff = (props) => {
  const {
    control, updateFiles, files, removeFile,
  } = props;
  const { t } = useTranslation(["common"]);

  return (
    <>
      <BoxLabel>
        <img alt={ "upload file" } src={ uploadImg } />
        <BoldLabel>{t("common.upload_file")}</BoldLabel>
      </BoxLabel>
      <InputForm
        type={ INPUT_TYPE.file }
        control={ control }
        name={ "attatchment" }
        text={ files.length ? `${files.length} ${t("common.filesSelected")}` : t("common.select_file") }
        onChange={ (event) => updateFiles(event.target) }
        variant={ VARIANT.outlined }
        typeStyle={ "outlined" }
      />
      {files?.map((file) => (
        <FileLabel key={ file.lastModified } onClick={ () => removeFile(file.name) }>
          <SpacedP>{file.name}</SpacedP>
          <StyledIcon color={ theme.error.main }>
            <DeleteOutlinedIcon />
          </StyledIcon>
        </FileLabel>
      ))}
    </>
  );
};

FileInputTimeOff.propTypes = {

  updateFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  files: PropTypes.any.isRequired,

};

export default FileInputTimeOff;
