import styled from "styled-components";
import Modal from "components/Modal";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const CustomModal = styled(Modal)`
width: 650px;
max-width: 600px;
width: 90vw;
`;

export const useStyles = makeStyles((theme) => ({

  mobileModal: {
    width: "90vw",
  },

}));
