import Autocomplete from "@material-ui/lab/Autocomplete";
import withStyles from "@material-ui/core/styles/withStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TABLE } from "theme/palette";

export const CustomAutocomplete = withStyles((theme) => ({
  root: {
    marginTop: 6,
  },
  tag: {
    backgroundColor: theme.palette.background.inputTags,
    "& .MuiChip-label": {
      color: theme.palette.text.subtitle,
    },
    "& .MuiChip-deleteIcon": {
      color: theme.palette.background.iconTags,
    },
  },
}))(Autocomplete);

export const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginRight: 8,
    "& .MuiIconButton-label": {
      color: theme.palette.text.link,
    },
  },
  addNew: {
    background: TABLE.ODD,
    color: theme.palette.black,
    fontSize: 12,
    "&:hover": {
      cursor: "pointer",
    },
    padding: "10px",
  },
  empty: {
    background: TABLE.ODD,
    color: theme.palette.black,
    fontSize: 12,
    padding: "10px",
  },
}));
