import React from "react";
import { StyledContainer, StyledContent, CustomAvatar, StyledBadge } from "./styles";

const Collaborators = (props) => {
  const {
    fullName, image, jobPosition, isVisibleBadge,
  } = props;
  return (
    <StyledContainer>
      <StyledBadge
        anchorOrigin={ {
          vertical: "bottom",
          horizontal: "right",
        } }
        color={ "error" }
        overlap={ "circle" }
        variant={ "dot" }
        invisible={ !isVisibleBadge }
      >
        <CustomAvatar alt={ "Collaborator" } src={ image } />
      </StyledBadge>
      <StyledContent>
        <strong>{fullName}</strong>
        <p>{jobPosition}</p>
      </StyledContent>
    </StyledContainer>
  );
};

export default Collaborators;
