import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import NalaLogoBlue from "assets/images/top-bar-layout/logo-nala@2x.png";
import useStyles from "./styles";

const Brand = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Box
        display={{
          xs: "none",
          sm: "none",
          md: "block",
          lg: "none",
          xl: "none",
        }}
      >
        <Box>
          <CloseIcon onClick={props.onClose} className={classes.close} />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box>
            <img alt="Logo Nala" src={NalaLogoBlue} width={72} height={34} />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

Brand.propTypes = {
  className: PropTypes.string,
};

export default Brand;
