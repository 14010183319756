import {
  LOAD_USERNAME_START,
  LOAD_USERNAME_SUCCESS,
  LOAD_USERNAME_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";
import { COLLABORATORS } from "common/paths/collaborators";

const getUserNames = (id) => async (dispatch, getState) => {
  dispatch({ type: LOAD_USERNAME_START });
  const client = getLoginClient();
  const USER_NAMES_URL =
    COLLABORATORS.root +
    COLLABORATORS.companyId +
    id +
    COLLABORATORS.managerQuery;
  client
    .get(USER_NAMES_URL)
    .then((response) => {
      dispatch({
        type: LOAD_USERNAME_SUCCESS,
        payload: response.data.collaborators,
      });
    })
    .catch(function(error) {
      dispatch({ type: LOAD_USERNAME_FAILURE, payload: error });
    });
};

export default {
  getUserNames,
};
