import i18n from "i18next";

const getMessage = () => i18n.t("common:common.api_responses.error.title");

const errorHandler = (error, dispatch, typeAction) => {
  if (error.response) {
    let messageError = getMessage();
    if (error.response.data?.message || error.response.data?.error) {
      messageError = error.response.data.detail
        || error.response.data?.error
        || error.response.data?.message;
    }

    dispatch({
      type: typeAction,
      payload: messageError,
    });
  }
};

export default errorHandler;
