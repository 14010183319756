import { CURRENCIES } from "common/paths/currency";
import {
  LOAD_CURRENCY_START,
  LOAD_CURRENCY_SUCCESS,
  LOAD_CURRENCY_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";

const getCurrencies = (id) => async (dispatch, getState) => {
  dispatch({ type: LOAD_CURRENCY_START });
  const client = getLoginClient();
  const CURRENCIES_URL = `${CURRENCIES.root}${CURRENCIES.mainSearch}${id}`;
  client
    // TODO: URL is not the same that BASE_URL
    .get(CURRENCIES_URL)
    .then((response) => {
      dispatch({
        type: LOAD_CURRENCY_SUCCESS,
        payload: response.data.currencies,
      });
    })
    .catch(function(error) {
      dispatch({ type: LOAD_CURRENCY_FAILURE, payload: error });
    });
};

export default {
  getCurrencies,
};
