import {
  RESET_STATE,
  GET_LIST,
  GET_MAIN_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_STATE_COLLABORATOR,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
  RESET_STATE_ORG_CHART,
  GET_LIST_ORG_CHART,
  GET_LIST_ORG_CHART_LOADING,
  GET_LIST_ORG_CHART_ERROR,
} from "../actionTypes/collaborator";

const INITIAL_STATE = {
  mainList: null,
  list: [],
  isLoadinglist: false,
  errorlist: "",
  one: null,
  isLoadingOne: false,
  errorOne: "",
  orgChartList: [],
  isLoadingOrgChartList: false,
  errorOrgChartList: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      isLoadingList: false,
      errorList: null,
      list: [],
      mainList: [],
    };
  case RESET_STATE_COLLABORATOR:
    return {
      ...state,
      isLoadingOne: false,
      errorOne: null,
      one: null,
    };
  case RESET_STATE_ORG_CHART:
    return {
      ...state,
      isLoadingOrgChartList: false,
      errorOrgChartList: null,
      orgChartList: [],
    };
  case GET_LIST:
    return {
      ...state,
      list: action.payload,
      isLoadingList: false,
      errorList: "",
    };
  case GET_MAIN_LIST:
    return {
      ...state,
      mainList: action.payload,
      isLoadingList: false,
      errorList: "",
    };
  case GET_LIST_LOADING:
    return {
      ...state,
      list: [],
      isLoadingList: true,
      errorList: "",
    };
  case GET_LIST_ERROR:
    return {
      ...state,
      isLoadingList: false,
      errorList: action.payload,
    };
  case GET_ONE:
    return {
      ...state,
      one: action.payload,
      isLoadingOne: false,
      errorOne: "",
    };
  case GET_ONE_LOADING:
    return {
      ...state,
      list: [],
      isLoadingOne: true,
      errorOne: "",
    };
  case GET_ONE_ERROR:
    return {
      ...state,
      isLoadingOne: false,
      errorOne: action.payload,
    };
  case GET_LIST_ORG_CHART:
    return {
      ...state,
      orgChartList: action.payload,
      isLoadingOrgChartList: false,
      errorOrgChartList: "",
    };
  case GET_LIST_ORG_CHART_LOADING:
    return {
      ...state,
      orgChartList: [],
      isLoadingOrgChartList: true,
      errorOrgChartList: "",
    };
  case GET_LIST_ORG_CHART_ERROR:
    return {
      ...state,
      isLoadingOrgChartList: false,
      errorOrgChartList: action.payload,
    };
  default:
    return state;
  }
};
