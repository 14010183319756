import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

export const SubmitGrid = styled(Grid)`
justify-content: flex-end;
margin-top: 20px

`;

export const FileGrid = styled(Grid)`
padding-right: ${(props) => (props.isMobile ? "3px" : "25vw !important")}
`;

const useStyles = makeStyles((theme) => ({
  dateInputs: {
    width: "50%",
  },
}));

export default useStyles;
