import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { BUTTON_STYLE_TYPES, SIZE, KEY_PRESS } from "common/constants";
import FormHelperText from "components/FormHelperText";
import { CustomAutocomplete, useStyles } from "./styles";

const InputTag = (props) => {
  const {
    id,
    label,
    placeholder,
    size,
    itemsSelected,
    onChange,
    data,
    isDisabled,
    hasCheckbox,
    textAddDinamicTag,
    onAddDinamicTag,
    onInputTextChange = () => {},
    name,
    register,
    isRequired,
    customStyle,
    groupBy,
    limitTags,
    defaultValues,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation("common");

  const icon = <CheckBoxOutlineBlankIcon fontSize={ SIZE.small } />;
  const checkedIcon = <CheckBoxIcon fontSize={ SIZE.small } />;

  const [value, setValue] = useState(itemsSelected || defaultValues);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (items) => {
    onChange(items);
    setValue(items);
  };

  const handleInputTextChange = (text) => {
    onInputTextChange(text);
    setInputValue(text);
  };

  useEffect(() => {
    setValue(itemsSelected || defaultValues);
  }, [itemsSelected, defaultValues]);

  const handleDinamicTag = () => {
    onAddDinamicTag();
  };

  const addButton = ({ children, ...other }) => (
    <Paper { ...other }>
      {children}
      {!isEmpty(inputValue) && onAddDinamicTag && (
        <p onMouseDown={ handleDinamicTag } className={ classes.addNew }>
          {textAddDinamicTag}
        </p>
      )}

      {isEmpty(inputValue) && isEmpty(data) && (
        <p className={ classes.empty }>
          {" "}
          {t("common.no_records")}
        </p>
      )}
    </Paper>
  );

  const handleOnKeyUp = () => {
    setInputValue("");
    onAddDinamicTag();
  };

  const getInputEnterValidation = (e) => (e.key === KEY_PRESS.enter && !isEmpty(inputValue) && onAddDinamicTag
    ? handleOnKeyUp()
    : {});

  const getOptions = () => ((groupBy?.filterBy === groupBy?.compareWith) ? data : data.sort((a, b) => -b.label.localeCompare(a.label)));

  const getGroupBy = () => ((groupBy?.filterBy === groupBy?.compareWith) ? (option) => option.country : null);

  return (
    <CustomAutocomplete
      data-testid={ "input-tags" }
      limitTags={ limitTags }
      multiple
      id={ id }
      options={ getOptions() }
      value={ value }
      onChange={ (e, newval) => {
        handleChange(newval);
      } }
      inputValue={ inputValue }
      onInputChange={ (event, newInputValue) => {
        handleInputTextChange(newInputValue);
      } }
      disableCloseOnSelect
      size={ size }
      noOptionsText={ "" }
      className={ customStyle }
      disabled={ isDisabled }
      getOptionLabel={ (option) => option.label }
      getOptionSelected={ (option, value) => option.label === value.label }
      groupBy={ getGroupBy() }
      renderOption={ (option, { selected }) => (hasCheckbox ? (
        <>
          <Checkbox
            icon={ icon }
            checkedIcon={ checkedIcon }
            className={ classes.checkbox }
            checked={ selected }
          />
          {option.label}
        </>
      ) : (
        option.label
      )) }
      renderInput={ (params) => (
        <>
          <TextField
            { ...params }
            variant={ BUTTON_STYLE_TYPES.OUTLINED }
            label={ label }
            placeholder={ placeholder }
            inputRef={ register }
            name={ name }
            onKeyUp={ (e) => getInputEnterValidation(e) }
          />
          {!isDisabled && isRequired && <FormHelperText />}
        </>
      ) }
      PaperComponent={ addButton }
    />
  );
};

InputTag.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  itemsSelected: PropTypes.array,
  onChange: PropTypes.func,
  data: PropTypes.array,
  isDisabled: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
  isRequired: PropTypes.bool,
  customStyle: PropTypes.string,
  limitTags: PropTypes.number,
  defaultValues: PropTypes.array,
};

export default InputTag;
