export const RESET_STATE = "RESET_STATE_SURVEY_PROCESSES";
export const GET_LIST = "GET_LIST_SURVEY_PROCESSES";
export const GET_LIST_LOADING = "GET_LIST_LOADING_SURVEY_PROCESSES";
export const GET_LIST_ERROR = "GET_LIST_ERROR_SURVEY_PROCESSES";

export const RESET_STATE_ONE = "RESET_STATE_ONE_SURVEY_PROCESSES";
export const GET_ONE = "GET_ONE_SURVEY_PROCESSES";
export const GET_ONE_LOADING = "GET_ONE_LOADING_SURVEY_PROCESSES";
export const GET_ONE_ERROR = "GET_ONE_ERROR_SURVEY_PROCESSES";

export const RESET_STATE_PROCESS_SURVEY_PROCESSES = "RESET_STATE_PROCESS_SURVEY_PROCESSES";
export const PROCESS_SURVEY_PROCESSES = "PROCESS_COLLABORATOR_SURVEY_PROCESSES";
export const PROCESS_SURVEY_PROCESSES_LOADING = "PROCESS_COLLABORATOR_SURVEY_PROCESSES_LOADING";
export const PROCESS_SURVEY_PROCESSES_ERROR = "PROCESS_COLLABORATOR_SURVEY_PROCESSES_ERROR";

export const RESET_STATE_PERFORMANCE_EVOLUTION = "RESET_STATE_PERFORMANCE_EVOLUTION";
export const GET_LIST_PERFORMANCE_EVOLUTION_LOADING = "GET_LIST_PERFORMANCE_EVOLUTION_LOADING";
export const GET_LIST_PERFORMANCE_EVOLUTION = "GET_LIST_PERFORMANCE_EVOLUTION";
export const GET_LIST_PERFORMANCE_EVOLUTION_ERROR = "GET_LIST_PERFORMANCE_EVOLUTION_ERROR";

// Survey result is part of survey process so:
export const RESET_STATE_SURVEY_RESULTS = "RESET_STATE_SURVEY_RESULTS";
export const GET_LIST_SURVEY_RESULTS = "GET_LIST_SURVEY_RESULTS";
export const GET_LIST_LOADING_SURVEY_RESULTS = "GET_LIST_LOADING_SURVEY_RESULTS";
export const GET_LIST_ERROR_SURVEY_RESULTS = "GET_LIST_ERROR_SURVEY_RESULTS";

export const RESET_STATE_COLLABORATOR_PERFORMANCE_EVOLUTION = "RESET_STATE_COLLABORATOR_PERFORMANCE_EVOLUTION";
export const GET_COLLABORATOR_PERFORMANCE_EVOLUTION = "GET_COLLABORATOR_PERFORMANCE_EVOLUTION";
export const GET_COLLABORATOR_PERFORMANCE_EVOLUTION_LOADING = "GET_COLLABORATOR_PERFORMANCE_EVOLUTION_LOADING";
export const GET_COLLABORATOR_PERFORMANCE_EVOLUTION_ERROR = "GET_COLLABORATOR_PERFORMANCE_EVOLUTION_ERROR";
