import axios from "axios";
import {
  GET_CONTRACT_TYPES_URL,
  CREATE_CONTRACT_TYPE_URL,
  UPDATE_CONTRACT_TYPE_URL,
  DELETE_CONTRACT_TYPE_URL,
} from "common/paths/typeOfContracts";
import { INDEX } from "common/constants";
import {
  LOAD_TYPE_OF_CONTRACT_START,
  LOAD_TYPE_OF_CONTRACT_SUCCESS,
  LOAD_TYPE_OF_CONTRACT_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";

const getTypeOfContract = (companyId) => async (dispatch, getState) => {
  dispatch({ type: LOAD_TYPE_OF_CONTRACT_START });
  const client = getLoginClient();
  client
    .get(GET_CONTRACT_TYPES_URL(companyId))
    .then((response) => {
      dispatch({
        type: LOAD_TYPE_OF_CONTRACT_SUCCESS,
        payload: response.data.type_of_contracts,
      });
    })
    .catch((error) => {
      dispatch({
        type: LOAD_TYPE_OF_CONTRACT_FAILURE,
        payload: error.status,
      });
    });
};

const createTypeOfContract = async (data, companyId) => {
  try {
    const response = await axios.post(
      CREATE_CONTRACT_TYPE_URL(companyId),
      data,
    );
    return response.data.type_of_contract;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

const updateTypeOfContract = async (id, data, companyId) => {
  try {
    const response = await axios.put(
      UPDATE_CONTRACT_TYPE_URL(id, companyId),
      data,
    );
    return response.data.type_of_contract;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

const deleteTypeOfContract = async (id, companyId) => {
  try {
    const response = await axios.delete(
      DELETE_CONTRACT_TYPE_URL(id, companyId),
    );
    return response.data.type_of_contract;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message || error?.response?.data?.errors.base[INDEX.zero],
    };
  }
};

export default {
  getTypeOfContract,
  createTypeOfContract,
  updateTypeOfContract,
  deleteTypeOfContract,
};
