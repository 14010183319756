import {
  LOAD_EMPLOYMENT_RELATIONSHIP_START,
  LOAD_EMPLOYMENT_RELATIONSHIP_SUCCESS,
  LOAD_EMPLOYMENT_RELATIONSHIP_FAILURE,
} from "../actions/types";

const initialState = {
  employmentRelationship: null,
  isLoading: false,
  error: null,
};

const currenciesData = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_EMPLOYMENT_RELATIONSHIP_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_EMPLOYMENT_RELATIONSHIP_SUCCESS:
      return {
        ...state,
        employmentRelationship: action.payload,
        isLoading: false,
      };
    case LOAD_EMPLOYMENT_RELATIONSHIP_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default currenciesData;
