export const RESET_STATE = "RESET_STATE_ACKNOWLEDGEMENT_TYPES";
export const GET_LIST = "GET_LIST_ACKNOWLEDGEMENT_TYPES";
export const GET_LIST_LOADING = "GET_LIST_LOADING_ACKNOWLEDGEMENT_TYPES";
export const GET_LIST_ERROR = "GET_LIST_ERROR_ACKNOWLEDGEMENT_TYPES";

// NOTE: for feedback acknowledgement
export const RESET_STATE_FEEDBACK = "RESET_STATE_FEEDBACK_ACKNOWLEDGEMENT";
export const PROCESS_FEEDBACK = "PROCESS_FEEDBACK_ACKNOWLEDGEMENT";
export const PROCESS_FEEDBACK_LOADING = "PROCESS_FEEDBACK_ACKNOWLEDGEMENT_LOADING";
export const PROCESS_FEEDBACK_ERROR = "PROCESS_FEEDBACK_ACKNOWLEDGEMENT_ERROR";
// end
