import React from "react";
import { default as ButtonInput } from "@material-ui/core/Button";
import PropTypes from "prop-types";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BUTTON_STYLE_TYPES } from "common/constants";
import clsx from "clsx";
import useStyles from "./styles";

const Button = (props) => {
  const {
    id,
    variant,
    component,
    onClick = () => {},
    isDisabled,
    type,
    size,
    isFullWidth,
    children,
    isLoading,
    icon,
    iconSide,
    typeStyle,
    customStyle,
    className,
    href,
    onBlur,
  } = props;

  const classes = useStyles();

  const handleClick = (event) => onClick(event);

  const handleStylesButton = (type) => {
    const {
      SUBMIT, CANCEL, BACK, OUTLINED, DISABLED,
    } = BUTTON_STYLE_TYPES;
    switch (type) {
    case SUBMIT:
      return classes.submitStyle;
    case CANCEL:
      return classes.cancelStyle;
    case BACK:
      return classes.backStyle;
    case OUTLINED:
      return classes.outlinedStyle;
    case DISABLED:
      return classes.disabledStyle;
    default:
    }
  };

  return (
    <ButtonInput
      data-testid={ "button" }
      id={ id }
      variant={ variant }
      className={ clsx(
        handleStylesButton(typeStyle),
        classes.generalStyles,
        customStyle,
        className,
      ) }
      component={ component }
      onClick={ handleClick }
      disabled={ isDisabled || isLoading }
      type={ type }
      size={ size }
      fullWidth={ isFullWidth }
      to={ href }
      onBlur={ onBlur }
    >
      {isLoading ? (
        <CircularProgress size={ 30 } />
      ) : icon ? (
        <div className={ classes.butonIconContainer }>
          <div
            className={ _.isEqual(iconSide, "left") ? classes.iconLeft : classes.iconRight }
          >
            {icon}
          </div>
          {children}
        </div>
      ) : (
        children
      )}
    </ButtonInput>
  );
};

Button.propTypes = {
  id: PropTypes.any,
  variant: PropTypes.string,
  customStyle: PropTypes.string,
  component: PropTypes.any,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.object,
  iconSide: PropTypes.string,
  typeStyle: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
};

export default Button;
