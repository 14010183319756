import axios from "axios";
import {
  CREATE_CITY, CITIES, COUNTRIES, UPDATE_CITY_URL, DELETE_CITY_URL, GET_ALL_CITIES,
} from "common/paths/country";
import {
  LOAD_CITY_START,
  LOAD_CITY_SUCCESS,
  LOAD_CITY_FAILURE,
  LOAD_COUNTRY_START,
  LOAD_COUNTRY_SUCCESS,
  LOAD_COUNTRY_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";

const getCities = (id) => async (dispatch, getState) => {
  dispatch({ type: LOAD_CITY_START });
  const client = getLoginClient();
  const CITIES_URL = CITIES.root + CITIES.companyId + id;
  client
    .get(CITIES_URL)
    .then((response) => {
      dispatch({
        type: LOAD_CITY_SUCCESS,
        payload: response.data.cities,
      });
    })
    .catch(function(error) {
      dispatch({ type: LOAD_CITY_FAILURE, payload: error });
    });
};

const getCountries = (id) => async (dispatch, getState) => {
  dispatch({ type: LOAD_COUNTRY_START });
  const client = getLoginClient();
  const COUNTRIES_URL = COUNTRIES.root + COUNTRIES.companyId + id;
  client
    .get(COUNTRIES_URL)
    .then((response) => {
      dispatch({
        type: LOAD_COUNTRY_SUCCESS,
        payload: response.data.countries,
      });
    })
    .catch(function(error) {
      dispatch({ type: LOAD_COUNTRY_FAILURE, payload: error });
    });
};

const getAllCities = async (companyId) => {
  try {
    const resp = await axios.get(GET_ALL_CITIES(companyId));
    return resp.data.cities;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

const createCity = async (data, companyId) => {
  try {
    const response = await axios.post(CREATE_CITY(companyId), data);
    return response.data.city;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

const updateCity = async (id, data, companyId) => {
  try {
    const response = await axios.put(UPDATE_CITY_URL(id, companyId), data);
    return response.data.city;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

const deleteCity = async (id, companyId, moveId) => {
  try {
    const response = await axios.delete(DELETE_CITY_URL(id, companyId, moveId));

    return response.data.city;
  } catch (error) {
    return {
      error: error?.response?.status,
      message: error?.response?.data?.detail || error?.response?.data?.message,
    };
  }
};

export default {
  getCities,
  getCountries,
  createCity,
  updateCity,
  deleteCity,
  getAllCities,
};
