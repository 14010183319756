import React from "react";
import DinamicModals from "views/TimeOff/components/DinamicModals";
import { CustomModal, useStyles } from "./styles";

const Modal = (props) => {
  const {
    selectedModal, selectedId, onClose, isOpen,
  } = props;
  const classes = useStyles();

  return (
    <CustomModal
      isOpen={ isOpen }
      onClose={ () => onClose() }
      customStyle={ classes.mobileModal }
    >
      <DinamicModals
        state={ selectedModal }
        itemId={ selectedId }
        onClose={ onClose }
        isMobile
      />
    </CustomModal>
  );
};

export default Modal;
