import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,

  RESET_STATE_TOP,
  GET_TOP_LIST,
  GET_TOP_LIST_LOADING,
  GET_TOP_LIST_ERROR,

  RESET_STATE_BOTTOM,
  GET_BOTTOM_LIST,
  GET_BOTTOM_LIST_LOADING,
  GET_BOTTOM_LIST_ERROR,

  RESET_STATE_SURVEY_RESULTS,
  GET_SURVEY_RESULTS_LIST,
  GET_SURVEY_RESULTS_LIST_LOADING,
  GET_SURVEY_RESULTS_LIST_ERROR,
} from "../../actionTypes/performance/goodLeader";

const INITIAL_STATE = {
  list: null,
  loadinglist: false,
  errorlist: "",

  topList: null,
  loadingTopList: false,
  errorTopList: "",

  bottomList: null,
  loadingBottomList: false,
  errorBottomList: "",

  surveyResults: null,
  loadingSurveyResults: false,
  errorSurveyResults: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      loadingList: false,
      errorList: null,
      list: null,
    };
  case RESET_STATE_TOP:
    return {
      ...state,
      loadingTopList: false,
      errorTopList: null,
      topList: null,
    };
  case RESET_STATE_BOTTOM:
    return {
      ...state,
      loadingBottomList: false,
      errorBottomList: null,
      bottomList: null,
    };
  case RESET_STATE_SURVEY_RESULTS:
    return {
      ...state,
      loadingSurveyResults: false,
      errorSurveyResults: null,
      surveyResults: null,
    };
  case GET_LIST:
    return {
      ...state,
      list: action.payload,
      loadingList: false,
      errorList: "",
    };
  case GET_LIST_LOADING:
    return {
      ...state,
      list: null,
      loadingList: true,
      errorList: "",
    };
  case GET_LIST_ERROR:
    return {
      ...state,
      loadingList: false,
      errorList: action.payload,
    };
  case GET_TOP_LIST:
    return {
      ...state,
      topList: action.payload,
      loadingTopList: false,
      errorTopList: "",
    };
  case GET_TOP_LIST_LOADING:
    return {
      ...state,
      topList: null,
      loadingTopList: true,
      errorTopList: "",
    };
  case GET_TOP_LIST_ERROR:
    return {
      ...state,
      loadingTopList: false,
      errorTopList: action.payload,
    };
  case GET_BOTTOM_LIST:
    return {
      ...state,
      bottomList: action.payload,
      loadingBottomList: false,
      errorBottomList: "",
    };
  case GET_BOTTOM_LIST_LOADING:
    return {
      ...state,
      bottomList: null,
      loadingBottomList: true,
      errorBottomList: "",
    };
  case GET_BOTTOM_LIST_ERROR:
    return {
      ...state,
      loadingBottomList: false,
      errorBottomList: action.payload,
    };
  case GET_SURVEY_RESULTS_LIST:
    return {
      ...state,
      surveyResults: action.payload,
      loadingSurveyResults: false,
      errorsurveyResults: "",
    };
  case GET_SURVEY_RESULTS_LIST_LOADING:
    return {
      ...state,
      surveyResults: null,
      loadingSurveyResults: true,
      errorsurveyResults: "",
    };
  case GET_SURVEY_RESULTS_LIST_ERROR:
    return {
      ...state,
      loadingSurveyResults: false,
      errorSurveyResults: action.payload,
    };
  default:
    return state;
  }
};
