import {
  LOAD_MASSIVE_UPLOAD_START,
  LOAD_MASSIVE_UPLOAD_FAILURE,
  GET_MASSIVE_UPLOAD_SUCCESS,
} from "./types";
import getLoginClient from "./loggedInClient";
import { USERS, UPLOAD_STATUS } from "common/paths/massiveUpload";

const uploadUsers = (data, companyId) => async (dispatch, getState) => {
  dispatch({ type: LOAD_MASSIVE_UPLOAD_START });
  const client = getLoginClient();
  const MASSIVE_UPLOAD_USERS_URL = `${USERS.root}${USERS.mainSearch}${companyId}`;
  client
    .post(MASSIVE_UPLOAD_USERS_URL, data)
    .then((response) => {
      dispatch({
        type: GET_MASSIVE_UPLOAD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_MASSIVE_UPLOAD_FAILURE, payload: error });
    });
};

const getUploadStatus = (uploadProcessId, companyId) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_MASSIVE_UPLOAD_START });
  const client = getLoginClient();
  const MASSIVE_UPLOAD_STATUS_URL = `${UPLOAD_STATUS.root}/${uploadProcessId}/${UPLOAD_STATUS.mainSearch}${companyId}`;
  client
    .get(MASSIVE_UPLOAD_STATUS_URL)
    .then((response) => {
      dispatch({
        type: GET_MASSIVE_UPLOAD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: LOAD_MASSIVE_UPLOAD_FAILURE,
        payload: error.status,
      });
    });
};

export default { uploadUsers, getUploadStatus };
