import Grid from "@material-ui/core/Grid";
import Button from "components/Button";
import styled from "styled-components";

export const SubmitGrid = styled(Grid)`
  justify-content: flex-end;
  margin-top: 20px;
  display: ${ (props) => props.isMobile ? "ruby" : "flex"}
`;

export const CustomGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomButton = styled(Button)`
  margin-right: 18px;
`;
