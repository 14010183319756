import { GET_CTC_MONTHLY_URL, GET_HC_MONTHLY_URL } from "common/paths/hrAnalytics";
import {
  LOAD_HC_MONTHLY_START,
  LOAD_HC_MONTHLY_SUCCESS,
  LOAD_HC_MONTHLY_FAILURE,
  LOAD_CTC_MONTHLY_START,
  LOAD_CTC_MONTHLY_SUCCESS,
  LOAD_CTC_MONTHLY_FAILURE,
} from "./types";

import getLoginClient from "./loggedInClient";

const getHcMonthly = (companyId) => (dispatch) => {
  dispatch({ type: LOAD_HC_MONTHLY_START });
  const client = getLoginClient();
  client
    .get(GET_HC_MONTHLY_URL(companyId))
    .then((response) => {
      dispatch({
        type: LOAD_HC_MONTHLY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_HC_MONTHLY_FAILURE, payload: error });
    });
};

const getCtcMonthly = (companyId) => async (dispatch) => {
  dispatch({ type: LOAD_CTC_MONTHLY_START });
  const client = getLoginClient();
  client
    .get(GET_CTC_MONTHLY_URL(companyId))
    .then((response) => {
      dispatch({
        type: LOAD_CTC_MONTHLY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_CTC_MONTHLY_FAILURE, payload: error });
    });
};

export default {
  getHcMonthly,
  getCtcMonthly,
};
