import {
  LOAD_DOCUMENT_START,
  LOAD_DOCUMENT_SUCCESS,
  LOAD_DOCUMENT_FAILURE,
} from "../actions/types";

let initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const documentsData = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DOCUMENT_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case LOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default documentsData;
