export const openFile = (file) => window.open(file, "_blank");

const handleDownload = (fileURL) => {
  fileURL.forEach((value, idx) => {
    const response = {
      file: value,
    };
    setTimeout(() => {
      window.location.href = response.file;
    }, idx * 2000);
  });
};

export default handleDownload;
