import React, { useContext, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { SessionContext } from "modules/session/context";
import useStyles from "./styles";

const Profile = (props) => {
  const { className, ...rest } = props;
  const { state: user } = useContext(SessionContext);
  const classes = useStyles();
  const [dataUser] = useState(user);

  const validateUser = (user) => !user;
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Box
        display={{
          xs: "block",
          sm: "block",
          md: "none",
          lg: "none",
          xl: "none",
        }}
      >
        <Box>
          <CloseIcon onClick={props.onClose} className={classes.close} />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box>
            <Avatar
              alt={!validateUser(dataUser) ? dataUser.full_name : ""}
              src={!validateUser(dataUser) ? dataUser.profile_img_url : ""}
              className={classes.avatar}
            />
          </Box>
          <Box>
            <Typography className={classes.profileName}>
              {!validateUser(dataUser) && dataUser.full_name}
            </Typography>
            <Typography className={classes.profilePosition}>
              {/* TODO: here comes position value (not coming from backend yet) */}
            </Typography>
            <Typography className={classes.profileCPF}>
              #{!validateUser(dataUser) && dataUser.personal_id}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
