const companyId = "?company_id=";

export const USERS = {
  root: "/users/import",
  mainSearch: companyId,
};

export const UPLOAD_STATUS = {
  root: "/async_task",
  mainSearch: companyId,
};
