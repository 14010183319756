import _ from "lodash";
import { ORG_UNITS } from "common/constants";
import {
  PERFORMANCE_PROCESSES,
  SURVEY_PROCESSES,
  SURVEY_RESULTS,
} from "common/paths/performance";
import {
  LOAD_PERFORMANCE_PROCESS_START,
  LOAD_PERFORMANCE_PROCESS_SUCCESS,
  LOAD_PERFORMANCE_PROCESS_FAILURE,
  GET_PERFORMANCE_SURVEY_RESULT_SUCCESS,
  GET_PERFORMANCE_SURVEY_RESULTS_SUCCESS,
  LOAD_TOP_RANKING_START,
  GET_TOP_RANKING_SUCCESS,
  GET_BOTTOM_RANKING_SUCCESS,
  LOAD_TOP_RANKING_FAILURE,
  LOAD_HEAT_MAP_START,
  GET_HEAT_MAP_SUCCESS,
  LOAD_HEAT_MAP_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";

const getPerformanceProcess = (id) => async (dispatch, getState) => {
  dispatch({ type: LOAD_PERFORMANCE_PROCESS_START });
  const client = getLoginClient();
  const GET_PERFORMANCE_PROCESS_URL =
    PERFORMANCE_PROCESSES.root + PERFORMANCE_PROCESSES.companyId + id;
  client
    .get(GET_PERFORMANCE_PROCESS_URL)
    .then((response) => {
      dispatch({
        type: LOAD_PERFORMANCE_PROCESS_SUCCESS,
        payload: response.data.performance_processes,
      });
    })
    .catch(function(error) {
      dispatch({
        type: LOAD_PERFORMANCE_PROCESS_FAILURE,
        payload: error.status,
      });
    });
};

const getAllPerformanceSurveyResult = (
  id,
  companyId,
  employeeId,
  userId
) => async (dispatch, getState) => {
  dispatch({ type: LOAD_PERFORMANCE_PROCESS_START });
  const client = getLoginClient();

  const PERFORMANCE_SURVEY_RESULT_URL = `${SURVEY_PROCESSES.root}/${id}/${SURVEY_PROCESSES.survey_results}${SURVEY_PROCESSES.mainSearch}${companyId}${SURVEY_PROCESSES.userIdQuery}${userId}${SURVEY_PROCESSES.employeeIdQuery}${employeeId}`;

  client
    .get(PERFORMANCE_SURVEY_RESULT_URL)
    .then((response) => {
      dispatch({
        type: GET_PERFORMANCE_SURVEY_RESULTS_SUCCESS,
        payload: response.data.survey_results,
      });
    })
    .catch(function(error) {
      dispatch({
        type: LOAD_PERFORMANCE_PROCESS_FAILURE,
        payload: error.status,
      });
    });
};

const getPerformanceSurveyResult = (id, companyId) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_PERFORMANCE_PROCESS_START });
  const client = getLoginClient();
  const GET_PERFORMANCE_SURVEY_RESULT = `${SURVEY_RESULTS.root}/${id}${SURVEY_RESULTS.companyId}${companyId}`;
  client
    .get(GET_PERFORMANCE_SURVEY_RESULT)
    .then((response) => {
      dispatch({
        type: GET_PERFORMANCE_SURVEY_RESULT_SUCCESS,
        payload: response.data.survey_result,
      });
    })
    .catch(function(error) {
      dispatch({
        type: LOAD_PERFORMANCE_PROCESS_FAILURE,
        payload: error.status,
      });
    });
};

const getRanking = (id, companyId, type, userId) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_TOP_RANKING_START });
  const client = getLoginClient();
  const RANKING_URL = `${SURVEY_PROCESSES.root}/${id}/${type}${SURVEY_PROCESSES.mainSearch}${companyId}${SURVEY_PROCESSES.userIdQuery}${userId}`;
  client
    .get(RANKING_URL)
    .then((response) => {
      dispatch({
        type: _.isEqual(type, SURVEY_PROCESSES.topResults)
          ? GET_TOP_RANKING_SUCCESS
          : GET_BOTTOM_RANKING_SUCCESS,
        payload: response.data.survey_results,
      });
    })
    .catch(function(error) {
      dispatch({
        type: LOAD_TOP_RANKING_FAILURE,
        payload: error.status,
      });
    });
};

const getHeatMap = (id, companyId, filter, userId) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_HEAT_MAP_START });
  const client = getLoginClient();
  let filterSeach = !_.isEqual(filter, ORG_UNITS.division)
    ? `${SURVEY_PROCESSES.filterBy}${_.toLower(filter)}`
    : "";
  const URL_HEAT_MAP_WITH_FILTER_SEARCH = `${SURVEY_PROCESSES.root}/${id}/${SURVEY_PROCESSES.heatMap}${SURVEY_PROCESSES.mainSearch}${companyId}${SURVEY_PROCESSES.userIdQuery}${userId}${filterSeach}`;

  client
    .get(URL_HEAT_MAP_WITH_FILTER_SEARCH)
    .then((response) => {
      dispatch({
        type: GET_HEAT_MAP_SUCCESS,
        payload: response.data,
      });
    })
    .catch(function(error) {
      dispatch({
        type: LOAD_HEAT_MAP_FAILURE,
        payload: error.status,
      });
    });
};

export default {
  getPerformanceProcess,
  getAllPerformanceSurveyResult,
  getPerformanceSurveyResult,
  getRanking,
  getHeatMap,
};
