import moment from "moment";
import getGantColor from "./getGantColor";

const formatItems = (data, statesFilter, t) => {

  let formatedItems = [];
  data && data.forEach((element) => {
    element.time_offs.forEach((timeOff) => {
      const title = timeOff.state === "pending" || timeOff.state === "rejected" ? t(`states.${timeOff.state}`) : t(`types.${timeOff.time_off_type}`);
      const itemColor = getGantColor(timeOff.state, timeOff.time_off_type);
      const letterColor = getGantColor(timeOff.state, timeOff.time_off_type, true);
      formatedItems.push({
        id: timeOff.id,
        type: t(`types.${timeOff.time_off_type}`),
        state: timeOff.state,
        group: element.id,
        files: timeOff.files_url,
        rejection_reason: timeOff.rejection_reason,
        title,
        start_time: moment(timeOff.starting_date).add(0, "day"),
        end_time: moment(timeOff.ending_date).add(0, "day"),
        canMove: false,
        itemProps: {
          borderRadius: 0,
          style: {
            background: itemColor,
            color: letterColor,
            border: 0,
            textAlign: "center",
            fontSize: 14,
            paddingTop: 20,
          },
        },
      });
    });
  });

  if (statesFilter.length > 0) {
    formatedItems = formatedItems.filter((item) => statesFilter.includes(item.state));
  }
  return formatedItems;
}

export default formatItems;