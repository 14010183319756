import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "components/Button";
import { SessionContext } from "modules/session/context";
import { update } from "redux/actions/timeOffActions";
import { STATE_CHANGE } from "common/constants/timeOff";
import { isAdmin as isAdminFunction } from "common/utils";
import {
  BUTTON_TYPE,
  SIZE,
  ALIGN_ITEMS,
  VARIANT,
} from "common/constants";
import { SubmitGrid, CustomGrid, CustomButton } from "./styles";

const AcceptForm = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "timeOff"]);
  const { timeOffId, onClose, isMobile } = props;

  const {
    state: { user },
  } = useContext(SessionContext);

  const isAdmin = isAdminFunction(user.roles);
  const { loadingProcess } = useSelector(
    ({ timeOffReducer }) => timeOffReducer,
  );

  const onSubmit = async () => {
    const state = isAdmin ? STATE_CHANGE.approve : STATE_CHANGE.leader_approve;
    await dispatch(update(timeOffId, state));
    onClose();
  };

  return (
    <Grid container direction={ "column" } spacing={ 5 }>
      <Grid item container spacing={ 3 }>
        <CustomGrid item xs={ 12 } lg={ 12 }>
          <h2><strong>{t("timeOff:Manage_request.Title")}</strong></h2>
        </CustomGrid>
        <CustomGrid item xs={ 12 } lg={ 12 }>
          <p>{t("timeOff:Manage_request.SubtitleAccept")}</p>
        </CustomGrid>
        <SubmitGrid
          isMobile={ isMobile }
          item
          lg={ 12 }
          sm={ 12 }
          container
          direction={ "row" }
          alignItems={ ALIGN_ITEMS.center }
        >
          <CustomButton
            variant={ VARIANT.contained }
            typeStyle={ "disabled" }
            size={ SIZE.medium }
            onClick={ () => onClose() }
          >
            {t("timeOff:Manage_request.Reject")}
          </CustomButton>
          <Button
            variant={ VARIANT.contained }
            type={ BUTTON_TYPE.submit }
            typeStyle={ "submit" }
            size={ SIZE.medium }
            onClick={ onSubmit }
            isLoading={ loadingProcess }
          >
            {t("timeOff:Manage_request.Accept")}
          </Button>
        </SubmitGrid>
      </Grid>
    </Grid>
  );
};

AcceptForm.propTypes = {
  timeOffId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AcceptForm;
