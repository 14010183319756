import {
  LOAD_CITY_START,
  LOAD_CITY_SUCCESS,
  LOAD_CITY_FAILURE,
} from "../actions/types";

const initialState = {
  cities: null,
  isLoading: false,
  error: null,
};

const citiesV1Data = (state = initialState, action) => {
  switch (action.type) {
  case LOAD_CITY_START:
    return {
      ...state,
      isLoading: true,
    };
  case LOAD_CITY_SUCCESS:
    return {
      ...state,
      cities: action.payload,
      isLoading: false,
    };
  case LOAD_CITY_FAILURE:
    return {
      ...state,
      error: action.payload,
      isLoading: false,
    };
  default:
    return state;
  }
};

export default citiesV1Data;
