import axios from "axios";
import { API_URL_RAILS_V1 } from "common/constants";

const loginClient = axios.create({
  baseURL: API_URL_RAILS_V1,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

loginClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function(error) {
    return Promise.reject(error.response);
  }
);

const getLoginClient = () => {
  return loginClient;
};

export default getLoginClient;
