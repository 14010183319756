import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useCookies } from "react-cookie";
import clsx from "clsx";
import PropTypes from "prop-types";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "components/Button";

import { SessionContext } from "modules/session/context";
import NalaLogoBlue from "assets/images/top-bar-minimal/logo-white.svg";
import GTranslateIcon from "@material-ui/icons/GTranslate";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import useStyles from "./styles";

const Topbar = (props) => {
  const {
    actions: { signOut },
    state: { user },
  } = useContext(SessionContext);
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");
  const [openSelect, setopenSelect] = useState(false);
  const [cookies, setCookie] = useCookies(["lenguage"]);
  const [lenguage, setlenguage] = useState("en");
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataUser] = useState(user);

  const handleChange = (event) => {
    setCookie("lenguage", event.target.value);
    setlenguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const handleClose = () => {
    setopenSelect(false);
  };

  const handleOpen = () => {
    setopenSelect(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosedProfile = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (cookies.lenguage) {
      setlenguage(cookies.lenguage);
    }
  }, [cookies]);

  const validateUser = (user) => isNull(user) || isUndefined(user);

  const expandIcon = anchorEl !== null ? <ExpandLessIcon /> : <ExpandMoreIcon />;

  return (
    <AppBar { ...rest } className={ clsx(classes.root, className) }>
      <Toolbar>
        <Box display={ {
          xs: "none", md: "block", lg: "none", xl: "none",
        } }
        >
          <IconButton onClick={ onSidebarOpen }>
            <MenuIcon className={ classes.whiteMenuIcon } />
          </IconButton>
        </Box>
        <Button>
          <RouterLink className={ classes.button } to={ "/" }>
            <img alt={ "Logo Nala" } src={ NalaLogoBlue } width={ 72 } height={ 34 } />
          </RouterLink>
        </Button>
        <div className={ classes.flexGrow } />
        <Button onClick={ handleOpen }>
          <GTranslateIcon className={ classes.icon } />
        </Button>
        <FormControl className={ classes.formControl }>
          <Select
            className={ classes.select }
            open={ openSelect }
            onClose={ handleClose }
            onOpen={ handleOpen }
            value={ lenguage }
            onChange={ handleChange }
            inputProps={ { classes: { icon: classes.icon } } }
          >
            <MenuItem value={ "en" }>{"English"}</MenuItem>
            <MenuItem value={ "es" }>{"Español"}</MenuItem>
            <MenuItem value={ "pr" }>{"Português"}</MenuItem>
          </Select>
        </FormControl>
        <Box px={ 2 } />
        <Hidden smDown>
          <Button
            aria-controls={ "simple-menu" }
            aria-haspopup={ "true" }
            onClick={ handleClick }
            customStyle={ classes.btnProfile }
          >
            {!validateUser(dataUser) && dataUser.full_name}
            {expandIcon}
            <Avatar
              alt={ !validateUser(dataUser) ? dataUser.full_name : "" }
              src={ !validateUser(dataUser) ? dataUser.profile_img_url : "" }
              className={ classes.avatar }
            />
          </Button>
          <Menu
            id={ "simple-menu" }
            anchorEl={ anchorEl }
            keepMounted
            open={ Boolean(anchorEl) }
            onClose={ handleClosedProfile }
            className={ classes.menu }
          >
            <div className={ classes.containerMenuItem }>
              <Typography className={ classes.MenuItemName }>
                {!validateUser(dataUser) && dataUser.full_name}
              </Typography>
              <Typography className={ classes.MenuItemCPF }>
                {"#"}
                {!validateUser(dataUser) && dataUser.personal_id}
              </Typography>
              <Divider className={ classes.dividerCustomcss } />
              <MenuItem className={ classes.menuItem } onClick={ signOut }>
                <ExitToAppIcon className={ classes.menuIcon } />
                {t("top-nav-bar.logout")}
              </MenuItem>
            </div>
          </Menu>
        </Hidden>
        <Hidden mdUp>
          <IconButton onClick={ onSidebarOpen }>
            <MenuIcon className={ classes.whiteMenuIcon } />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
