import qs from "qs";
import isEmpty from "lodash/isEmpty";
import { PARAMS_SERIALIZER_OPTIONS } from "common/constants";
import configAxios from "../configAxios";
import errorHandler from "../errorHandler";
import {
  RESET_STATE,
  GET_MAIN_LIST,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_STATE_COLLABORATOR,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
  RESET_STATE_ORG_CHART,
  GET_LIST_ORG_CHART,
  GET_LIST_ORG_CHART_LOADING,
  GET_LIST_ORG_CHART_ERROR,
} from "../actionTypes/collaborator";

const URL = {
  main: "/collaborators",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const resetStateOne = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_COLLABORATOR,
  });
};

export const resetStateOrgChart = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ORG_CHART,
  });
};

export const getListSearch = (isEmptyFilter, query) => async (dispatch) => {
  let object = null;
  let dispatchRequest = true;
  if (isEmptyFilter) {
    object = query.q;
    dispatchRequest = Object.values(object).map((item) => !isEmpty(item));
  }

  dispatch({
    type: GET_LIST_LOADING,
  });

  try {
    let responseList = [];
    if (!isEmptyFilter || (isEmptyFilter && dispatchRequest.find((item) => item === true))) {
      const response = await configAxios.get(URL.main, {
        params: query,
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });
      responseList = response.data.collaborators;
    }
    dispatch({
      type: GET_LIST,
      payload: responseList,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};

export const getOne = (collaboratorId) => async (dispatch, getState) => {
  const { one } = getState().collaboratorReducer;
  if (!one) {
    dispatch({
      type: GET_ONE_LOADING,
    });
    try {
      const response = await configAxios.get(`${URL.main}/${collaboratorId}`);
      dispatch({
        type: GET_ONE,
        payload: response.data,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_ONE_ERROR);
    }
  }
};

export const getMainList = () => async (dispatch, getState) => {
  const { mainList } = getState().collaboratorReducer;
  if (!mainList) {
    dispatch({
      type: GET_LIST_LOADING,
    });
    try {
      const response = await configAxios.get(URL.main);
      dispatch({
        type: GET_MAIN_LIST,
        payload: response.data.collaborators,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_ERROR);
    }
  }
};

export const getOrchartList = (isEmptyFilter, query) => async (dispatch) => {
  let object = null;
  let dispatchRequest = true;
  if (isEmptyFilter) {
    object = query.q;
    dispatchRequest = Object.values(object).map((item) => !isEmpty(item));
  }

  dispatch({
    type: GET_LIST_ORG_CHART_LOADING,
  });

  try {
    let responseList = [];
    if (!isEmptyFilter || (isEmptyFilter && dispatchRequest.find((item) => item === true))) {
      const response = await configAxios.get(URL.main, {
        params: { ...query, tree: true },
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });
      responseList = response.data.collaborators;
    }

    dispatch({
      type: GET_LIST_ORG_CHART,
      payload: responseList,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ORG_CHART_ERROR);
  }
};
