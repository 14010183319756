import {
  LOAD_ORGUNIT_TYPES_FAILURE,
  LOAD_ORGUNIT_TYPES_SUCCESS,
  LOAD_ORGUNIT_TYPES_START,
} from "../actions/types";

let initialState = {
  organizationUnitsTypes: null,
  isLoading: false,
  error: null,
};

const organizationUnitsTypesData = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ORGUNIT_TYPES_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_ORGUNIT_TYPES_SUCCESS:
      return {
        ...state,
        organizationUnitsTypes: action.payload,
        isLoading: false,
      };
    case LOAD_ORGUNIT_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default organizationUnitsTypesData;
