import { GET_TRAINING_PROCESSES_URL } from "common/paths/training";
import {
  LOAD_TRAINING_PROCESS_START,
  LOAD_TRAINING_PROCESS_SUCCESS,
  LOAD_TRAINING_PROCESS_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";

const getTrainingEvaluationProcess = (companyId) => (dispatch) => {
  dispatch({ type: LOAD_TRAINING_PROCESS_START });
  const client = getLoginClient();
  client
    .get(GET_TRAINING_PROCESSES_URL(companyId))
    .then((response) => {
      dispatch({
        type: LOAD_TRAINING_PROCESS_SUCCESS,
        payload: response.data.training_evaluation_processes,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_TRAINING_PROCESS_FAILURE, payload: error });
    });
};

export default { getTrainingEvaluationProcess };
