import {
  LOAD_ENGAGEMENT_PROCESS_START,
  LOAD_ENGAGEMENT_PROCESS_SUCCESS,
  LOAD_ENGAGEMENT_PROCESS_FAILURE,
} from "actions/types";

let initialState = {
  engagementProcess: null,
  isLoading: false,
  error: null,
};

const engagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ENGAGEMENT_PROCESS_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_ENGAGEMENT_PROCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        engagementProcess: action.payload,
      };
    case LOAD_ENGAGEMENT_PROCESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default engagementReducer;
