import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { TablePagination } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DeckIcon from "@material-ui/icons/Deck";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import NoDataMessage from "components/NoDataMessage";
import handleDownload from "views/TimeOff/functions/downloadFiles";
import StateManagingButtons from "../TableTimeOff/components/StateManagingButtons";
import Modal from "./components/Modal/index";
import * as Styled from "./styles";

export default function CollaboratorsCard(props) {
  const {
    data,
    pagination,
    isLoading,
    isAdmin,
  } = props;
  const { t } = useTranslation(["common", "timeOff"]);
  const [selectedId, setSelectedId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState();

  const handleModal = (state, id) => {
    setIsOpen(true);
    setSelectedId(id);
    setSelectedModal(state);
  };

  const handleOpen = () => setIsOpen(false);

  return (
    <>
      {data && data?.map((el) => (
        <Styled.RootContainer key={ el.starting_date }>
          <Styled.AvGrid>
            <Styled.AvatarCard aria-label={ "recipe" } src={ el.employee.profile_img_url } />
          </Styled.AvGrid>
          <Styled.CardContentContainer>
            <Styled.CardTitle>
              {el.employee.full_name}
            </Styled.CardTitle>
            <Styled.CardFont>
              <Styled.Leader>
                <AccountCircleOutlinedIcon fontSize={ "small" } />
                {el.employee.manager_name}
              </Styled.Leader>
              <Styled.CalendarGrid>
                <CalendarTodayIcon fontSize={ "small" } />
                <p>
                  {el.starting_date}
                  </p>
                <CalendarTodayIcon fontSize={ "small" } />
                <p>

                {el.ending_date}
                </p>
               
              </Styled.CalendarGrid>
              <Styled.TimeOffType>
                <DeckIcon fontSize={ "small" } />
                {t(`timeOff:types.${el.time_off_type}`)}
              </Styled.TimeOffType>
              <Styled.Motive>
                <QuestionAnswerIcon fontSize={ "small" } />
                {" "}
                {t("common.motive")}
                {":"}
              </Styled.Motive>
              <Styled.Reason>
                {el.reason}
              </Styled.Reason>
              <Styled.Leader>
                <DragHandleIcon fontSize={ "small" } />
                {t(`timeOff:states.${el.state}`)}
              </Styled.Leader>
            </Styled.CardFont>
            <Styled.Hr />
            <Styled.CardButton justify={ el.files_url.length > 0 ? "center" : "flex-end" } >
              {el.files_url.length > 0 ? (
                <IconButton onClick={ () => { handleDownload(el.files_url); } }>
                  <Styled.BlueCloudDownloadIcon />
                </IconButton >
              ) : ""}
              <StateManagingButtons
                state={ el.state }
                id={ el.id }
                isAdmin={ isAdmin }
                handleModal={ handleModal }
                flexEnd={ !(el.files_url.length > 0) }
                isMobile
              />
            </Styled.CardButton>
          </Styled.CardContentContainer>
        </Styled.RootContainer>
      ))}
      {!isLoading && (isEmpty(data) ? <NoDataMessage />
        : (pagination && data.length > 11 ) && (
          <TablePagination
            component={ "div" }
            count={ -1 }
            rowsPerPage={ pagination.size }
            page={ pagination.number }
            onChangePage={ pagination.handlePage }
            rowsPerPageOptions={ [] }
          />
        ))}
      <Modal
        selectedId={ selectedId }
        selectedModal={ selectedModal }
        isOpen={ isOpen }
        onClose={ handleOpen }
      />
    </>
  );
}

CollaboratorsCard.propTypes = {
  title: PropTypes.string,
  starting_date: PropTypes.string,
  ending_date: PropTypes.string,
  time_off_type: PropTypes.string,
  motive: PropTypes.string,
  reason: PropTypes.string,
  state: PropTypes.string,
};
