import configAxios from "../configAxios";
import errorHandler from "../errorHandler";
import {
  RESET_STATE_VALIDATE_PASSWORD,
  PROCESS_VALIDATE_PASSWORD,
  PROCESS_VALIDATE_PASSWORD_LOADING,
  PROCESS_VALIDATE_PASSWORD_ERROR,
  PROCESS_CREATE_PASSWORD_ERROR,
  PROCESS_CREATE_PASSWORD,
  PROCESS_CREATE_PASSWORD_LOADING,
  PROCESS_PASSWORD_RECOVERY_LOADING,
  PROCESS_PASSWORD_RECOVERY, RESET_STATE_CREATE_PASSWORD, RESET_STATE_PASSWORD_RECOVERY,
} from "../actionTypes/createPassword";

const URL = {
  main: "/user",
  validateTokenURL: "user/check_reset_token",
  passwordCreationURL: "auth/users/password",
  requestPasswordReset: "user/reset_password",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_VALIDATE_PASSWORD,
  });
};

export const resetStateCreatePassword = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_CREATE_PASSWORD,
  });
};

export const resetPasswordRecovery = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_PASSWORD_RECOVERY,
  });
};

export const validateToken = (formData) => async (dispatch, getState) => {
  dispatch({
    type: PROCESS_VALIDATE_PASSWORD_LOADING,
  });
  try {
    const data = {
      reset_password_token: formData.token_password_creation,
      email: formData.email,
    };
    const response = await configAxios.post(URL.validateTokenURL, data, {
      // The email is a query param because works like tenant
      params: data,
    });
    dispatch({
      type: PROCESS_VALIDATE_PASSWORD,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_VALIDATE_PASSWORD_ERROR);
  }
};

export const sendPasswordRecoveryEmail = (formData) => async (dispatch, getState) => {
  dispatch({
    type: PROCESS_PASSWORD_RECOVERY_LOADING,
  });

  try {
    const response = await configAxios.post(URL.requestPasswordReset, formData, {
      // The email is a query param because works like as tenant
      params: { email: formData.email },
    });
    dispatch({
      type: PROCESS_PASSWORD_RECOVERY,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_PASSWORD_RECOVERY_LOADING);
  }
};

export const createPassword = (formData) => async (dispatch, getState) => {
  dispatch({
    type: PROCESS_CREATE_PASSWORD_LOADING,
  });

  try {
    const response = await configAxios.patch(URL.passwordCreationURL, { v1_user: formData }, {
      // The email is a query param because works like tenant
      params: { email: formData.email },
    });
    dispatch({
      type: PROCESS_CREATE_PASSWORD,
      // we need to add a default message to the response if the backend doesn't provide it
      payload: response.data || { message: "OK" },
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_CREATE_PASSWORD_ERROR);
  }
};
