//  NOTE: all commented code will be used as the refactor progresses

import axios from "axios";
import i18n from "i18next";
// import {
//   // TOKEN_KEY, TOKEN_STRING,
// } from "../config";
import {
  API_URL_RAILS_V1, LOCAL_STORAGE_NAMES, LANGUAGES,
} from "common/constants";
import { getItemFromLocalStorage, getEmployeeCompanyId } from "common/utils";

// const TOKEN = localStorage.getItem(TOKEN_KEY);
const baseURL = API_URL_RAILS_V1; //  FIXME: we should use a proxy

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// if (TOKEN) {
//   instance.defaults.headers.common.Authorization = TOKEN_STRING + TOKEN;
// } else {
instance.interceptors.request.use((config) => {
  const companyId = getEmployeeCompanyId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user)); // FIXME: remove this after TOKEN implementation
  const { params } = config;
  const locale = i18n.language === LANGUAGES.pr ? LANGUAGES.pt : i18n.language;

  const formattedConfig = {
    ...config,
    params: {
      ...params,
      company_id: companyId, // this should be deleted when token is implemented
      locale,
    },
  };
  return formattedConfig;
});
// }

// export const updateToken = (token) => {
//   instance.defaults.headers.common.Authorization = TOKEN_STRING + token;
//   instance.interceptors.request.use((config) => {
//     return config;
//   });
// };

export default instance;
