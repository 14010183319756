import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
import { isAdmin as isAdminFunction } from "common/utils";
import { HEADERS } from "common/constants/timeOff";
import Table from "components/Table";
import { SessionContext } from "modules/session/context";
import DinamicModals from "views/TimeOff/components/DinamicModals";
import getRows from "./functions/getRows";

const TableTimeOff = (props) => {
  const {
    data, isLoading, pageFilter, setTableSort, pageHandler,
  } = props;
  const { t } = useTranslation(["timeOff"]);

  const [selectedId, setSelectedId] = useState();
  const [selectedModal, setSelectedModal] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(false);
  const handleModal = (state, id) => {
    setIsOpen(true);
    setSelectedId(id);
    setSelectedModal(state);
  };

  const {
    state: { user },
  } = useContext(SessionContext);

  const isAdmin = isAdminFunction(user?.roles);

  const headers = [
    { title: HEADERS.name, sortable: true, isVisible: true },
    { title: HEADERS.manager, sortable: true, isVisible: isAdmin },
    { title: HEADERS.period, sortable: true, isVisible: true },
    { title: HEADERS.type, sortable: true, isVisible: true },
    { title: HEADERS.motive, sortable: true, isVisible: true },
    { title: HEADERS.state, sortable: true, isVisible: true },
    { title: HEADERS.documents, isVisible: true },
    { title: HEADERS.actions, isVisible: true },
  ];
  const rows = getRows(data, isAdmin, t, handleModal);

  const handleSort = async (title, direction) => {
    setTableSort({
      title,
      direction,
    });
  };

  return (
    <>
      <Table
        headers={ headers }
        isAdmin={ isAdmin }
        data={ data }
        rows={ rows }
        isLoading={ isLoading }
        sortAction={ handleSort }
        pagination={ {
          page: pageFilter.number, pageSize: pageFilter.size, handlePageChange: pageHandler, count: -1,
        } }
      />
      <Modal
        isOpen={ isOpen }
        onClose={ handleOpen }
      >
        <DinamicModals
          state={ selectedModal }
          itemId={ selectedId }
          onClose={ handleOpen }
        />
      </Modal>
    </>
  );
};

export default TableTimeOff;
