import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  GET_LIST_TIME_OFF,
  GET_LIST_TIME_OFF_ERROR,
  GET_LIST_TIME_OFF_LOADING,
  RESET_TIME_OFF,
  RESET_STATE_EXCEL,
  GET_LIST_EXCEL,
  GET_LIST_EXCEL_LOADING,
  GET_LIST_EXCEL_ERROR,
  PROCESS_TIME_OFF,
  PROCESS_TIME_OFF_ERROR,
  PROCESS_TIME_OFF_LOADING,
} from "../actionTypes/timeOff";

const INITIAL_STATE = {
  timeOffList: null,
  timeOffLoadingList: false,
  timeOffErrorList: "",
  list: null,
  loadingList: false,
  errorList: "",
  excelList: null,
  loadingExcelList: false,
  errorExcelList: "",
  loadingProcess: false,
  sucessProcess: null,
  errorProcess: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_TIME_OFF:
    return {
      ...state,
      timeOffLoadingList: false,
      timeOffErrorList: null,
      timeOffList: null,
    };
  case GET_LIST_TIME_OFF:
    return {
      ...state,
      timeOffList: action.payload,
      timeOffLoadingList: false,
      timeOffErrorList: "",
    };
  case GET_LIST_TIME_OFF_LOADING:
    return {
      ...state,
      timeOffList: null,
      timeOffLoadingList: true,
      timeOffErrorList: "",
    };
  case GET_LIST_TIME_OFF_ERROR:
    return {
      ...state,
      timeOffLoadingList: false,
      timeOffErrorList: action.payload,
    };
  case RESET_STATE:
    return {
      ...state,
      loadingList: false,
      errorList: null,
      list: null,
    };
  case GET_LIST:
    return {
      ...state,
      list: action.payload,
      loadingList: false,
      errorList: "",
    };
  case GET_LIST_LOADING:
    return {
      ...state,
      list: null,
      loadingList: true,
      errorList: "",
    };
  case GET_LIST_ERROR:
    return {
      ...state,
      loadingList: false,
      errorList: action.payload,
    };
    case RESET_STATE_EXCEL:
      return {
        ...state,
        loadingExcelList: false,
        errorExcelList: null,
        excelList: null,
      };
    case GET_LIST_EXCEL:
      return {
        ...state,
        excelList: action.payload,
        loadingExcelList: false,
        errorExcelList: "",
      };
    case GET_LIST_EXCEL_LOADING:
      return {
        ...state,
        excelList: null,
        loadingExcelList: true,
        errorExcelList: "",
      };
    case GET_LIST_EXCEL_ERROR:
      return {
        ...state,
        loadingExcelList: false,
        errorExcelList: action.payload,
      };
  
  case PROCESS_TIME_OFF_LOADING:
    return {
      ...state,
      loadingProcess: true,
      sucessProcess: null,
      errorProcess: null,
    };
  case PROCESS_TIME_OFF:
    return {
      ...state,
      loadingProcess: false,
      sucessProcess: action.payload,
    };
  case PROCESS_TIME_OFF_ERROR:
    return {
      ...state,
      loadingProcess: false,
      errorProcess: action.payload,
    };
  default:
    return state;
  }
};
