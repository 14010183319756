import React from "react";
import PropTypes from "prop-types";
import { getInitials } from "./functions/getInitials";
import * as Styled from "./styles";

const CustomAvatar = (props) => {
  const {
    isVisibleBadge,
    avatarImg,
    name,
    position,
  } = props;
  return (
    <Styled.AvatarCell>
      <Styled.StyledBadge
        anchorOrigin={ {
          vertical: "bottom",
          horizontal: "right",
        } }
        color={ "error" }
        overlap={ "circle" }
        variant={ "dot" }
        invisible={ isVisibleBadge }
      >
        <Styled.StyledAvatar
          src={ avatarImg }
          name={ name }
        >
          { avatarImg ? "" : getInitials(name)}
        </Styled.StyledAvatar>
      </Styled.StyledBadge>
      <Styled.Labels>
        <Styled.Bold>{name}</Styled.Bold>
        <p>{position}</p>
      </Styled.Labels>
    </Styled.AvatarCell>
  );
};

CustomAvatar.propTypes = {
  isVisibleBadge: PropTypes.bool.isRequired,
  avatarImg: PropTypes.string,
  name: PropTypes.string.isRequired,
  position: PropTypes.string,
};

export default CustomAvatar;
