import React from "react";
import PropTypes from "prop-types";
import PrivateRoute from "../../shared/auth/PrivateRoute";

const PrivateRouteWithLayout = function (props) {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
	<PrivateRoute
	{ ...rest }
	component={ (matchProps) => (
	<Layout>
	<Component { ...matchProps } />
        </Layout>
      ) }
    />
  );
};

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default PrivateRouteWithLayout;
