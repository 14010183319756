import {
  LOAD_SURVEY_PROCESSES_START,
  LOAD_SURVEY_PROCESSES_SUCCESS,
  LOAD_SURVEY_PROCESSES_FAILURE,
  LOAD_SURVEY_PROCESSES_EVALUATION_SUCCESS,
} from "../actions/types";

let initialState = {
  surveyProcesses: null,
  surveyProcess: null,
  isLoading: false,
  error: null,
};

const surveyProcessesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SURVEY_PROCESSES_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_SURVEY_PROCESSES_SUCCESS:
      return {
        ...state,
        surveyProcesses: action.payload,
        isLoading: false,
      };
    case LOAD_SURVEY_PROCESSES_EVALUATION_SUCCESS:
      return {
        ...state,
        surveyProcess: action.payload,
        isLoading: false,
      };
    case LOAD_SURVEY_PROCESSES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default surveyProcessesReducer;
