import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import _ from "lodash";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Button from "components/Button";
import useStyles from "./styles";

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  const isActiveClass = (href) => {
    const search = window.location.search;

    if (search) {
      if (
        _.includes(search, "collaborator") &&
        _.isEqual(href, "/collaborators")
      )
        return true;
      else if (
        _.includes(search, "candidate") &&
        _.isEqual(href, "/onboarding-candidates")
      )
        return true;
    } else return _.isEqual(window.location.pathname, href);
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page) => (
        <div key={page.title}>
          {page.divider && <Divider className={classes.divider} />}
          <ListItem className={classes.item} disableGutters>
            <Button
              customStyle={clsx(
                classes.button,
                isActiveClass(page.href) && classes.active
              )}
              component={CustomRouterLink}
              href={page.href}
            >
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          </ListItem>
        </div>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
