import { GANT_COLOR, GANT_LETTER_COLOR } from "theme/palette";
import { STATES } from "common/constants/timeOff";

const STATES_BACKGROUND = {
  pending: GANT_COLOR.pending,
  rejected: GANT_COLOR.rejected,
  vacations: GANT_COLOR.vacations,
  medical_license: GANT_COLOR.medical_license,
  permission: GANT_COLOR.permission,
};

const LETTER_STATES = {
  pending: GANT_LETTER_COLOR.letter_pending,
  rejected: GANT_LETTER_COLOR.letter_rejected,
  vacations: GANT_LETTER_COLOR.letter_vacations,
  medical_license: GANT_LETTER_COLOR.letter_medical_license,
  permission: GANT_LETTER_COLOR.letter_permission,
};

const getGantColor = (state, timeOffType, isLetter) => {
  if (state === STATES.pending || state === STATES.rejected) {
    return isLetter ? LETTER_STATES[state] : STATES_BACKGROUND[state];
  }
  return isLetter ? LETTER_STATES[timeOffType] : STATES_BACKGROUND[timeOffType];
};

export default getGantColor;
