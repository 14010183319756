import React from "react";
import PropTypes from "prop-types";
import Timeline from "react-calendar-timeline";
import moment from "moment";
import SkeletonLoader from "components/SkeletonLoader";
import { Container } from "./styles";
import "react-calendar-timeline/lib/Timeline.css";

const Gant = (props) => {
  const { isLoading, groups, itemRenderer, items, onItemSelect} = props;
  
  return (
    <Container >
      {isLoading ? <SkeletonLoader />
        : (
          <Timeline
          onItemSelect={onItemSelect}
            itemRenderer={itemRenderer} 
            groups={ groups }
            items={ items }
            defaultTimeStart={ moment().add(-12, "day") }
            defaultTimeEnd={ moment().add(12, "day") }
          />
        )}
    </Container>
  );
};

Gant.propTypes = {
  groups: PropTypes.array,
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default Gant;