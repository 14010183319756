export const RESET_STATE = "RESET_STATE_COLLABORATORS";
export const GET_LIST = "GET_LIST_COLLABORATORS";
export const GET_MAIN_LIST = "GET_MAIN_LIST_COLLABORATORS";
export const GET_LIST_LOADING = "GET_LIST_LOADING_COLLABORATORS";
export const GET_LIST_ERROR = "GET_LIST_ERROR_COLLABORATORS";

export const RESET_STATE_COLLABORATOR = "RESET_STATE_COLLABORATOR";
export const GET_ONE = "GET_ONE_COLLABORATOR";
export const GET_ONE_LOADING = "GET_ONE_LOADING_COLLABORATOR";
export const GET_ONE_ERROR = "GET_ONE_ERROR_COLLABORATOR";

export const RESET_STATE_ORG_CHART = "RESET_STATE_ORG_CHART";
export const GET_LIST_ORG_CHART = "GET_LIST_ORG_CHART_COLLABORATOR";
export const GET_LIST_ORG_CHART_LOADING = "GET_LIST_ORG_CHART_LOADING_COLLABORATOR";
export const GET_LIST_ORG_CHART_ERROR = "GET_LIST_ORG_CHART_ERROR_COLLABORATOR";
