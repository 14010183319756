import styled from "styled-components";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  height: 70px;
`;

export const StyledContent = styled.div`
  display: block;
  line-height: 20px;
  margin-left: 10px;
  white-space: normal;
  p{
    margin: 0;
    color: #4F4F4F;
    font-size: 12px;
  }

  strong {
    color: #333333;
    font-size: 14px;
  }
`;

export const StyledBadge = styled(Badge)`
  margin-right: 7px;
  height: fit-content;
`;

export const CustomAvatar = styled(Avatar)`
margin-left: 8px;
`;
