import {
  LOAD_SURVEY_RESULTS_START,
  LOAD_SURVEY_RESULTS_SUCCESS,
  LOAD_SURVEY_RESULTS_FAILURE,
  LOAD_SURVEY_DETAILS_START,
  LOAD_SURVEY_DETAILS_SUCCESS,
  LOAD_SURVEY_DETAILS_FAILURE,
} from "../actions/types";

let initialState = {
  surveyResults: [],
  surveyDetails: {},
  isLoading: false,
  error: null,
};

const surveyResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SURVEY_RESULTS_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_SURVEY_RESULTS_SUCCESS:
      return {
        ...state,
        surveyResults: action.payload,
        isLoading: false,
      };
    case LOAD_SURVEY_RESULTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case LOAD_SURVEY_DETAILS_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_SURVEY_DETAILS_SUCCESS:
      return {
        ...state,
        surveyDetails: action.payload,
        isLoading: false,
      };
    case LOAD_SURVEY_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default surveyResultsReducer;
