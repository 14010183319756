import {
  LOAD_POTENTIAL_PROCESS_START,
  LOAD_POTENTIAL_PROCESS_SUCCESS,
  LOAD_POTENTIAL_PROCESS_FAILURE,
} from "actions/types";

let initialState = {
  potentialProcess: [],
  isLoading: false,
  error: null,
};

const potentialReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_POTENTIAL_PROCESS_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_POTENTIAL_PROCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        potentialProcess: action.payload,
      };
    case LOAD_POTENTIAL_PROCESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default potentialReducer;
