import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_STATE_NINEBOX_RESULT,
  GET_NINEBOX_RESULTS,
  GET_NINEBOX_RESULTS_LOADING,
  GET_NINEBOX_RESULTS_ERROR,
} from "../actionTypes/ninebox";

const INITIAL_STATE = {
  nineboxes: null,
  nineboxResults: null,
  loadingNinebox: false,
  loadingNineboxResults: false,
  errorList: "",
  errorNineboxResult: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      loadingNinebox: false,
      errorList: null,
      nineboxes: null,
    };
  case GET_LIST:
    return {
      ...state,
      nineboxes: action.payload,
      loadingNinebox: false,
      errorList: "",
    };
  case GET_LIST_LOADING:
    return {
      ...state,
      nineboxes: [],
      loadingNinebox: true,
      errorList: "",
    };
  case GET_LIST_ERROR:
    return {
      ...state,
      loadingNinebox: false,
      errorList: action.payload,
    };
  case RESET_STATE_NINEBOX_RESULT:
    return {
      ...state,
      loadingNineboxResults: false,
      errorNineboxResult: null,
      nineboxResults: null,
    };
  case GET_NINEBOX_RESULTS:
    return {
      ...state,
      nineboxResults: action.payload,
      loadingNineboxResults: false,
      errorNineboxResult: "",
    };
  case GET_NINEBOX_RESULTS_LOADING:
    return {
      ...state,
      nineboxResults: null,
      loadingNineboxResults: true,
      errorNineboxResult: "",
    };
  case GET_NINEBOX_RESULTS_ERROR:
    return {
      ...state,
      loadingNineboxResults: false,
      errorNineboxResult: action.payload,
    };
  default:
    return state;
  }
};
