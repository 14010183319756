import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getOne as getUser, resetState } from "redux/actions/signInActions";
import { showToast } from "views/SignIn/functions";
import {
  GET_BOTTOM_RANKING_SUCCESS,
  GET_CANDIDATE_SUCCESS,
  GET_COLLABORATOR_SUCCESS,
  GET_NINEBOX_SUCCESS,
  GET_PERFORMANCE_SURVEY_RESULT_SUCCESS,
  GET_PERFORMANCE_SURVEY_RESULTS_SUCCESS,
  GET_TOP_RANKING_SUCCESS, LOAD_AUTH_CHANGE_PASSWORD_SUCCESS,
  LOAD_AUTH_EMAIL_RECOVER_PASSWORD_SUCCESS,
  LOAD_AUTH_RECOVER_PASSWORD_SUCCESS,
  LOAD_AUTH_SUCCESS, LOAD_AUTH_VALIDATE_TOKEN_SUCCESS,
  LOAD_CANDIDATE_SUCCESS,
  LOAD_CITY_SUCCESS,
  LOAD_COLLABORATOR_SUCCESS,
  LOAD_COUNTRY_SUCCESS,
  LOAD_DOCUMENT_SUCCESS,
  LOAD_INFO_TYPES_SUCCESS,
  LOAD_NINEBOX_SUCCESS, LOAD_ORGANIZATION_CHART_SUCCESS,
  LOAD_ORGUNIT_SUCCESS,
  LOAD_ORGUNIT_TYPES_SUCCESS,
  LOAD_PERFORMANCE_PROCESS_SUCCESS,
  LOAD_POTENTIAL_PROCESS_SUCCESS,
  LOAD_SURVEY_RESULTS_SUCCESS,
  LOAD_TYPE_OF_CONTRACT_SUCCESS,
  LOAD_USERNAME_SUCCESS,
  LOAD_EMPLOYMENT_RELATIONSHIP_SUCCESS,
  LOAD_POSITIONS_SUCCESS,
  LOAD_CURRENCY_SUCCESS,
} from "actions/types";

const useSignInService = (props = {}) => {
  const { location, authMethod } = props;
  const dispatch = useDispatch();
  const {
    one: resultSignIn, errorList, isLoading,
  } = useSelector(({ signInReducer }) => signInReducer);
  const { t } = useTranslation(["authentication", "common"]);

  // States
  const [formData, setFormData] = useState(null);

  // Props management
  const stateLocation = location?.state;
  const emailToRecoverPassword = stateLocation?.emailToRecoverPassword;

  // This the basic post call to sign in
  useEffect(() => {
    if (formData) {
      dispatch(getUser(formData));
      setFormData(null);
    }
  }, [dispatch, formData]);

  // When the result is an error we need to show toast with the result
  useEffect(() => {
    if (errorList && authMethod) {
      showToast(errorList, authMethod, emailToRecoverPassword, t);
      dispatch(resetState());
    }
  }, [errorList, authMethod, t, emailToRecoverPassword, dispatch]);

  const logout = () => {
    // TODO: Migrate this to the new way
    dispatch({ type: LOAD_AUTH_SUCCESS, payload: {} });
    dispatch({ type: LOAD_COLLABORATOR_SUCCESS, payload: {} });
    dispatch({ type: GET_COLLABORATOR_SUCCESS, payload: {} });
    dispatch({ type: LOAD_CANDIDATE_SUCCESS, payload: [] });
    dispatch({ type: GET_CANDIDATE_SUCCESS, payload: {} });
    dispatch({ type: LOAD_DOCUMENT_SUCCESS, payload: {} });
    dispatch({ type: LOAD_INFO_TYPES_SUCCESS, payload: {} });
    dispatch({ type: LOAD_ORGUNIT_SUCCESS, payload: null });
    dispatch({ type: LOAD_ORGUNIT_TYPES_SUCCESS, payload: null });
    dispatch({ type: LOAD_CITY_SUCCESS, payload: null });
    dispatch({ type: LOAD_USERNAME_SUCCESS, payload: null });
    dispatch({ type: LOAD_COUNTRY_SUCCESS, payload: null });
    dispatch({ type: LOAD_PERFORMANCE_PROCESS_SUCCESS, payload: null });
    dispatch({ type: GET_PERFORMANCE_SURVEY_RESULTS_SUCCESS, payload: null });
    dispatch({ type: GET_PERFORMANCE_SURVEY_RESULT_SUCCESS, payload: null });
    dispatch({ type: GET_TOP_RANKING_SUCCESS, payload: [] });
    dispatch({ type: GET_BOTTOM_RANKING_SUCCESS, payload: [] });
    dispatch({ type: LOAD_POTENTIAL_PROCESS_SUCCESS, payload: [] });
    dispatch({ type: LOAD_NINEBOX_SUCCESS, payload: [] });
    dispatch({ type: GET_NINEBOX_SUCCESS, payload: {} });
    dispatch({ type: LOAD_TYPE_OF_CONTRACT_SUCCESS, payload: null });
    dispatch({ type: LOAD_SURVEY_RESULTS_SUCCESS, payload: [] });
    dispatch({ type: LOAD_AUTH_EMAIL_RECOVER_PASSWORD_SUCCESS, payload: null });
    dispatch({ type: LOAD_AUTH_RECOVER_PASSWORD_SUCCESS, payload: null });
    dispatch({ type: LOAD_AUTH_CHANGE_PASSWORD_SUCCESS, payload: null });
    dispatch({ type: LOAD_AUTH_VALIDATE_TOKEN_SUCCESS, payload: {} });
    dispatch({ type: LOAD_ORGANIZATION_CHART_SUCCESS, payload: [] });
    dispatch({ type: LOAD_EMPLOYMENT_RELATIONSHIP_SUCCESS, payload: null });
    dispatch({ type: LOAD_POSITIONS_SUCCESS, payload: null });
    dispatch({ type: LOAD_CURRENCY_SUCCESS, payload: null });
    dispatch(resetState());
  };

  return {
    userResult: resultSignIn,
    errorList,
    isLoading,
    signInPost: setFormData,
    logout,
  };
};

export default useSignInService;
