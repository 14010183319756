import React, { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Edit from "@material-ui/icons/Edit";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Search from "@material-ui/icons/Search";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";

const getURLFirebase = () => {
  switch (process.env.NODE_ENV) {
  case "production":
    return "https://us-central1-nala-system.cloudfunctions.net";
  case "development":
    return "http://localhost:5001/nala-system/us-central1";
  default:
    return "https://us-central1-nala-system.cloudfunctions.net";
  }
};

const getURLRails = () => {
  switch (process.env.REACT_APP_ENV) {
  case "production":
    return "https://api.nalarocks.com";
  case "development":
    return "https://staging.api.nalarocks.com";
  default:
    return "https://staging.api.nalarocks.com";
  }
};

export const YOUTUBE_URLS = {
  main: "https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=",
  format: "&format=json",
  external: "https://youtu.be/",
};

export const FUNCTIONS_API_URL_BASE = getURLFirebase();
export const API_URL_BASE_RAILS = getURLRails();
export const API_V1 = "/api/v1";
export const API_URL_RAILS_V1 = API_URL_BASE_RAILS + API_V1;

export const headersWithEmail = (email) => ({
  "x-email": email,
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
});

export const ROLES = {
  CANDIDATE: "candidate",
  COLLABORATOR: "collaborator",
  MANAGER: "manager",
  ADMIN: "admin",
  ADMIN_NALA: "nala_admin",
  ADMIN_COLOMBIA: "colombia_admin",
};

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox { ...props } ref={ ref } />),
  Check: forwardRef((props, ref) => <Check { ...props } ref={ ref } />),
  Clear: forwardRef((props, ref) => <Clear { ...props } ref={ ref } />),
  Delete: forwardRef((props, ref) => <DeleteOutline { ...props } ref={ ref } />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight { ...props } ref={ ref } />
  )),
  Edit: forwardRef((props, ref) => <Edit { ...props } ref={ ref } />),
  Export: forwardRef((props, ref) => <SaveAlt { ...props } ref={ ref } />),
  Filter: forwardRef((props, ref) => <FilterList { ...props } ref={ ref } />),
  FirstPage: forwardRef((props, ref) => <FirstPage { ...props } ref={ ref } />),
  LastPage: forwardRef((props, ref) => <LastPage { ...props } ref={ ref } />),
  NextPage: forwardRef((props, ref) => <ChevronRight { ...props } ref={ ref } />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft { ...props } ref={ ref } />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear { ...props } ref={ ref } />),
  Search: forwardRef((props, ref) => <Search { ...props } ref={ ref } />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward { ...props } ref={ ref } />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove { ...props } ref={ ref } />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn { ...props } ref={ ref } />),
};

export const BASE_URL_HR_ANALYTICS = "https://app.powerbi.com/view?r=";
export const IFRAME_URL_HR_ANALYTICS = {
  blu: // updated at 11/06/2021
    "eyJrIjoiMjgwNjJjYzYtOGZkZC00ZGMzLWE2YWYtODUzODg5Mjc2OGMxIiwidCI6ImI3OGViZjQ2LWVjNjQtNGU0Mi1iZWY5LWMxODUwMDNhOGNmZSJ9",
  haulmer:
    "eyJrIjoiNjE3MjViN2UtZjc0MS00MDVhLWFiYmEtYjk1MWNmNDNjNGVjIiwidCI6IjNmMTNmM2FmLThlN2EtNDE3Yi05ZTdkLWY4YjlhMjBiYzFkMiIsImMiOjJ9",
  denda: // updated at 11/06/2021
    "eyJrIjoiZjA4YTNhZmUtMzMzNS00MGJmLTliNmEtYTkwZmZjNjJiMzRhIiwidCI6ImI3OGViZjQ2LWVjNjQtNGU0Mi1iZWY5LWMxODUwMDNhOGNmZSJ9",
  nala_demo: // updated at 11/06/2021
    "eyJrIjoiMjlhOWU2OTMtYTFjNi00NGFhLWEzOTUtYzZkNGY2ZGZlZGQyIiwidCI6ImI3OGViZjQ2LWVjNjQtNGU0Mi1iZWY5LWMxODUwMDNhOGNmZSJ9",
  tecno_diagnostica: // updated at 11/06/2021
    "eyJrIjoiZmE5MzdmODMtZDIxNi00ZmQ0LTk4MDItMjQ3YjExYzFlNzkyIiwidCI6ImI3OGViZjQ2LWVjNjQtNGU0Mi1iZWY5LWMxODUwMDNhOGNmZSJ9",
  comunidad_feliz: // updated at 11/06/2021
    "eyJrIjoiODkxOGVlYjMtZTViMy00NWQ0LWI5NmQtOGQ1ZGQ3NzRkZTBiIiwidCI6ImI3OGViZjQ2LWVjNjQtNGU0Mi1iZWY5LWMxODUwMDNhOGNmZSJ9",
  dutrafer:
    "eyJrIjoiZjcxMjU2OGUtMGRlNC00NzBkLTg4OWItYmU2YjJkYzAwYWE1IiwidCI6IjNmMTNmM2FmLThlN2EtNDE3Yi05ZTdkLWY4YjlhMjBiYzFkMiIsImMiOjJ9&pageName=ReportSection140bb93460a524aba7c2",
  aprende: // updated at 11/06/2021
    "eyJrIjoiM2FkNjY2YjEtMTA4NC00YzU4LThjMWUtODNjMDBjOGUxMzBhIiwidCI6ImI3OGViZjQ2LWVjNjQtNGU0Mi1iZWY5LWMxODUwMDNhOGNmZSJ9",
  aprende_managers: // updated at 11/06/2021
    "eyJrIjoiOGZhMTAwNzUtMWIzYy00Y2RlLWFiMGQtZDE2ZDM3ZDVmN2Y3IiwidCI6ImI3OGViZjQ2LWVjNjQtNGU0Mi1iZWY5LWMxODUwMDNhOGNmZSJ9",
  trebleAi: // updated at 11/06/2021
    "eyJrIjoiOTk3NTJhODItMTNlZi00ZGFjLTg1ZTItN2QzNDE2YmRhZjBlIiwidCI6ImI3OGViZjQ2LWVjNjQtNGU0Mi1iZWY5LWMxODUwMDNhOGNmZSJ9",
  dlds: // updated at 15/06/2021
    "eyJrIjoiNGVjZjg4YzgtNzE1Ni00MzI5LTgwYjItZGI0OGNkYTM0ZmJhIiwidCI6ImI3OGViZjQ2LWVjNjQtNGU0Mi1iZWY5LWMxODUwMDNhOGNmZSJ9",
  sugo: // updated at 22/06/2021
    "eyJrIjoiMmQxNGRmN2MtOGRkYi00YmVlLTkyNzUtOTVkMTllNmU3YzBlIiwidCI6ImI3OGViZjQ2LWVjNjQtNGU0Mi1iZWY5LWMxODUwMDNhOGNmZSJ9&pageName=ReportSection0db4bfcb85d9ca067570",
};

export const HC_PLANNING_CALCULATOR_IFRAME = `${BASE_URL_HR_ANALYTICS}eyJrIjoiZDU2OWEyZjgtOTIyMy00YmRmLWJjY2ItNmZjNjkxYWI3NjlhIiwidCI6IjNmMTNmM2FmLThlN2EtNDE3Yi05ZTdkLWY4YjlhMjBiYzFkMiIsImMiOjJ9&pageName=ReportSection145e6eb0b8b1959c5dba`;

export const FIRST_PAGE = "first_page";
export const PREV_PAGE = "prev_page";
export const NEXT_PAGE = "next_page";
export const LAST_PAGE = "last_page";

export const ASC = "asc"; // for ascending
export const DESC = "desc"; // for descending

export const ORDER_BY_NAME = "name";
export const ORDER_BY_UNITY = "unity";

export const PERFORMANCE_MAX = 1.2;

export const RATING = {
  initial: 0,
  stars: 5,
  total_min: 100,
  total_max: 120,
  precision: 0.1,
};

export const INFO = "info";
export const SUCCESS = "success";
export const WARNING = "warning";
export const ERROR = "error";
export const CRITIC = "critic";

// FIXME: remove when receiving scale
export const LEADER_CLASSIFICATION_RANK = {
  EXEMPLARY_LEADER: [0.9, 1],
  GOOD_LEADER: [0.75, 0.89],
  LEADER_IN_DEVELOPMENT: [0.6, 0.74],
  BOX: [0, 0.59],
};

export const HEAT_MAP_LABEL_RANGE = {
  critic: "0% - 49%",
  low: "50% - 69%",
  medium: "70% - 89%",
  normal: "90% - 99%",
  excellent: "100% - 109%",
  great: "110% - 120%",
};
export const HEAT_MAP_VALUE_RANGE = {
  critic: {
    min: 0,
    max: 50, // max used on inRange lodash function wich does not include the max
  },
  low: {
    min: 50,
    max: 70,
  },
  medium: {
    min: 70,
    max: 90,
  },
  normal: {
    min: 90,
    max: 100,
  },
  excellent: {
    min: 100,
    max: 110,
  },
  great: {
    min: 109,
  },
};

export const RISK_RESULT_VALUES = {
  critic: 100,
  high: 80,
  medium: 60,
  low: 40,
  none: 20,
  zero: 0,
  index: {
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    four: 4,
  },
  additionalPadding: 10,
};

export const GRAPHIC_LABEL_OFFSET = 15;
export const GRAPHIC_TOP_PERCENTAGE = 100;

export const MAX_PER_PAGE = 15;
export const ROWS_PER_PAGE = [15, 30, 45];
export const TIMEOUT_TRANSITION = 350;

export const AUTO_HIDE_DURATION = 8000;
export const ALERT_ANCHOR_ORIGIN = {
  vertical: "top",
  horizontal: "right",
};

export const STATE = {
  SELECTED: "selected",
  FILL_DATA: "fill_data",
  DATA_FILLED: "data_filled",
  REQUIRE_MORE_DATA: "require_more_data",
  DATA_REQUIRED: "data_required",
  HIRE: "hire",
  HIRED: "hired",
  DESIST: "desist",
  DESISTED: "desisted",
  //  FIXME: change all this constants above to lowercase
  turnover: "Turnover",
};

export const MARITAL_STATUS = {
  SINGLE: "common.marital_status.single",
  MARRIED: "common.marital_status.married",
  FREE_UNION: "common.marital_status.free_union",
  SEPARATED: "common.marital_status.separated",
  DIVORCED: "common.marital_status.divorced",
  WIDOWER: "common.marital_status.widower",
};

export const ROLES_NAMES = {
  collaborator: "common:common.roles.collaborator",
  manager: "common:common.roles.manager",
  admin: "common:common.roles.admin",
  colombia_admin: "common:common.roles.colombia_admin",
};

export const DATA_URL_KEY = "dataURLKey"; // for UploadImage component

export const NINEBOX = {
  generalViewBox: "0 0 100 100",
  footerViewBox: "0 0 100 3",
  iconViewBox: "0 0 10 10",
  iconX: 24,
  iconY: 2.5,
  iconWidth: 10,
  maxBox: 33.333333333333336,
  xMaxBox: 66.66666666666667,
  zero: 0.0,
  box: {
    a: {
      x: 2,
      y: 3.5,
    },
    b: {
      x: 35.5,
    },
    c: {
      x: 69,
    },
    d: {
      y: 37,
    },
    g: {
      y: 70.5,
    },
  },
  footerBox: {
    xa: 12,
    xb: 45,
    xc: 80,
  },
  one: 1,
  total: 100,
  totalPercentage: "100%",
  quadrantBorder: 0.5,
  quadrantData: {
    x: 0,
    y: 1,
    width: 2,
    fill: 3,
    name: 4,
    xText: 5,
    yText: 6,
    description: 7,
    xMin: 8,
    xMax: 9,
    yMin: 10,
    yMax: 11,
    xPercentage: 12,
    yPercentage: 13,
    quadrant: 14,
  },
  quadrantIndex: {
    zero: 0,
    first: 1,
    second: 2,
    third: 3,
    fourth: 4,
    fifth: 5,
    sixth: 6,
    seventh: 7,
    eighth: 8,
    all: 9,
  },
  footerData: {
    x: 0,
    xText: 1,
  },
  boxesGroup: {
    narrow: 0,
    balance: 1,
    broad: 2,
  },
  boxRange: {
    xBox: {
      low: {
        min: 0,
        max: 54,
      },
      medium: {
        min: 55,
        max: 84,
      },
      high: {
        min: 85,
        max: 100,
      },
    },
    yBox: {
      low: {
        min: 0,
        max: 75,
      },
      medium: {
        min: 75.1,
        max: 87.5,
      },
      high: {
        min: 87.51,
        max: 100,
      },
    },
  },
  tooltip: {
    rect: {
      x: -18,
      y: -2.8,
      width: 3,
      height: 3.5,
      rx: 0.5,
      ry: 0.5,
    },
    text: {
      x: -16,
      y: -0.5,
    },
  },
  focus: {
    position: 3.5,
    width: 6.5,
  },
  group: {
    max: 9,
    min: 2,
    initial: 1,
    zero: 0,
  },
  results: {
    potential: "potentialResult",
    performance: "performanceResult",
  },
  radius: {
    max: 3,
  },
  image: {
    id: "person",
    aspectRatio: "none",
  },
  round: {
    min: 0,
    max: 2,
  },
};

export const XLSX_EXTENSION = "xlsx";
export const FILE_TYPE_EXCEL = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

export const ERROR_VALUE = 500;

export const CLIENT_ID_GOOGLE = "508350423051-gjvhm8k25okgohned728bq50qrvob1j3";

export const AUTH_NETWORK_FAILED_FIREBASE = "auth/network-request-failed";
export const CLOSED_POPUP_FAILED_FIREBASE = "auth/cancelled-popup-request";
export const CLOSED_POPUP_USER_FIREBASE = "auth/popup-closed-by-user";

export const PROFILE_INDEX_TABS = {
  profile: 0,
  personalData: 1,
  salary: 2,
  performance: 3,
  induction_potential: 4,
  documents: 5,
  feedback: 6,
  lossRisk: 7,
};

export const COLLABORATOR_INDEX_TABS = {
  active: 0,
  inactive: 1,
};

export const REMOVE_OPTION = "remove-option";
export const CLEAR = "clear";

export const SKELETONS_NUMBER = {
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  EIGTH: 8,
  NINE: 9,
};

export const TIMEOUT_REDIRECT = 2000;
export const TIMEOUT_DISPATCH = 1000;

export const COMPANY_SLUGS = {
  blu: "blu",
  haulmer: "haulmer",
  denda: "denda",
  nala: "nala",
  nala_demo: "nala-demo",
  tecno_diagnostica: "tecno-diagnostica",
  comunidad_feliz: "comunidad-feliz",
  dutrafer: "dutrafer",
  aprende: "aprende",
  trebleAi: "treble-ai",
  dlds: "dlds",
  leal: "leal",
  onelink: "onelink",
  sugo: "sugo",
  everis: "everis",
};

export const COMPANIES_WITH_HR_ANALYTICS_BETA = [
  COMPANY_SLUGS.blu,
  COMPANY_SLUGS.haulmer,
  COMPANY_SLUGS.denda,
  COMPANY_SLUGS.nala_demo,
  COMPANY_SLUGS.tecno_diagnostica,
  COMPANY_SLUGS.comunidad_feliz,
  COMPANY_SLUGS.dutrafer,
  COMPANY_SLUGS.aprende,
  COMPANY_SLUGS.trebleAi,
  COMPANY_SLUGS.dlds,
  COMPANY_SLUGS.sugo,
];

export const ORG_UNITS = {
  area: "Area",
  division: "Division",
  subarea: "Subarea",
};

export const ENTITY_OPTION = {
  country: "country",
  city: "city",
};

export const OBJECT_KEYS = {
  // A
  actions: "goals_actions",
  area: "area",
  averageCommissions: "average_commissions",
  archive: "archieve",
  // B
  birthDate: "birth_date",
  birthdate: "birthdate",
  // C
  cityName: "city_name",
  countryName: "country_name",
  completedValue: "completed_value",
  completationPercentage: "completation_percentage",
  compliance: "compliance",
  comment: "comment",
  createdAt: "created_at",
  collaboratorsCount: "collaborators_count",
  currency: "currency",
  companyEmail: "company_email",
  country: "country",
  country_id: "country_id",
  city: "city",
  costCenter: "cost_center",
  children: "children",
  code: "code",
  collaborator: "collaborator",
  // D
  date: "date",
  description: "description",
  division: "division",
  directory: "directory",
  dayControl: "day_control",
  dentalInsurance: "dental_insurance",
  downloadImage: "download-image",
  // E
  employeeFullName: "employee_full_name",
  evaluationType: "evaluation_type",
  evaluation: "evaluation",
  end_date: "end_date",
  errors: "errors",
  employmentRelationship: "employment_relationship.name",
  // F
  filename: "filename",
  finishContract: "finishContract",
  fullname: "full_name",
  // G
  grossSalary: "gross_salary",
  goalRuleId: "goal_rule_id",
  gender: "gender",
  // H
  healthInsurance: "health_insurance",
  human_resources: "human_resources",
  // I
  isActive: "is_active",
  id: "id",
  // K
  keepDoing: "keep_doing",
  // L
  lateralMovement: "LateralMovement",
  label: "label",
  level: "level",
  legalEntityCode: "legal_entity_code",
  lifeInsurance: "life_insurance",
  listings: "listings",
  lossRisk: "lossRisk",
  // M
  managerName: "manager_name",
  metric: "metric",
  minWorkedDays: "min_worked_days",
  month: "month",
  managerId: "manager_id",
  mealTicket: "meal_allowance",
  // N
  name: "name",
  nameWithCode: "name_with_code",
  null: "null",
  // O
  organizationUnit: "organization_unit",
  otherBenefits: "other_benefits",
  // P
  promotion: "Promotion",
  profileImgUrl: "profile_img_url",
  positionName: "position_name",
  payrollItemId: "payroll_item_id",
  performance: "performance",
  principalActions: "actions",
  position: "position",
  pairs: "pairs",
  positionId: "position_id",
  // R
  rolesName: "roles_name",
  recommendation: "recomendation",
  revisionDate: "revision_date",
  result: "result",
  reasonOfTermination: "reason_of_termination",
  reminderDays: "reminder_days",
  // S
  salaryRaise: "SalaryRaise",
  score: "score",
  start_date: "start_date",
  startingDate: "starting_date",
  subarea: "subarea",
  salary: "salary",
  surveyProcess: "survey_process",
  stopDoing: "stop_doing",
  // T
  typeOfContractId: "type_of_contract_id",
  total: "total",
  totalGoals: "total_goals",
  title: "title",
  type: "type",
  terminationDate: "termination_date",
  typeOfContract: "type_of_contract",
  // U
  undefined: "undefined",
  userIdCompany: "user_id_company",
  userId: "user_id",
  unit: "unit",
  unarchive: "unarchieve",
  // V
  value: "value",
  // W
  weight: "weight",
  zoomIn: "zoom-in",
  zoomOut: "zoom-out",
};

export const BUTTON_STYLE_TYPES = {
  SUBMIT: "submit",
  CANCEL: "cancel",
  BACK: "back",
  OUTLINED: "outlined",
  DISABLED: "disabled",
  CONTAINED: "contained",
};

export const EVALUATION_CLASSIFICATION_RANK = {
  SUPER_STAR: [85, 100],
  STAR: [70, 84],
  CONSISTENT: [50, 69],
  IN_DEVELOPMENT: [40, 49],
  NOT_CONSISTENT: [0, 39],
};

export const VISIBILITY = {
  COMPACT: "Compact",
  WIDE: "Wide",
};

export const PERFORMANCE_MAX_PERCENTAGE = 100;
export const FORMAT_TEXT = /([a-z])([A-Z])/g;
export const MIN_STAR_RATING = 0;

export const TYPES = {
  string: "string",
  object: "object",
  number: "number",
  email: "email",
  date: "date",
  text: "text",
  hidden: "hidden",
};

export const NUMBER_OF_EVALUATIONS = 4;

export const RADIO_OPTIONS = {
  voluntary: "voluntary",
  involuntary: "involuntary",
};

export const GRID_DIVIDERS = 10;
export const COLORS_NAME = {
  light: "light",
  purple: "purple",
  primary: "primary",
};

export const DATE = "date";

export const LANGUAGES = {
  en: "en",
  es: "es",
  pr: "pr",
  pt: "pt",
};

export const LANGUAGES_NAMES = {
  spanish: "Español",
  english: "English",
  portuguese: "Português",
};

export const TYPES_TALENTS = {
  PEOPLE_AGILITY: {
    name: "Agilidad Interpersonal",
    id: "people_agility",
  },
  MENTAL_AGILITY: { name: "Agilidad Mental", id: "mental_agility" },
  CHANGE_AGILITY: {
    name: "Agilidad para el cambio",
    id: "change_agility",
  },
  RESULTS_AGILITY: {
    name: "Agilidad con los resultados",
    id: "results_agility",
  },
  SELF_AWARENESS: {
    name: "Conciencia de si mismo",
    id: "self_awareness",
  },
};

export const TYPES_RESULTS = {
  deep: "Profundo",
  balanced: "Balanceado",
  large: "Amplio",
  skills: {
    mental: "Agilidad Mental",
    interpersonal: "Agilidad Interpersonal",
    change: "Agilidad para el cambio",
    results: "Agilidad con los resultados",
    awareness: "Conciencia de si mismo",
  },
  skillKeys: {
    mental: "mental",
    interpersonal: "interpersonal",
    change: "change",
    results: "results",
    awareness: "awareness",
  },
  skillProp: {
    talented: "talented",
    develop: "developed",
    toDevelop: "toDevelop",
  },
};

export const DASHBOARD_REDUCER = {
  performance: "performance",
  potential: "potential",
};

export const MIN_VALUE = 0;

export const PATTERN_OBJECT_BOUNDING = "objectBoundingBox";

export const MANAGERS_ANALYTICS_IFRAMES = {
  blu: [
    "aline@useblu.com.br",
    "gustavo.coelho@useblu.com.br",
    "joao.amorim@useblu.com.br",
    "pedro.gomes@useblu.com.br",
    "rafael.sobral@useblu.com.br",
    "bruno@useblu.com.br",
    "luis.marinho@useblu.com.br",
    "andre.alonso@useblu.com.br",
    "fernanda.ventura@useblu.com.br",
    "victor.pinto@useblu.com.br",
  ],
  aprende: [
    // Please don't change the order
    "mclaure@aprende.com",
    "gabriel@aprende.com",
  ],
};

export const SCROLL_BEHAVIOR = {
  smooth: "smooth",
};

export const BORDER_ACCORDION_TYPE = {
  rounded: "rounded",
  square: "square",
};

export const WEEK_DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const CARD_TYPE = {
  evaluation: "evaluation",
  badge: "badge",
};

export const PERSONALITY_EVALUATION = {
  energizes: 0,
  drains: 1,
};

export const EXCEL_FILES_NAME = {
  potentialNineBox: "potential-nine-box",
  leadersReview: "leaders-review",
};

export const FORMAT_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const BULK_UPLOAD_FORMAT = /(.xlsx|.csv)$/i;
export const ACCEPTED_FORMATS = ".xlsx, .csv";

export const BULK_UPLOAD = {
  row_number: "row_number",
  xlsx: {
    type: {
      binary: "binary",
      array: "array",
    },
    col: 1,
    initialCounter: 2,
    firstData: 0,
    dataLimit: 10,
    ref: "!ref",
    maxCol: 29,
  },
  requiredSign: "*",
  state: {
    pending: "pending",
    successfully: "successfully",
    failed: "failed",
  },
  calculateTime: {
    min: 100,
  },
};

export const LINEAR_PROGRESS = {
  min: 0,
  max: 100,
  diff: 10,
  interval: 300,
};

export const PASSWORD_INPUT_LABEL_WIDTH = {
  large: 150,
  medium: 110,
  default: 70,
};
export const INPUT_TYPE_TOGGLE_PASSWORD = {
  password: "password",
  text: "text",
};

export const PASSWORD_VALIDATION_UTILS = {
  length: "length",
  confirmation: "confirmation",
  minLength: 8,
};

export const AUTH_METHODS = {
  password: "password",
  token: "token",
  google: "google",
};

export const ERROR_TYPE = {
  unauthorized: "unauthorized",
};

export const RECOVER_PASSWORD_UTILS = {
  tokenSearchPosition: 2,
  emailSearchPosition: 1,
  maxLength: 16,
  typeErrorMaxLength: "maxLength",
};

export const LOCAL_STORAGE_NAMES = {
  typeOfContract: "typeOfContract",
  managers: "managers",
  countries: "countries",
  cities: "cities",
  organizationUnitsTypes: "organizationUnitsTypes",
  organizationUnits: "organizationUnits",
  currencies: "currencies",
  employmentRelationship: "employmentRelationship",
  positions: "positions",
  user: "user",
};

export const RobotoFont = "Roboto";

export const ENGAGEMENT_DATA = {
  satisfactionThemeHeader: ["V", "O", "I", "C", "E", "-E"],
  searchItems: {
    name: "name",
    nps: "nps",
    participation: "participation",
    previousNps: "previous_nps_result",
    unsatisfiedTotal: "unsatisfied_total",
    neutralTotal: "neutral_total",
    satisfiedTotal: "satisfied_total",
    previousSatisfaction: "previous_satisfaction_result",
    result: "result",
    previousResult: "previous_result",
    id: "id",
  },
  range: {
    unsatisfied: { min: 0, max: 49 },
    unsatisfiedLight: { min: 49, max: 66 },
    neutralLight: { min: 66, max: 75 },
    neutral: { min: 75, max: 81 },
    satisfiedLight: { min: 81, max: 90 },
    satisfied: { min: 90, max: 100 },
  },
};

export const SIZE = {
  small: "small",
  medium: "medium",
};

export const TABLE_SIZE = {
  small: "small",
  medium: "medium",
};

export const TABLE_CELL_ALIGN = {
  left: "left",
};

export const ICON = {
  left: "left",
};

export const VARIANT = {
  button: "button",
  paddingCheckbox: "checkbox",
  none: "none",
  default: "defautl",
  dense: "dense",
  outlined: "outlined",
  determinate: "determinate",
  contained: "contained",
  bodyOne: "body1",
  bodyTwo: "body2",
  subtitleOne: "subtitle1",
  subtitleTwo: "subtitle2",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  normal: "normal",
};

export const CIRCULAR_PROGRESS = {
  large: 120,
  medium: 60,
  small: 20,
};

export const DECIMAL_SCALE_NUMBER_INPUT = 0;
export const DEFAULT_MIN_DECIMAL = 2;

export const FILTER_SEACH = {
  equal: "=",
};

export const FILTERS_KEYS = {
  country: "country",
  countries: "countries",
  city: "city",
  cities: "cities",
  unit: "unit",
  unities: "unities",
  manager: "manager",
  managers: "managers",
  collaborators: "collaborators",
  periods: "periods",
  state: "state",
  states: "states"
};

export const UNDEFINED = undefined;

export const FULLDATE_FORMATS = {
  slash: "DD/MM/YYYY",
  dash: "DD-MM-YYYY",
  slash_calendar: "YYYY/MM/DD",
  dash_calendar: "YYYY-MM-DD"
};

export const FULLDATE_FORMATS_STANDARD = {
  slash: "dd/MM/yyyy",
  dash: "dd-MM-yyyy",
};

export const SORT_COMPARATOR = {
  one: 1,
  minusOne: -1,
  zero: 0,
};

export const GOALS_INDEX_TABS = {
  inProgress: 0,
  archived: 1,
};

export const DESCENDANT = "is_descendant";
export const EMPLOYEES_TO_REMOVE_NUMBER = 1;

export const COLLABORATORS_TABLE_ACTIONS = {
  view: "view",
  edit: "edit",
  promote: "promote",
  lateralMovement: "lateralMovement",
  salaryRaise: "salaryRaise",
};

export const MODE_ON_CHANGE_FORMS = "onChange";

export const ROUND = {
  min: 0,
  max: 2,
};

export const PERCENTAGE = {
  max: 100,
  min: 0,
};

export const MAX_LENGTH_INPUT = {
  currency: 20,
  number: 10,
};

export const MULTILINE = {
  min: 2,
};

export const KEY_PRESS = {
  enter: "Enter",
};

export const PAGINATION = {
  maxPerPage: 15,
  next: 1,
};

export const COMPONENT = {
  fieldset: "fieldset",
  label: "label",
  span: "span",
};

export const INDEX = {
  zero: 0,
  one: 1,
  two: 2,
  three: 3,
};

export const ALIGN_ITEMS = {
  center: "center",
  flex: "flex",
  flexEnd: "flex-end",
  bottom: "bottom",
  left: "left",
  right: "right",
  top: "top",
};

export const WIDTH = {
  text: 300,
  date: 200,
  select: 170,
  progressBar: 130,
  actions: 50,
  name: 250,
};

export const USD = "USD"; // FIXME: Temporal const; delete this when company's currency config will be ready

export const DATE_PARTS = {
  year: "year",
  month: "month",
  date: "date",
};

export const SURVEY_PROCESS_TYPE = {
  performance: {
    key: "PerformanceProcess",
    value: "performance_processes",
    itemData: "performance_process",
  },
  potential: {
    key: "PotentialProcess",
    value: "potential_processes",
    itemData: "potential_process",
  },
  goodLeader: {
    key: "GoodLeaderProcess",
    value: "good_leader_processes",
    itemData: "good_leader_process",
  },
  engagement: {
    key: "EngagementProcess",
    value: "engagement_processes",
    itemData: "engagement_process",
    resultTypes: {
      satisfaction: "satisfaction_by_department",
      comments: "comments_by_department",
      nps: "nps_by_department",
    },
  },
  training: {
    key: "TrainingEvaluationProcess",
    value: "training_evaluation_processes",
    itemData: "training_evaluation_process",
  },
};

export const ACTIONS = {
  new: "new",
  edit: "edit",
  close: "close",
};

export const COMPANY_ID_PARAM = (id) => `?company_id=${id}`;
export const COLLABORATORS_PATH = "collaborators";
export const FEEDBACK_PATH = "feedbacks";
export const SURVEY_PROCESS_PATH = "survey_processes";
export const SURVEY_RESULTS_PATH = "survey_results";
export const GOOD_LEADER_PROCESSES_PATH = "good_leader_processes";
export const USER_ID_PARAM = (userId) => `user_id=${userId}`;
export const BOTTOM_RESULTS_PATH = "bottom_results";
export const TOP_RESULTS_PATH = "top_results";
export const SEND_PROCESS_PATH = "send_process";
export const SEND_PROCESS_REMINDER_PATH = "send_process_reminder";

export const DOWNLOAD_TYPE = {
  none: "none",
  excel: "excel",
  pdf: "pdf",
};

export const GOAL_RULES = {
  lessIsBetter: "LessIsBetter",
  moreIsBetter: "MoreIsBetter",
};

export const PATHS = {
  profile: "/profile",
  search: {
    collaborators: "?collaborator=",
  },
};

export const METHODS = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

export const LOCATION_PROPS = {
  search: "search",
  pathname: "pathname",
  state: "state",
  key: "key",
  hash: "",
};
export const FEEDBACK_SIMPLES_PATH = "feedback_simples";
export const FEEDBACK_SSC_PATH = "feedback_start_stop_continues";

export const INPUT_TYPE = {
  text: "text",
  select: "select",
  password: "password",
  file: "file",
};

export const INPUT_RULES = {
  isValidEmail: {
    required: true,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    },
  },
  required: {
    required: true,
  },
  maxAndMinLength: (maxLength, required = true, minLength) => ({ required, maxLength, minLength }),
  passwordMatch: (password, t) => ({
    required: true,
    validate: (value) => (value === password.current) || t("formValidations:validations.password_dont_match"),
  }),
};

export const BUTTON_TYPE = {
  submit: "submit",
};

export const NO_AUTOCOMPLETE = "no";

export const FORM_MODE = {
  onChange: "onChange",
};

export const EXCEL_PROPS = {
  eachCell: "eachCell",
  style: {
    minWidth: 15,
    minLength: 0,
    maxLength: 5,
    border: {
      thin: "thin",
    },
    colors: {
      fgColorRequired: "FFFFFF00",
      bgColorRequired: "FF0000FF",
    },
  },
  type: {
    list: "list",
    pattern: "pattern",
  },
  errorStyle: {
    error: "error",
  },
  plusCell: "0000",
  pattern: {
    solid: "solid",
  },
  font: "Calibri",
  state: {
    hidden: "hidden",
  },
  position: {
    one: 1,
  },
};

export const COUNTRIES = {
  colombia: "Colombia",
};

export const IFRAME = {
  min: 500,
};

export const VACATION_FORM = {
  nala: {
    key: "1FAIpQLSe57C2nEahvSJA9R8BId_tm1wmh4XzU9tBtS4TqlvPwQKePTA",
  },
  sugo: {
    key: "1FAIpQLSdVZwYfBs2KlkLevI7Mzy1dHhpl1ZJyUz1rpfibFhVH1yN9gQ",
  },
  trebleAi: {
    url: "https://1dpwfbxohtd.typeform.com/to/bFYVv5Xi",
  },
  aprende: {
    key: "1FAIpQLScA6zW7d-LtfuViQs-Wv5qGIMC5hvoq3YJ6eHgKiFiVJFehmQ",
  },
  aprendeColombia: {
    key: "1FAIpQLSdYakKH9rfcZ6kJ1qJvZydx1qA8Wzu1QtW2Jhypos0psC-9AQ",
  },
};

export const BLANK = "_blank";

export const FEEDBACK_TYPE = {
  feedbackSimple: "FeedbackSimple",
  feedbackSSC: "FeedbackStartStopContinue",
  feedbackSimpleSN: "feedback_simple",
  feedbackSSCSN: "feedback_start_stop_continue",
};

export const PARAMS_SERIALIZER_OPTIONS = { encode: false, arrayFormat: "brackets" };

export const signInPath = "sign-in";
export const signInTitle = "sign_in";

export const RADIX_PARAMETER = 10;
