import {
  LOAD_PERFORMANCE_PROCESS_START,
  LOAD_PERFORMANCE_PROCESS_SUCCESS,
  LOAD_PERFORMANCE_PROCESS_FAILURE,
  GET_PERFORMANCE_SURVEY_RESULTS_SUCCESS,
  GET_PERFORMANCE_SURVEY_RESULT_SUCCESS,
  GET_TOP_RANKING_SUCCESS,
  GET_BOTTOM_RANKING_SUCCESS,
  LOAD_HEAT_MAP_START,
  GET_HEAT_MAP_SUCCESS,
  LOAD_HEAT_MAP_FAILURE,
} from "../actions/types";

let initialState = {
  performance_process: null,
  performance_survey_results: null,
  performance_survey_result: null,
  top_ranking: [],
  bottom_ranking: [],
  heat_map: [],
  isLoading: false,
  heatMapIsLoading: false,
  error: null,
  heatMapError: null,
};

const performancesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PERFORMANCE_PROCESS_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_PERFORMANCE_PROCESS_SUCCESS:
      return {
        ...state,
        performance_process: action.payload,
        isLoading: false,
      };
    case GET_PERFORMANCE_SURVEY_RESULTS_SUCCESS:
      return {
        ...state,
        performance_survey_results: action.payload,
        isLoading: false,
      };
    case GET_PERFORMANCE_SURVEY_RESULT_SUCCESS:
      return {
        ...state,
        performance_survey_result: action.payload,
        isLoading: false,
      };
    case GET_TOP_RANKING_SUCCESS:
      return {
        ...state,
        top_ranking: action.payload,
        isLoading: false,
      };
    case GET_BOTTOM_RANKING_SUCCESS:
      return {
        ...state,
        bottom_ranking: action.payload,
        isLoading: false,
      };
    case LOAD_PERFORMANCE_PROCESS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case LOAD_HEAT_MAP_START:
      return {
        ...state,
        heatMapIsLoading: true,
      };
    case GET_HEAT_MAP_SUCCESS:
      return {
        ...state,
        heat_map: action.payload,
        heatMapIsLoading: false,
      };
    case LOAD_HEAT_MAP_FAILURE:
      return {
        ...state,
        heatMapError: action.payload,
        heatMapIsLoading: false,
      };
    default:
      return state;
  }
};

export default performancesReducer;
