import styled from "styled-components";
import Box from "@material-ui/core/Box";

export const BoxLabel = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SpacedP = styled.p`
  margin: 8px 0 8px 0;
`;

export const BoldLabel = styled.label`
  margin: 5px;
  font-weight: 600;
`;

export const FileLabel = styled(BoxLabel)`
margin: 8px 0 8px 0;
`;
