import {
  LOAD_SURVEY_RESULTS_START,
  LOAD_SURVEY_RESULTS_SUCCESS,
  LOAD_SURVEY_RESULTS_FAILURE,
  LOAD_SURVEY_DETAILS_START,
  LOAD_SURVEY_DETAILS_SUCCESS,
  LOAD_SURVEY_DETAILS_FAILURE,
} from "./types";

import getLoginClient from "./loggedInClient";
import {
  INDIVIDUAL_RESULT,
  SURVEY_PROCESSES,
  SURVEY_RESULTS,
} from "common/paths/surveyResults";

const getSurveyResults = (id, processId, userId) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_SURVEY_RESULTS_START });
  const client = getLoginClient();
  const SURVEY_RESULTS_URL = `${SURVEY_PROCESSES.root}/${processId}/${SURVEY_PROCESSES.survey_results}${SURVEY_PROCESSES.mainSearch}${id}${SURVEY_PROCESSES.userIdQuery}${userId}`;
  client
    .get(SURVEY_RESULTS_URL)
    .then((response) => {
      dispatch({
        type: LOAD_SURVEY_RESULTS_SUCCESS,
        payload: response.data.survey_results,
      });
    })
    .catch(function(error) {
      dispatch({
        type: LOAD_SURVEY_RESULTS_FAILURE,
        payload: error.status,
      });
    });
};

const getSurveyDetails = (id, surveyId) => async (dispatch, getState) => {
  dispatch({ type: LOAD_SURVEY_DETAILS_START });
  const client = getLoginClient();

  client
    .get(`${SURVEY_RESULTS.root}/${surveyId}${SURVEY_RESULTS.mainSearch}${id}`)
    .then((response) => {
      dispatch({
        type: LOAD_SURVEY_DETAILS_SUCCESS,
        payload: response.data.survey_result,
      });
    })
    .catch(function(error) {
      dispatch({
        type: LOAD_SURVEY_DETAILS_FAILURE,
        payload: error.status,
      });
    });
};

const getIndividualSurveyResult = (id, userId, processId) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_SURVEY_RESULTS_START });
  const client = getLoginClient();
  const INDIVIUDAL_SURVEY_RESULT_URL = `${INDIVIDUAL_RESULT.root}/${userId}/${INDIVIDUAL_RESULT.survey_results}${INDIVIDUAL_RESULT.mainSearch}${id}`;

  client
    .get(INDIVIUDAL_SURVEY_RESULT_URL)
    .then((response) => {
      dispatch({
        type: LOAD_SURVEY_RESULTS_SUCCESS,
        payload: response.data.survey_results,
      });
    })
    .catch(function(error) {
      dispatch({
        type: LOAD_SURVEY_RESULTS_FAILURE,
        payload: error.status,
      });
    });
};

export default {
  getSurveyResults,
  getSurveyDetails,
  getIndividualSurveyResult,
};
