import axios from "axios";
import qs from "qs";
import { API_URL_RAILS_V1, LANGUAGES, PARAMS_SERIALIZER_OPTIONS } from "common/constants";
import {
  COLLABORATORS,
  EMPLOYEES,
  GET_CREATE_COLLABORATOR_URL,
  GET_COLLABORATORS_BY_MANAGER_JOB_POSITION,
} from "common/paths/collaborators";
import {
  LOAD_COLLABORATOR_START,
  LOAD_COLLABORATOR_SUCCESS,
  LOAD_COLLABORATOR_FAILURE,
  GET_COLLABORATOR_SUCCESS,
  LOAD_ORGANIZATION_CHART_SUCCESS,
  LOAD_COLLABORATORS_DOWLOAD_SUCCESS,
} from "./types";
import getLoginClient from "./loggedInClient";

const getCollaborators = (id, employeeId, query) => async (dispatch, getState) => {
  dispatch({ type: LOAD_COLLABORATOR_START });
  const client = getLoginClient();

  const GET_COLLABORATORS_URL = `${COLLABORATORS.root}${COLLABORATORS.companyId}${id}&${COLLABORATORS.employeeId}${employeeId}`;
  client.get(GET_COLLABORATORS_URL,
    {
      params: query,
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    })
    .then((response) => {
      dispatch({
        type: LOAD_COLLABORATOR_SUCCESS,
        payload: response.data.collaborators,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_COLLABORATOR_FAILURE, payload: error.status });
    });
};

const getCollaborator = (id, company_id) => async (dispatch, getState) => {
  dispatch({ type: LOAD_COLLABORATOR_START });
  const client = getLoginClient();
  const GET_COLLABORATOR_URL = `${COLLABORATORS.root}/${id}${COLLABORATORS.companyId}${company_id}`;
  client
    .get(GET_COLLABORATOR_URL)
    .then((response) => {
      dispatch({
        type: GET_COLLABORATOR_SUCCESS,
        payload: response.data.collaborator,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_COLLABORATOR_FAILURE, payload: error.status });
    });
};

const createCollaborator = (data, company_id, locale = LANGUAGES.en) => async (dispatch, getState) => {
  dispatch({ type: LOAD_COLLABORATOR_START });
  const client = getLoginClient();
  client
    .post(GET_CREATE_COLLABORATOR_URL(company_id, locale), data)
    .then((response) => {
      dispatch({
        type: GET_COLLABORATOR_SUCCESS,
        payload: response.data.collaborator,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_COLLABORATOR_FAILURE, payload: error });
    });
};

const updateCollaborator = (data, collaboratorId, companyId) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: LOAD_COLLABORATOR_START });
  const client = getLoginClient();
  const UPDATE_COLLABORATOR_URL = `${COLLABORATORS.root}/${collaboratorId}${COLLABORATORS.companyId}${companyId}`;
  client
    .put(UPDATE_COLLABORATOR_URL, data)
    .then((response) => {
      dispatch({
        type: GET_COLLABORATOR_SUCCESS,
        payload: response.data.collaborator,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_COLLABORATOR_FAILURE, payload: error });
    });
};

const terminateEmployeeCollaborator = (
  collaboratorId,
  companyId,
  data,
) => async (dispatch, getState) => {
  dispatch({ type: LOAD_COLLABORATOR_START });
  const client = getLoginClient();
  const TERMINATE_EMPLOYEE_COLLABORATOR = `${EMPLOYEES.root}/${collaboratorId}/${EMPLOYEES.turnovers}${EMPLOYEES.companyId}${companyId}`;
  client
    .post(TERMINATE_EMPLOYEE_COLLABORATOR, data)
    .then((response) => {
      dispatch({
        type: GET_COLLABORATOR_SUCCESS,
        payload: response.data.turnover.employee,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_COLLABORATOR_FAILURE, payload: error });
    });
};

const getOrganizationChart = (companyId) => async (dispatch, getState) => {
  dispatch({ type: LOAD_COLLABORATOR_START });
  const client = getLoginClient();
  const ORGANIZATION_CHART_URL = `${COLLABORATORS.root}${COLLABORATORS.companyId}${companyId}${COLLABORATORS.tree}true`;
  client
    .get(ORGANIZATION_CHART_URL)
    .then((response) => {
      dispatch({
        type: LOAD_ORGANIZATION_CHART_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_COLLABORATOR_FAILURE, payload: error });
    });
};

// async and specific calls from 124 to 147
const getAsyncAllCollaborators = async (id, employeeId) => {
  try {
    const GET_COLLABORATORS_URL = `${API_URL_RAILS_V1}${COLLABORATORS.root}${COLLABORATORS.companyId}${id}&${COLLABORATORS.employeeId}${employeeId}`;
    const resp = await axios.get(GET_COLLABORATORS_URL);
    return resp.data.collaborators.filter((item) => item.is_active);
  } catch (err) {
    return err;
  }
};

const getAsyncCollaboratorChildrens = async (companyId, findById, type) => {
  try {
    const resp = await axios.get(GET_COLLABORATORS_BY_MANAGER_JOB_POSITION(companyId, findById, type));
    return resp.data.collaborators.filter((item) => item.is_active);
  } catch (err) {
    return err;
  }
};

const getCollaboratorsToDownload = (companyId) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: LOAD_COLLABORATOR_START });
  const client = getLoginClient();
  const ORGANIZATION_CHART_URL = `${COLLABORATORS.root}${COLLABORATORS.companyId}${companyId}${COLLABORATORS.download}true`;
  client
    .get(ORGANIZATION_CHART_URL)
    .then((response) => {
      dispatch({
        type: LOAD_COLLABORATORS_DOWLOAD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: LOAD_COLLABORATOR_FAILURE, payload: error });
    });
};

export default {
  getCollaborators,
  getCollaborator,
  createCollaborator,
  updateCollaborator,
  terminateEmployeeCollaborator,
  getOrganizationChart,
  getAsyncAllCollaborators,
  getAsyncCollaboratorChildrens,
  getCollaboratorsToDownload,
};
