import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { SessionContext } from "../../modules/session/context";

const PrivateRoute = function ({ component: Component, ...props }) {
  const {
    state: { user },
  } = useContext(SessionContext);

  const renderRedirect = (renderProps) => (user ? <Component { ...renderProps } /> : <Redirect to={ "/sign-in" } />);

  if (!Component) {
    throw new Error(
      `A component needs to be specified for private route for path ${props.path}`,
    );
  }

  return <Route { ...props } render={ renderRedirect } />;
};

export default PrivateRoute;
