import {
  LOAD_AUTH_START,
  LOAD_AUTH_SUCCESS,
  LOAD_AUTH_FAILURE,
  LOAD_AUTH_RECOVER_PASSWORD_SUCCESS,
  LOAD_AUTH_EMAIL_RECOVER_PASSWORD_SUCCESS,
  LOAD_AUTH_VALIDATE_TOKEN_SUCCESS,
  LOAD_AUTH_CHANGE_PASSWORD_SUCCESS,
} from "actions/types";

const initialState = {
  user: {},
  userValidatedByToken: {},
  recoverPassword: null,
  emailRecoverPassword: null,
  changePassword: null,
  isLoading: false,
  error: null,
};

const oAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_AUTH_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case LOAD_AUTH_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
      };
    case LOAD_AUTH_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case LOAD_AUTH_RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        recoverPassword: action.payload,
        isLoading: false,
      };
    case LOAD_AUTH_EMAIL_RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        emailRecoverPassword: action.payload,
        isLoading: false,
      };
    case LOAD_AUTH_VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        userValidatedByToken: action.payload,
        isLoading: false,
      };
    case LOAD_AUTH_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: action.payload,
        isLoading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default oAuthReducer;
