import moment from "moment";
import { FULLDATE_FORMATS } from "common/constants";

export const getTabs = (t) => {
  const defaultTabs = [
    { label: t("timeOff:tabs.personal") },
    { label: t("timeOff:tabs.team") },
  ];
  return defaultTabs;
};

export const treatAsUTC = (date) => {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};

export const daysBetween = (startDate, endDate) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
};

const calcBusinessDays = (startDate, endDate) => { 
  const day = moment(startDate);
  let businessDays = 0;

  while (day.isSameOrBefore(endDate, "day")) {
    if (day.day() !== 0 && day.day() !== 6) businessDays++;
    day.add(1, "d");
  }
  return businessDays;
};

export const getExcelData = (excelList, t) => {
  const data = excelList.map((elem) => {
    return {
      [t("timeOff:excel.Personal id")]: elem.employee.personal_id,
      [t("timeOff:excel.Name")]: elem.employee.full_name,
      [t("timeOff:excel.Email")]: elem.employee.email,
      [t("timeOff:excel.Manager name")]: elem.employee.manager_name,
      [t("timeOff:excel.Starting date")]: moment(elem.starting_date).format(FULLDATE_FORMATS.dash),
      [t("timeOff:excel.Finish date")]: moment(elem.ending_date).format(FULLDATE_FORMATS.dash),
      [t("timeOff:excel.Number of calendar days")]: daysBetween(treatAsUTC(elem.starting_date), treatAsUTC(elem.ending_date)),
      [t("timeOff:excel.Number of business days")]: calcBusinessDays(elem.starting_date, elem.ending_date),
      [t("timeOff:excel.Type")]: t(`timeOff:types.${elem.time_off_type}`),
      [t("timeOff:excel.Reason")]: elem.reason,
      [t("timeOff:excel.State")]: t(`timeOff:states.${elem.state}`),
    }
  });
  return data;
};
