import React, {
  useState, useEffect, useContext, useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Box, Hidden } from "@material-ui/core";
import Tab from "components/Tab";
import TabPanel from "components/TabPanel";
import DateFilter from "components/DateFilter";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Filter from "components/Filter";
import DownloadExcelButton from "components/DownloadExcelButton";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import { isAdminOrManager as isAdminOrManagerFunction, isAdmin as isAdminFunction } from "common/utils";
import {
  getList, getTimeOffList, getExcelList, resetTimeOffList, resetState,
} from "redux/actions/timeOffActions";
import { SessionContext } from "modules/session/context";
import { useProcessToast } from "hooks/timeOff/useProcessToast";
import InputTextSearch from "./components/InputTextSearch";
import PopUpForm from "./components/PopUpForm";
import CollaboratorsCard from "./components/TabGantTable/components/CollaboratorsCard";
import TabGantTable from "./components/TabGantTable";
import { getExcelData, getTabs } from "./functions";
import {
  CustomGrid, CustomPaper, SpacedGrid, TimeOffGrid, TimeOffPopUp, TabStyle, DateStyle,
} from "./styles";

const TimeOff = () => {
  const { t } = useTranslation(["common", "timeOff"]);

  const [tabHandler, setTabHandler] = useState(0);
  const [countriesFilter, setCountriesFilter] = useState([]);
  const [citiesFilter, setCitiesFilter] = useState([]);
  const [unitiesFilter, setUnitiesFilter] = useState([]);
  const [statesFilter, setStatesFilter] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [pageFilter, setPageFilter] = useState({ number: 0, size: 12 });
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const [processState, setProcessState] = useState("");

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevCount = usePrevious(pageFilter);

  const {
    state: { user },
  } = useContext(SessionContext);

  const isAdminOrManager = isAdminOrManagerFunction(user?.roles);

  const isAdmin = isAdminFunction(user?.roles);

  const handleValueChanged = (value) => {
    setTabHandler(value);
  };

  const {
    list, loadingList, timeOffList, timeOffLoadingList, excelList, loadingExcelList,
  } = useSelector(({ timeOffReducer }) => timeOffReducer);

  const onDownloadExcel = (filename) => () => {
    const data = excelList ? getExcelData(excelList, t) : [];
    return [data, filename];
  };

  const FILTERS = {
    countries: {
      state: countriesFilter, set: setCountriesFilter,
    },
    cities: {
      state: citiesFilter, set: setCitiesFilter,
    },
    unities: {
      state: unitiesFilter, set: setUnitiesFilter,
    },
    states: {
      state: statesFilter, set: setStatesFilter,
    },
  };

  const handleExternalFilter = (selectedfilter, values) => {
    const filterSet = FILTERS[selectedfilter].set;
    const filteredValues = values[selectedfilter].map((item) => item.id);
    filterSet(filteredValues || []);
  };

  const dispatch = useDispatch();

  const pageHandler = async (event, newPage) => {
    window.scroll(0, 0);
    await setPageFilter({ size: 12, number: newPage });
  };

  const inputHandler = (name = "") => {
    setSearchFilter(name);
  };

  const endingDateHandler = (date) => {
    setEndingDate(date);
  };

  const startingDateHandler = (date) => {
    setStartingDate(date);
  };

  useProcessToast("", setProcessState, processState);
  useEffect(() => {
    const query = {
      q: {
        country_id_in: countriesFilter,
        city_id_in: citiesFilter,
        organization_unit_id_in: unitiesFilter,
      },
    };

    const queryCollaborator = query;
    const queryTimeOff = query;

    queryCollaborator.q.time_offs_state_in = statesFilter;
    queryTimeOff.q.state_in = statesFilter;
    const formatedPage = {
      size: pageFilter.size,
      number: pageFilter.number + 1,

    };

    if (processState !== "") {
      setProcessState("");
    } else if (prevCount !== pageFilter) {
      dispatch(resetTimeOffList());
      dispatch(resetState());
      dispatch(getList(searchFilter, startingDate, endingDate, queryCollaborator));
      dispatch(getExcelList(searchFilter, startingDate, endingDate, queryTimeOff));
      dispatch(getTimeOffList(searchFilter, startingDate, endingDate, formatedPage, queryTimeOff));
    } else {
      setPageFilter({ number: 0, size: 12 });
    }
    // eslint-disable-next-line
  }, [searchFilter, citiesFilter, countriesFilter, dispatch,
    statesFilter, unitiesFilter, pageFilter, startingDate, endingDate, processState]);

  return (
    <div data-testid={ "time-off-component-view" }>
      <Box p={ 4 }>
        <Hidden smDown >
          <SpacedGrid container direction={ "row" }>
            <Grid item>
              <ViewHeaderTitle title={ t("timeOff:viewTitle") } />
            </Grid>
            <Grid item>
              <PopUpForm />
            </Grid>
          </SpacedGrid>

          <Grid container spacing={ 4 }>
            <Grid item xs={ 12 }>

              {isAdminOrManager && (
                <Tab
                  tabs={ getTabs(t) }
                  onChange={ handleValueChanged }
                  tabValue={ tabHandler }
                />
              )}
              <CustomPaper variant={ "outlined" }>
                <TabPanel
                  value={ tabHandler }
                  index={ 0 }
                >
                  {"personal"}
                </TabPanel>

                {isAdminOrManager && (
                  <TabPanel
                    value={ tabHandler }
                    index={ 1 }
                    isLoading={ false }
                  >

                    <Grid container>
                      <CustomGrid item xs={ 12 }>
                        <Filter
                          type={ FILTER_ACTIONS_TYPES.timeOff }
                          isLabel
                          isMultiple
                          externalParams={ [searchFilter,
                            startingDate, endingDate, pageFilter] }
                          externalHandler={ handleExternalFilter }
                        />
                      </CustomGrid>
                    </Grid>

                    <Grid
                      container
                      spacing={ -1 }
                      alignItems={ "center" }
                    >
                      <Grid item xs={ 5 }>
                        <DateFilter
                          startingDateHandler={ startingDateHandler }
                          endingDateHandler={ endingDateHandler }
                        />
                      </Grid>
                      <Grid item xs={ 2 }>
                        <DownloadExcelButton
                          onClick={ onDownloadExcel("Time off") }
                          isDisabled={ loadingExcelList }
                        />
                      </Grid>
                      <Grid item xs={ 3 }>
                        <InputTextSearch inputHandler={ inputHandler } />
                      </Grid>
                      <Grid item xs={ 1 } />
                    </Grid>
                    <TabGantTable
                      gantData={ list }
                      tableData={ timeOffList }
                      tableIsLoading={ timeOffLoadingList }
                      gantIsLoading={ loadingList }
                      pageHandler={ pageHandler }
                      pageFilter={ pageFilter }
                      statesFilter={ statesFilter }
                    />
                  </TabPanel>
                )}
              </CustomPaper>
            </Grid>
          </Grid>
        </Hidden >

        <Hidden mdUp>
          <ViewHeaderTitle title={ t("timeOff:viewTitle") } />
          <TabStyle>
            {isAdminOrManager && (
              <Tab
                tabs={ getTabs(t) }
                onChange={ handleValueChanged }
                tabValue={ tabHandler }
                variant={ "fullWidth" }
              />
            )}
          </TabStyle>

          <CustomPaper variant={ "outlined" }>
            <TabPanel
              value={ tabHandler }
              index={ 0 }
            >
              {"personal"}
            </TabPanel>

            {isAdminOrManager && (
              <TabPanel
                value={ tabHandler }
                index={ 1 }
                isLoading={ false }
              >

                <Grid item lg={ 12 } >
                  <TimeOffGrid>
                    <Filter
                      isLabel
                      isMultiple
                      type={ FILTER_ACTIONS_TYPES.timeOff }
                      isButton
                      externalParams={ [searchFilter, startingDate, endingDate, pageFilter] }
                      externalHandler={ handleExternalFilter }
                    />
                  </TimeOffGrid>
                  <DateStyle>
                    <DateFilter
                      fullWidth
                      startingDateHandler={ startingDateHandler }
                      endingDateHandler={ endingDateHandler }
                    />
                  </DateStyle>
                  <TimeOffGrid>
                    <InputTextSearch inputHandler={ inputHandler } />
                  </TimeOffGrid>
                  <DownloadExcelButton
                    onClick={ onDownloadExcel("Time off") }
                    isDisabled={ loadingExcelList }
                  />
                  <CollaboratorsCard
                    data={ timeOffList }
                    pagination={ { number: pageFilter.number, size: pageFilter.size, handlePage: pageHandler } }
                    isLoading={ timeOffLoadingList }
                    isAdmin={ isAdmin }
                  />
                </Grid>
              </TabPanel>
            )}
          </CustomPaper >

        </Hidden >
      </Box >
      <Hidden mdUp>
        <TimeOffPopUp>
          <PopUpForm
            isMobile
          />
        </TimeOffPopUp>
      </Hidden>
    </div >
  );
};

export default TimeOff;
