import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import { SessionContext } from "modules/session/context";
import { STATES, STATE_CHANGE } from "common/constants/timeOff";
import { ReactComponent as AcceptIcon } from "assets/images/manageRequest/acceptIcon.svg";
import { ReactComponent as CancelIcon } from "assets/images/manageRequest/cancelIcon.svg";
import { ReactComponent as DownloadIcon } from "assets/images/manageRequest/downloadIcon.svg";
import handleDownload from "views/TimeOff/functions/downloadFiles";
import { isAdmin as isAdminFunction } from "common/utils";
import {
  CustomPaper, CustomGrid, CustomDiv, CustomIcons, FromDateContainer, ToDateContainer, DateP, TimeOffContainer, ReasonRejection,
} from "./styles";

const ManageRequest = (props) => {
  const { t } = useTranslation(["common", "timeOff"]);
  const {
    fromMonth,
    toMonth,
    fromDay,
    toDay,
    type,
    state,
    files,
    id,
    rejection_reason,
    handleModal=() => {},
  } = props;

  const {
    state: { user },
  } = useContext(SessionContext);

  const isAdmin = isAdminFunction(user.roles);

  return (
    <CustomPaper elevation={ 3 } index={id}>
      <CustomGrid item lg={ 12 }>
        <FromDateContainer item lg={ 5 }>
          <DateP>{t("common.fromDate")}</DateP>
          <CustomDiv>
            <p>{fromMonth}</p>
            <strong>{fromDay}</strong>
          </CustomDiv>
        </FromDateContainer>
        <ToDateContainer item lg={ 5 }>
          <DateP>{t("common.toDate")}</DateP>
          <CustomDiv>
            <p>{toMonth}</p>
            <strong>{toDay}</strong>
          </CustomDiv>
        </ToDateContainer>
      </CustomGrid>
      <TimeOffContainer>
        <p>
          {t("timeOff:excel.Type")}
          {": "}
          {" "}
          <strong>{type}</strong>
        </p>
        <p>
          {t("timeOff:excel.State")}
          {": "}
          {" "}
          <strong>{t(`timeOff:states.${state}`)}</strong>
        </p>
        {rejection_reason
          && (
            <>
              <ReasonRejection>
                {t("timeOff:Manage_request.RejectionReason")}
                {":"}
                {" "}
              </ReasonRejection>
              <ReasonRejection><strong>{rejection_reason}</strong></ReasonRejection>
            </>
          )}
      </TimeOffContainer>
      <CustomIcons>
        { ((isAdmin && state === STATES.approved_by_leader) || (state === STATES.pending))
          && (
            <>
              <IconButton onClick={ () => { handleModal(STATE_CHANGE.reject); } }>
                <CancelIcon />
              </IconButton>
              <IconButton onClick={ () => { handleModal(STATE_CHANGE.approve); } }>
                <AcceptIcon />
              </IconButton >
            </>
          )}
        {files?.length > 0
          && (
            <IconButton onClick={ () => { handleDownload(files); } } >
              <DownloadIcon />
            </IconButton>
          )}
      </CustomIcons>
    </CustomPaper>
  );
};

ManageRequest.propTypes = {
  type: PropTypes.string,
  state: PropTypes.string,
  fromMonth: PropTypes.string,
  toMonth: PropTypes.string,
  fromDay: PropTypes.string,
  toDay: PropTypes.string,
  //files: PropTypes.arrayOf(PropTypes.string),
  rejection_reason: PropTypes.string,
  //handleModal: PropTypes.func,
};

ManageRequest.defaultProps = {
  type: null,
  state: null,
  fromMonth: null,
  toMonth: null,
  fromDay: null,
  toDay: null,
  rejection_reason: null,
};

export default ManageRequest;
