import {
  LOAD_ROLES_START,
  LOAD_ROLES_SUCCESS,
  LOAD_ROLES_FAILURE,
} from "./types";
import getLoginClient from "./loggedInClient";
import { ROLES } from "common/paths/roles";

const getRoles = (companyId) => async (dispatch, getState) => {
  dispatch({ type: LOAD_ROLES_START });
  const client = getLoginClient();
  const ROLES_URL = `/${ROLES.root}?${ROLES.companyId}=${companyId}`;
  client
    .get(ROLES_URL)
    .then((response) => {
      dispatch({
        type: LOAD_ROLES_SUCCESS,
        payload: response.data.roles,
      });
    })
    .catch(function(error) {
      dispatch({ type: LOAD_ROLES_FAILURE, payload: error });
    });
};

export default {
  getRoles,
};
