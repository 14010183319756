import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

export const CustomGrid = styled(Grid)`
  display: flex;
  margin-bottom: 5px;
  margin-left: -10px;
`;

export const CustomIcons = styled(Grid)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
`;

export const CustomPaper = styled(Paper)`
  width: 220px;
  min-height: 220px;  
  height: min-content;
  padding: 22px 22px 0px 22px;
  z-index: ${props => props.index };
`;

export const DateP = styled.p`
  font-size: 11px;
  align-self: center;
`; 

export const FromDateContainer = styled(Grid)`
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  margin: 0px 15px 5px 9px; 
`; 

export const ToDateContainer = styled(Grid)`
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  margin-bottom: 5px;
`; 

export const TimeOffContainer = styled(Grid)`
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  margin-bottom: 5px;
`; 

export const CustomDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  margin: 2px;
  height: 48px;
  border-radius: 4px;
  border:1px solid #BDBDBD;
  border-top: 7px solid  #19E2D6;
  align-self: center;
`;

export const ReasonRejection = styled.p`
  text-align: initial;
`;
