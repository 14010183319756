import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import moment from "moment";
import { FULLDATE_FORMATS } from "common/constants";
import { EnhancedDateInputController } from "./styles";

const DateFilter = (props) => {
  const { startingDateHandler, endingDateHandler, fullWidth } = props;
  const { control, watch } = useForm();
  const { t } = useTranslation(["common"]);

  const fromDay = watch("from", "");

  const toDay = watch("to", "");

  useEffect(() => {
    if (fromDay !== "") {
      startingDateHandler(moment(fromDay).format(FULLDATE_FORMATS.dash_calendar));
    }
    if (toDay !== "") {
      endingDateHandler(moment(toDay).format(FULLDATE_FORMATS.dash_calendar));
    }
  }, [fromDay, toDay, control, startingDateHandler, endingDateHandler]);

  return (

    <form>
      <EnhancedDateInputController
        customStyles={ fullWidth ? null : "enhancedInputController" }
        control={ control }
        label={ t("common.fromDate") }
        name={ "from" }
        fullWidth={ fullWidth }
        required={ false }
      />

      <EnhancedDateInputController
        customStyles={ fullWidth ? null : "enhancedInputController" }
        control={ control }
        label={ t("common.toDate") }
        name={ "to" }
        fullWidth={ fullWidth }
        required={ false }
      />
    </form>
  );
};

export default DateFilter;
